/* Slider */

.slick-slider {
	position: relative;
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
} 

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;

	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
		width: 100%;
		max-height: inherit;
		max-width: inherit !important;
	}

	&.slick-loading img {
		display: none;
	}

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-arrow {
	position: absolute;
	z-index: 10;
	top: 50%;
	border: none;
	outline: none;
	padding: 0;
	color: $color-3;
	background-color: transparent;
	font-size: 26px;
	cursor: pointer;
	transform: translateY(-50%);
	transition: $transition;

	&:hover {
		color: $color-2;
	}

	&:active {
		transform: translateY(calc(-50% + 3px));
	}
}

.slick-arrow.slick-prev {
	left: -30px;

	i {
		display: inline-block;
		transform: rotate(180deg);
	}
}

.slick-arrow.slick-next {
	right: -30px;
}

//Dots
.slick-dots {
	position: absolute;
	bottom: 20px;
	left: 50%;
	display: flex;
	transform: translateX(-50%);

	li {
		margin: 0 8px;
	}

	button {
		display: block;
		width: 10px;
		height: 10px;
		padding: 0;
		border-radius: 50%;
		border: 2px solid rgba(255,255,255,.5);
		background: $color-4;
		text-indent: -9999px;
		transition: $transition;
	}

	.slick-active button {
		background: $color-2;
	}
}