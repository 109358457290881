.js-rating, 
.js-rating-choose {
	white-space: nowrap
}

.jq-stars {
	display: inline-block;
}

.jq-rating-label {
	position: relative;
	display: inline-block;
	vertical-align: top;
	font-size: 22px;
	font-family: helvetica, arial, verdana;
}

.jq-star {
	vertical-align: middle;
	display: inline-block;
	width: 100px;
	height: 100px;
	margin-right: 5px;
	cursor: pointer;
	&:last-child {
		margin-right: 0;
	}
}

.jq-star-svg {
	width: 100%;
	height: 100%;
}

.jq-star:hover .fs-star-svg path {}

.jq-star-svg path {
	stroke-linejoin: round;
}

.jq-shadow {
	-webkit-filter: drop-shadow(-2px -2px 2px #888);
	filter: drop-shadow(-2px -2px 2px #888);
}
