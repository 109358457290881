/*
================================================================================
|                                  COMPARE PAGE                                |
================================================================================
*/

.compare-table {
	display: flex;
	align-items: flex-start;
	$this: &;
	p {
		color: #ADADAD;
		font-size: 11px;
		line-height: 17px;
		font-weight: 300;
	}
	.price-old {
		color: #555;
		text-decoration: line-through;
		font-size: 12px;
	}
	&__clear {
		align-self: center;
		display: inline-block;
		margin-top: 20px;
		border: none;
		border-bottom: 1px solid;
		padding: 0;
		background-color: transparent;
		color: rgba(51, 51, 51, .4);
		white-space: nowrap;
		font-size: 13px;
		line-height: 14px;
		transition: $transition;

		&:hover {
			color: $color-2;
			border-color: transparent;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		background-color: #fff;
	}

	.col {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		padding: 16.5px 15px;
		border-bottom: 1px solid #E0E7EA;

		&:last-child {
			border-bottom: none;
		}

		&_title {
			border: none;
			padding: 12px 30px;
			background: #E0E7EA;
		}
	}

	&__item_header {
		width: 290px;
		margin-right: 20px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;

		.col_1 {
			justify-content: flex-start;
		}
	}

	&__item-wrap {
		position: relative;
		width: calc(100% - 310px);

		.col_1 {
			padding: 16.5px 10px;
		}

		#{$this}__item {
			display: block;
			width: 260px;
			min-width: 260px;

			&:not(:last-child) {
				.col:not(.col_1):not(.col_title) {
					border-right: 1px solid #E0E7EA;
				}
			}

			.col:last-child {
				padding: 25px 15px 30px;
			}
		}
	}

	&__item-scroll {
		display: flex;
	}

	&__img {
		position: relative;
		width: 100%;
		height: 208px;
		border: 1px solid #E8E8E8;
		margin: 17px 0 0px;
		background-color: #FFFFFF;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: auto 85%;
	}

	&__icon {
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-color: #E8E8E8;
		color: rgba(51, 51, 51, .5);
		font-size: 12px;
		line-height: 1;
		cursor: pointer;
		transition: $transition;

		&:hover {
			color: rgba(51, 51, 51, 1);
		}
	}

	&__link-wrap {
		display: block;
		padding: 10px 0;
	}

	&__link {
		display: inline;
		border-bottom: 1px solid;
		color: $color-3;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;

		&:hover {
			border-color: transparent;
			color: $color-2;
		}
	}

	&__basket {
		min-width: 180px;

		i {
			font-size: 18px;
			margin-right: 10px;
		}
	}

	&__link-remove-wrap {
		margin-top: 20px;
	}

	&__link-remove {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: $color-3;
	}

	&__bottom {
		margin-top: 40px;
		padding: 20px;
		background-color: #fff;
		text-align: right;

		.btn {
			min-width: 150px;
		}
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal {
		top: 11px;
		bottom: auto;
		left: 10px;
		right: 10px;
	}
}

/*
================================================================================
|                                  SEARCH PAGE                                |
================================================================================
*/

.box-search {

	h1,
	h2 {
		padding: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
	}

	&__wrap {
		background-color: #fff;
	}

	&__row {
		padding: 20px 23px;
		border-bottom: 1px solid #E8E8E8;

		&:nth-child(2) {
			padding-bottom: 2px;
		}
	}

	.catalog-main-list {
		margin: 31px 0 0;
	}

	.catalog-main-list__top {
		border-bottom: none;
		padding: 10px 20px 0;
	}

	.product-list-wrap {
		margin-top: 0;
	}

	.catalog-main-list .product-list__item {
		width: 20%;
	}
}

.search-form {
	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 -10px;
		padding-top: 10px;
	}

	.col {
		display: flex;
		padding: 0 10px;
	}

	&__field {
		font-family: $custom-font-family;
		margin-right: 20px;
		padding-bottom: 35px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__label {
		display: block;
		padding-left: 15px;
		margin-bottom: 15px;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}

	&__input-wrap {
		width: 300px;
		max-width: 100%;
	}

	&__input {
		height: 40px;
		border: 1px solid #D3D3D3;
		padding: 0 15px;
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}

	.catalog-filter__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 300px;
		max-width: 100%;
		height: 40px;
		border: 1px solid #D3D3D3;
		border-radius: 50px;
		padding: 0 15px;
		font-family: $custom-font-family;
	}

	.filter-list__item {
		padding: 12px 15px;
	}

	.catalog-filter__top-type {
		flex-grow: 1;
	}

	.catalog-filter__top-type,
	.filter-list__item {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}

	.catalog-filter__top-icon {
		color: rgba(51, 51, 51, .3);
	}

	.form-filter__checkbox:not(:last-child) {
		margin-right: 30px;
		margin-bottom: 0;
	}

	.form-filter__checkbox-label {
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}

	.filter-list {
		top: calc(100% + 6px);
		left: 0;
		width: 100%;
		border-radius: 20px;
	}

	.form-filter__checkbox-label::before {
		top: 2px;
	}

	.form-filter__checkbox-label::before {
		border: 2px solid rgba(94, 94, 94, .5);
	}

	&__btn .btn {
		display: inline-block;
		height: 50px;
		min-width: 140px;
		padding: 0 30px;
		color: #fff;
		background-color: $color-3;
		border-color: $color-3;

		&:hover {
			color: $color-1;
			background-color: #fff;
		}
	}
}

/*
================================================================================
|                              SEARCH NONE PAGE                                |
================================================================================
*/

.box-search-none {
	margin: 30px 0 228px;

	&__desc {
		display: flex;
		align-items: center;
		font-size: 18px;
		line-height: 21px;

		i {
			width: 40px;
			color: $color-2;
			font-size: 40px;
		}

		span {
			width: calc(100% - 40px);
			padding-left: 14px;
		}
	}
}

/*
================================================================================
|                                  STOCKS PAGE                                |
================================================================================
*/

.box-search.box-search_stocks {
	.catalog-main-list {
		margin-top: -12px;
	}

	.box-search__row:nth-child(2) {
		padding-bottom: 20px;
	}
}

/*
================================================================================
|                                  SERVICE PAGE                                |
================================================================================
*/

.service-page {
	background-color: #fff;

	h1 {
		padding-top: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
	}

	h2 {
		font-family: $custom-font-family;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
	}

	&__row {
		border-bottom: 1px solid #E8E8E8;
		padding: 47px 50px 53px;

		&:last-child {
			border-bottom: none;
			padding: 39px 50px 47px;

			p {
				font-size: 12px;
				line-height: 22px;
			}
		}
	}

	&__link {
		color: $color-3;
		font-weight: bold;
		font-size: 12px;
		line-height: 22px;
	}

	p {
		padding: 5px 0;
		font-size: 14px;
		line-height: 26px;
	}

	&__wrap {
		display: flex;
		margin: 76px 0 29px;
	}

	&__desc-wrap {
		position: relative;
		z-index: 1;
		height: 384px;
		width: calc(45% + 100px);
		margin: 48px -100px 48px 0;
		padding: 46px 36px 46px 49px;
		background-color: #fff;
		box-shadow: 0px 4px 8px rgba(92, 104, 112, 0.16);
	}

	&__desc {
		height: 100%;

		h2:first-child {
			padding-top: 0;
		}

		p {
			padding: 6px 0;
		}
	}

	&__img {
		display: block;
		width: 55%;
		height: 480px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&__btn .btn {
		padding: 0px 27px;
	}
}

.js-scroll {
	.mCSB_scrollTools {
		background-color: transparent;
	}

	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		width: 4px;
	}

	.mCSB_scrollTools .mCSB_draggerRail {
		width: 4px;
	}

	.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
		background-color: #E0E7EA;
	}

	.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: $color-3;
	}

	.mCSB_inside > .mCSB_container {
		margin-right: 40px;
	}
}

.service-list {
	display: flex;
	flex-wrap: wrap;
	margin: -15px -53px;

	&-wrap {
		margin: 26px 0 52px;
	}

	&__item {
		display: flex;
		align-items: center;
		padding: 15px 38px;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		color: #fff;
		background: rgba(58, 100, 121, .6);
		font-size: 34px;
	}

	&__desc {
		padding-left: 20px;
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}
}

/*
================================================================================
|                                  DELIVERY PAGE                                |
================================================================================
*/

.delivery-page {
	padding: 40px 49px 20px;
	background-color: #fff;

	$this: &;

	h1 {
		padding-top: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		margin-bottom: 25px;
	}

	h2 {
		font-family: $custom-font-family;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
	}

	p {
		padding: 5.5px 0;
		font-size: 14px;
		line-height: 26px;
	}

	&__row {
		display: flex;
		margin-bottom: 70px;

		&:nth-child(odd) {
			#{$this}__desc {
				border: 1px solid #E8E8E8;
				border-left: none;
				order: 1;
			}
		}
	}

	&__desc {
		width: 50%;
		border: 1px solid #E8E8E8;
		border-right: none;
		padding: 46px 55px 36px;
	}

	&__desc-top {
		display: flex;
		align-items: center;
		margin-bottom: 19px;

		h2 {
			width: calc(100% - 60px);
			padding-left: 26px;
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		color: #fff;
		background: rgba(58, 100, 121, .6);
		font-size: 30px;
	}

	&__img {
		display: block;
		width: 50%;
		height: inherit;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

/*
================================================================================
|                                  ABOUT PAGE                                |
================================================================================
*/

.about-page {
	padding: 46px 50px;
	background-color: #fff;

	$this: &;

	h1 {
		padding-top: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		margin-bottom: 20px;
	}

	p {
		padding: 11px 0;
		font-size: 14px;
		line-height: 26px;
	}
}

.about-list {
	margin: 2px 0 9px;

	li {
		position: relative;
		padding-left: 21px;
		font-size: 14px;
		line-height: 26px;

		&::before {
			content: "";
			position: absolute;
			top: 10px;
			left: 0;
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: $color-3;
		}
	}
}

.stage-dev {
	margin-top: 90px;

	h2 {
		font-weight: 500;
		font-size: 22px;
		line-height: 20px;
		text-align: center;
	}
}

.stage-list {
	position: relative;
	padding-left: 120px;

	$this: &;

	.slick-list {
		overflow: visible;
		margin: 0 -20px;
		padding-right: 110px;
	}

	.slick-slide {
		opacity: 0;
		transition: $transition;
	}

	.slick-active {
		opacity: 1;
	}

	&-wrap {
		margin: 50px 0;
		position: relative;
	}

	&__item {
		margin-right: -110px;
		padding: 0 20px;

		&:focus {
			outline: none;
		}

		&:nth-child(odd) {
			#{$this}__wrap {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}

			#{$this}__desc {
				order: -1;
			}
		}

		&:nth-child(even) {
			#{$this}__year-shadow {
				top: 0;
				bottom: auto;
			}

			#{$this}__wrap::after {
				top: -8px;
				bottom: auto;
			}

			#{$this}__wrap::before {
				top: -1px;
				bottom: auto;
			}
		}
	}

	&__wrap {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 38px;
			display: block;
			height: 1px;
			width: calc(100% - 125px);
			background-color: $color-3;
		}

		&::after {
			content: "";
			position: absolute;
			z-index: 2;
			bottom: -8px;
			left: 2px;
			display: block;
			width: 16px;
			height: 16px;
			border: 4px solid #fff;
			background: $color-4;
			box-shadow: 0px 0px 0px 1px rgba(128, 157, 172, .3);
			border-radius: 50%;
		}
	}

	&__desc {
		position: relative;
		z-index: 1;
		font-size: 13px;
		line-height: 22px;
		color: rgba(51, 51, 51, .8);
	}

	&__year {
		position: relative;
		z-index: 1;
		padding: 35px 0 35px;
		font-family: $custom-font-family;
		font-weight: bold;
		font-size: 22px;
		line-height: 20px;
	}

	&__year-shadow {
		position: absolute;
		bottom: 0;
		left: 0;
		color: #FFFFFF;
		font-family: $custom-font-family;
		font-size: 70px;
		line-height: normal;
		font-weight: bold;
		text-shadow: 0px 0px 15px rgba(100, 135, 152, 0.15);
		white-space: nowrap;
		transform: translateX(-50%);
	}

	.slick-arrow {
		font-size: 12px;

		&:active {
			transform: translateY(-50%);
		}

		&::before {
			content: "";
			z-index: 1;
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			width: 100px;
			height: 20px;
			transform: translateY(-50%);
		}

		&::after {
			content: "";
			z-index: 2;
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			width: 100px;
			height: 1px;
			margin-top: -1px;
			background-color: $color-3;
			transition: $transition;
		}

		&:hover::after {
			background-color: $color-2;
		}
	}

	.slick-arrow.slick-prev {
		left: 0;
	}

	.slick-arrow.slick-next {
		right: 0;
	}

	.slick-arrow.slick-next::before,
	.slick-arrow.slick-next::after {
		right: 0;
		left: auto;
	}
}

/*
================================================================================
|                                  CONTACTS PAGE                                |
================================================================================
*/

.contacts-page {
	background-color: #fff;

	h1 {
		padding-top: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
	}

	h2 {
		padding-top: 5px;
		font-family: $custom-font-family;
		font-weight: bold;
		font-size: 18px;
		line-height: 20px;
	}

	p {
		padding: 5px 0;
		font-size: 14px;
		line-height: 26px;
	}

	&__row {
		border-bottom: 1px solid #E8E8E8;
		padding: 46px 50px 46px;

		&:last-child {
			border-bottom: none;
		}
	}

	&__form-wrap,
	&__map-wrap {
		display: flex;
	}

	&__map {
		display: block;
		width: 50%;
		height: inherit;
	}

	.contacts-map {
		display: block;
		width: 100%;
		height: 100%;
	}

	&__form {
		width: 50%;
		padding: 53px 65px;
		background-color: rgba(100, 135, 152, .12);
	}

	&__img {
		width: 50%;
		height: inherit;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.dealer-cert {
		padding: 0 0 0 24px;

		img {
			max-height: 144px;
			width: 100%;
		}
	}
}

.contacts-list {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 70%;
	padding-right: 32px;

	&-wrap {
		display: flex;
		align-items: center;
		margin: 12px 0 0;
	}

	&__item {
		display: flex;
		align-items: center;
		width: 50%;
		margin: 11px 0;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		color: #fff;
		background: rgba(58, 100, 121, .6);
		font-size: 32px;
	}

	&__desc {
		width: calc(100% - 60px);
		padding: 0 20px;
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}

	.time {
		white-space: nowrap;
	}
	.days {
		hyphens: auto;
	}
}

.contacts-list-info {
	width: 30%;
	padding: 30px 24px;
	background: rgba(238, 239, 240, .6);

	&__item {
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 14px;
		line-height: 200%;
		color: rgba(51, 51, 51, .8);
	}
}

.how-to-get {
	width: 50%;
	padding-right: 65px;

	h3 {
		font-family: $custom-font-family;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
	}

	p {
		font-size: 14px;
		line-height: 24px;
		color: rgba(51, 51, 51, .8);
	}

	&__row {
		margin: 27px 0;
	}

	&__title-wrap {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		h3 {
			padding-left: 18px;
			width: calc(100% - 48px);
		}
	}

	&__icon {
		display: block;
		width: 48px;
		height: 48px;
	}
}

.contacts-form {
	padding: 50px 32px;
	background-color: #fff;

	h2 {
		font-family: $base-font-family;
		font-size: 18px;
		line-height: 20px;
		padding-top: 0;
	}

	&__row {
		margin: 21px 0;
	}

	&__input,
	&__textarea {
		height: 32px;
		padding: 0;
		border: none;
		border-bottom: 1px solid $color-1;
		border-radius: 0;
		font-family: $base-font-family;
		color: $color-1;
		font-weight: 500;
		font-size: 14px;

		&::placeholder {
			color: rgba(51, 51, 51, .4);
		}
	}

	&__textarea {
		height: 80px;
	}

	&__btn {
		text-align: right;

		.btn {
			padding: 0 30px;
		}
	}
}

/*
================================================================================
|                                  PAYMENT PAGE                                |
================================================================================
*/

.pay-page {
	padding: 46px 43px;
	background-color: #fff;

	$this: &;

	h1 {
		padding-top: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
	}

	h2 {
		display: flex;
		align-items: center;
		font-family: $custom-font-family;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		padding-top: 0;
		margin-bottom: -5px;

		span {
			width: 17px;
			margin-right: 23px;
			font-weight: 500;
			font-size: 46px;
			line-height: normal;
			color: rgba(51, 51, 51, .4);
		}
	}

	p {
		font-size: 14px;
		line-height: 22px;
	}

	&__row {
		margin: 80px 0;

		&:first-of-type {
			margin-top: 55px;
		}
	}

	&__wrap {
		display: flex;
	}

	&__wrap_bg {
		margin: 48px 0;
		background: #F6F5F3;
		padding: 36px 50px;
	}

	&__wrap_box {
		padding: 47px 49px;
		box-shadow: 0px 4px 8px rgba(92, 104, 112, 0.16);

		#{$this}__img {
			width: 397px;
		}

		#{$this}__info {
			width: calc(100% - 397px);
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		padding-right: 40px;
	}

	&__inner-img {
		margin-top: 32px;
		margin-right: -40px;
	}

	&__img {
		display: block;
		width: 50%;

		img {
			margin-left: auto;
		}
	}

	&__phone {
		font-size: 14px;
		line-height: 22px;

		i {
			margin-right: 5px;
			color: $color-4;
			font-size: 12px;
		}
	}

	.about-list li {
		line-height: 23px;
	}
}

.pay-inner-list {
	padding: 10px 16px;

	li {
		font-size: 14px;
		line-height: 22px;
	}
}

.pay-list {
	display: flex;
	align-items: stretch;
	margin: -15px;

	&-wrap {
		margin-top: 40px;
	}

	&__item {
		display: flex;
		width: 20%;
		padding: 15px;
	}

	&__wrap {
		flex: 1;
		border: 1px solid #E8E8E8;
		padding: 33px 28px 13px;
		text-align: center;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		margin: 0 auto 9px;
		border-radius: 50%;
		color: #fff;
		background: rgba(58, 100, 121, .6);
		font-size: 32px;
	}

	p {
		font-size: 13px;
		line-height: 20px;
		color: rgba(51, 51, 51, .7);
	}
}

.pay-type-list {
	display: flex;
	margin: -8px;

	&-wrap {
		margin-top: 31px;
	}

	&__item {
		width: 12.5%;
		padding: 8px;
	}

	&__wrap {
		width: 100%;
		height: 72px;
		padding: 0 17px;
		border: 1px solid #E8E8E8;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
}

.pay-step-list {
	display: flex;
	margin: -13px;
	counter-reset: item;

	$this: &;

	&-wrap {
		margin-top: 92px;
	}

	&__item {
		position: relative;
		display: flex;
		width: 25%;
		padding: 13px;

		&:last-child #{$this}__wrap::after {
			display: none;
		}
	}

	&__wrap {
		position: relative;
		flex: 1;
		padding: 24px 24px 28px;
		border: 1px solid #E8E8E8;
		background: #FFFFFF;

		&::before {
			counter-increment: item;
			content: counter(item);
			position: absolute;
			z-index: 2;
			top: 0px;
			left: 0px;
			display: block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: $color-4;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			line-height: 30px;
			text-align: center;
			transform: translate(-50%, -50%);
		}

		&::after {
			content: "";
			position: absolute;
			top: -8px;
			left: 0;
			display: block;
			width: calc(100% + 10px);
			height: 30px;
			background: url(#{$cdn}/static/svg/line.svg) no-repeat center center;
			background-size: 100% 100%;
			transform: translateY(-100%);
		}
	}

	&__img {
		height: 206px;
	}

	&__img img {
		margin: 0 auto;
		max-height: 100%;
		margin-bottom: 20px;
	}

	h3 {
		padding-top: 0;
		font-family: $custom-font-family;
		font-size: 16px;
		line-height: 22px;
		font-weight: bold;
	}

	p {
		font-size: 14px;
		line-height: 22px;
	}
}

.pay-info-list {
	display: flex;
	margin: -12px;
	counter-reset: item;

	$this: &;

	&-wrap {
		margin-top: 87px;
	}

	&__item {
		display: flex;
		width: 20%;
		padding: 12px;

		&:last-child #{$this}__wrap::after {
			display: none;
		}
	}

	&__wrap {
		position: relative;
		flex: 1;
		padding: 30px 24px;
		border: 1px solid #E8E8E8;
		background: #FFFFFF;
		font-size: 14px;
		line-height: 22px;

		&::before {
			counter-increment: item;
			content: counter(item);
			position: absolute;
			z-index: 2;
			top: 0px;
			left: 0px;
			display: block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: $color-4;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			line-height: 30px;
			text-align: center;
			transform: translate(-50%, -50%);
		}

		&::after {
			content: "";
			position: absolute;
			top: -11px;
			left: 7px;
			display: block;
			width: 100%;
			height: 30px;
			background: url(#{$cdn}/static/svg/line-1.svg) no-repeat center center;
			background-size: 100% 100%;
			transform: translateY(-100%);
		}
	}
}

/*-------------------------------------------------------------------*/

@media (max-width: 1440px) {
	.box-search .catalog-main-list .product-list__item {
		width: 25%;
	}

	.contacts-list {
		padding-right: 20px;
	}
}

@media (max-width: 1279px) {
	.box-search .catalog-main-list .product-list__item {
		width: 33.33%;
	}

	.box-search__row:nth-child(2) {
		padding-bottom: 20px;
	}

	.search-form__wrap {
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.search-form__field {
		padding-bottom: 25px;
	}

	.search-form__wrap {
		padding-top: 0;
	}

	.service-page__row,
	.service-page__row:last-child {
		padding: 30px 35px;
	}

	.service-list-wrap {
		margin: 20px 0 40px;
	}

	.delivery-page {
		padding: 30px 30px 20px;
	}

	.delivery-page__desc {
		padding: 25px 25px 20px;
	}

	.delivery-page__row {
		margin-bottom: 50px;
	}

	.contacts-page__row {
		padding: 30px 35px;
	}

	.how-to-get {
		padding-right: 35px;
	}

	.contacts-page__form {
		padding: 40px 35px;
	}

	.contacts-list-info {
		padding: 25px 20px;
	}

	.pay-page {
		padding: 30px;
	}

	.pay-page__row:first-of-type {
		margin-top: 40px;
	}

	.pay-page__row {
		margin: 60px 0;
	}

	.pay-list__wrap {
		padding: 20px 15px 10px;
	}

	.pay-page__wrap_bg {
		margin: 40px 0;
		padding: 25px 30px;
	}

	.pay-page__img img {
		padding-left: 20px;
	}

	.pay-page__inner-img {
		margin-right: 0;
	}

	.pay-page__info {
		padding-right: 20px;
	}

	.pay-info-list__wrap {
		padding: 25px 15px;
		font-size: 13px;
		line-height: 20px;
	}

	.pay-page__wrap_box {
		padding: 25px 30px;
	}

	.pay-step-list,
	.pay-info-list,
	.pay-list {
		margin: -7.5px;
	}

	.pay-info-list__item,
	.pay-step-list__item,
	.pay-list__item {
		padding: 7.5px;
	}

	.pay-step-list__wrap {
		padding: 20px 15px;
	}

	.pay-step-list__img {
		height: 180px;
	}

	.about-page {
		padding: 30px;
	}
}

@media (max-width: 1023px) {
	.box-search {
		margin: 20px -20px;
	}

	.compare-table {
		margin: 0 -20px;
	}

	.box-search-none {
		margin: 30px 0 100px;
	}

	.box-search .product-list-wrap {
		margin-top: 10px;
	}

	.box-search .catalog-main-list__top {
		padding: 0;
	}

	.box-search .catalog-main-list {
		padding: 0;
	}

	.compare-table__item {
		font-size: 13px;
		line-height: normal;
	}

	.compare-table__item_header {
		width: 140px;
		margin-right: 10px;
		font-size: 13px;
		line-height: normal;
	}

	.compare-table__item-wrap {
		width: calc(100% - 150px);
	}

	.compare-table .col {
		padding: 10px 8px;
	}

	.compare-table__link-wrap {
		padding: 5px 0;
	}

	.compare-table__link {
		font-size: 13px;
		line-height: normal;
	}

	.compare-table__item-wrap .compare-table__item {
		width: 200px;
		min-width: 200px;
	}

	.compare-table__img {
		height: 140px;
	}

	.compare-table__basket,
	.compare-table__link-remove {
		font-size: 13px;
	}

	.service-page__desc-wrap {
		padding: 35px 25px;
	}

	.service-page .mCSB_inside > .mCSB_container {
		margin-right: 30px;
	}

	.service-page__wrap {
		margin: 50px 0 20px;
	}

	.service-page__row,
	.service-page__row:last-child {
		padding: 25px 20px;
	}

	.service-page {
		margin: 0 -20px;
	}

	.delivery-page {
		margin: 0 -20px;
		padding: 20px;
	}

	.delivery-page__row {
		position: relative;
		margin-bottom: 30px;
	}

	.delivery-page__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.delivery-page__desc {
		position: relative;
		z-index: 1;
		width: 100%;
		border: 1px solid #E8E8E8;
		background-color: rgba(255, 255, 255, .9);
	}

	.contacts-page {
		margin: 0 -20px;
	}

	.contacts-page__row {
		padding: 25px 20px;
	}

	.contacts-list-wrap {
		flex-wrap: wrap;
	}

	.contacts-list {
		width: 100%;
		padding-right: 0;
	}

	.contacts-list-info {
		width: 380px;
		max-width: 100%;
		margin-top: 30px;
	}

	.contacts-page__form-wrap,
	.contacts-page__map-wrap {
		position: relative;
		flex-wrap: wrap;
	}

	.how-to-get {
		width: 100%;
		padding-right: 0;
	}

	.contacts-page__map {
		width: 100%;
		height: 450px;
	}

	.contacts-page__img {
		width: 100%;
		height: 500px;
	}

	.contacts-form {
		padding: 50px 0px;
	}

	.contacts-page__form {
		position: relative;
		z-index: 1;
		width: 100%;
		background-color: rgba(255, 255, 255, .9);
	}

	.contacts-page__form {
		padding: 20px 20px;
	}

	.contacts-page h2 {
		padding-top: 0;
	}

	.pay-page {
		padding: 25px 20px;
		margin: 0 -20px;
	}

	.about-page {
		padding: 25px 20px;
		margin: 0 -20px;
		width: auto;
	}

	.pay-inner-list {
		padding: 10px 0;
	}

	.pay-page__img img {
		padding-left: 0;
	}

	.pay-list,
	.pay-info-list {
		flex-wrap: wrap;

		&__item {
			width: 33.3%;
		}
	}

	.pay-page__wrap_box .pay-page__img {
		width: auto;
	}

	.pay-page__wrap_box .pay-page__info {
		width: 100%;
	}

	.pay-page__row {
		margin: 50px 0;
	}

	.pay-type-list {
		flex-wrap: wrap;

		&__item {
			width: 16.66%;
		}
	}

	.pay-step-list {
		flex-wrap: wrap;
	}

	.pay-step-list,
	.pay-info-list {
		margin: -15px -7.5px;
	}

	.pay-step-list__item,
	.pay-info-list__item {
		padding: 15px 7.5px;
		width: 100%;
	}

	.pay-info-list__wrap::after,
	.pay-step-list__wrap::after {
		display: none;
	}

	.pay-list__item {
		width: 100%;
	}

	.pay-list__wrap {
		display: flex;
		align-items: center;
		padding: 15px;
	}

	.pay-list__icon {
		margin: 0;
	}

	.pay-list p {
		width: calc(100% - 60px);
		padding: 0;
		padding-left: 20px;
		text-align: left;
	}

	.pay-page__wrap_bg,
	.pay-page__wrap_box {
		padding: 20px;
	}

	.pay-page__wrap_box {
		flex-wrap: wrap;
	}

	.pay-page__row:last-child {
		margin-bottom: 0;
	}

	.stage-list__year-shadow {
		bottom: 10px;
		font-size: 40px;
	}

	.stage-list__item:nth-child(even) .stage-list__year-shadow {
		top: 10px;
	}

	.stage-dev {
		margin-top: 50px;
	}

	.contacts-list-wrap {
		.dealer-cert {
			img {
				margin-top: 20px;
			}
		}
	}
}

@media (max-width: 767px) {
	.box-search.box-search_stocks .catalog-main-list {
		margin-top: 0;
	}

	.box-search h1,
	.box-search h2 {
		font-size: 18px;
		line-height: 22px;
	}

	.box-search__row {
		padding: 18px 20px;
	}

	.box-search-none__desc {
		font-size: 17px;
		line-height: normal;
	}

	.search-form .col {
		flex-direction: column;
		width: 100%;
	}

	.search-form .form-filter__checkbox {
		padding-bottom: 15px;
	}

	.search-form__btn {
		margin-top: 10px;
		text-align: center;
	}

	.box-search .catalog-main-list .product-list__item {
		width: 50%;
	}

	.service-list__desc {
		font-size: 15px;
	}

	.service-page p,
	.delivery-page p {
		font-size: 13px;
		line-height: 26px;
	}

	.service-page__btn {
		text-align: center;
	}

	.service-page__desc-wrap {
		width: calc(45% + 150px);
		margin: 48px -150px 48px 0;
	}

	.delivery-page h1 {
		margin-bottom: 15px;
	}

	.delivery-page__desc {
		padding: 25px 20px 20px;
	}

	.contacts-list__icon {
		width: 50px;
		height: 50px;
		font-size: 28px;
	}

	.contacts-list__desc {
		width: calc(100% - 50px);
		padding: 0 15px;
		font-size: 14px;
		line-height: normal;
	}

	.pay-type-list__item {
		width: 20%;
	}

	.pay-page__wrap {
		flex-wrap: wrap;
	}

	.pay-page__info {
		width: 100%;
		padding-right: 0;
		margin-bottom: 30px;
	}

	.pay-page__img {
		width: 100%;
	}

	.pay-page__img img {
		margin: 0 auto;
	}

	.pay-step-list__wrap,
	.pay-info-list__wrap {
		padding: 15px 10px 10px;
	}

	.pay-type-list__item {
		width: 25%;
	}

	.pay-page p,
	.pay-inner-list li,
	.about-list li,
	.about-page p {
		font-size: 13px;
		line-height: 22px;
	}

	.pay-step-list__wrap::before,
	.pay-info-list__wrap::before {
		width: 26px;
		height: 26px;
		font-size: 14px;
		line-height: 26px;
	}

	.pay-step-list h3 {
		font-size: 15px;
		line-height: normal;
	}

	.compare-table__basket {
		min-width: inherit;
		padding: 0 7px;
	}

	.compare-table__link-remove-wrap {
		margin-top: 15px;
	}

	.stage-list {
		padding-left: 20px;
	}

	.stage-list__year-shadow {
		transform: none;
	}

	.stage-list .slick-arrow::before,
	.stage-list .slick-arrow::after {
		display: none;
	}

	.stage-dev {
		margin-top: 25px;
	}

	.stage-list-wrap {
		margin: 30px 0 0;
	}

	.about-page h1 {
		margin-bottom: 0;
	}

	.stage-list__year {
		padding: 25px 0;
	}

	.contacts-list-wrap {
		.dealer-cert {
			padding: 0;
		}
	}
}

/*------------------------------*/

@media (min-width: 767px) {
	.box-search .product-list_row {
		.product-list__item {
			width: 100%;
		}

		.product-list__wrap {
			flex-direction: row;
		}

		.product-list__img-wrap {
			width: 170px;
			padding: 10px;
			margin: -14px;
			margin-right: 0;
		}

		.product-list__img {
			width: 100%;
			height: 112px;
		}

		.product-list__row-wrap {
			flex: 1;
			padding: 0 20px;
		}

		.product-list__name {
			max-width: 525px;
			margin: 28px 0 12px;
		}

		.product-list__bottom {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			margin-top: 0;
			text-align: right;
		}

		.product-list__price-old {
			position: static;
		}

		.product-list__price {
			margin: 8px 0 6px;
		}

		.product-list__info {
			left: 190px;
		}

		.product-list__order,
		.product-list__availability {
			position: absolute;
			bottom: 10px;
			left: 85px;
			transform: translateX(-50%);
		}
	}
}

@media (max-width: 500px) {
	.service-page .mCSB_inside > .mCSB_container {
		margin-right: 0;
	}

	.service-page .js-scroll .mCSB_scrollTools {
		opacity: 0;
	}

	.box-search .catalog-main-list .product-list__item {
		width: 100%;
	}

	.search-form__input-wrap,
	.search-form .catalog-filter__top {
		width: 100%;
	}

	.catalog-filter__wrap {
		flex-grow: 1;
	}

	.search-form__field {
		margin-right: 0;
	}

	.search-form .form-filter__checkbox-label {
		font-weight: normal;
	}

	.search-form__label {
		margin-bottom: 8px;
	}

	.compare-table__item_header {
		width: 120px;
		font-size: 12px;
	}

	.compare-table__item-wrap {
		width: calc(100% - 130px);
	}

	.service-page__wrap {
		position: relative;
		margin: 25px -20px 10px;
	}

	.service-page__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.service-page__desc-wrap {
		width: 100%;
		height: auto;
		margin: 0;
		padding: 30px 20px;
		background-color: rgba(255, 255, 255, .9);
	}

	.service-list__icon,
	.delivery-page__icon {
		width: 50px;
		height: 50px;
		font-size: 28px;
	}

	.service-list__desc,
	.delivery-page h2 {
		width: calc(100% - 50px);
		padding-left: 15px;
	}

	.delivery-page__desc-top {
		margin-bottom: 10px;
	}

	.contacts-list__item {
		width: 100%;
	}

	.contacts-page__form {
		padding: 0;
	}

	.contacts-page__img {
		height: 400px;
	}

	.contacts-form {
		padding: 40px 0 20px;
	}

	.pay-page__wrap_bg {
		margin: 20px -20px;
		padding: 20px;
	}

	.pay-page__wrap_box {
		padding: 0;
		box-shadow: none;
	}

	.pay-step-list,
	.pay-info-list,
	.pay-list {
		margin: -7.5px;
	}

	.pay-step-list__item,
	.pay-info-list__item,
	.pay-list__item {
		width: 100%;
		padding: 7.5px;
	}

	.pay-type-list__item {
		width: 33.33%;
	}

	.pay-list__wrap {
		padding: 10px;
	}

	.pay-list p {
		padding-left: 15px;
	}

	.pay-page__row:first-of-type {
		margin-top: 15px;
	}

	.pay-step-list__wrap::before,
	.pay-info-list__wrap::before {
		transform: translate(-50%, -40%);
	}

	.basket-form-promocode__input {
		padding: 0 115px 0 10px;
	}

	.stage-list-wrap {
		margin: 10px 0 0;
	}

	.contacts-page__map {
		height: 350px;
		pointer-events: none;
	}
}