/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
.box-footer {
	order: 3;
	width: 100%;
	min-width: $min-width;
	height: auto;
	background-color: #fff;
	text-align: left;
	margin-top: 30px;
	.wrapper:after {
		display:none;
	}
	&__top {
		display: flex;
		justify-content: space-between;
		padding: 41px 0 34px;
		margin: 0 -10px;
		border-bottom: 1px solid rgba(51, 51, 51, .2);

		.col {
			padding: 0 10px;
		}

		.col_2 {
			width: 19%;
		}

		.col_3 {
			width: 19%;
		}

		.col_4 {
			width: 20%;
		}
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		padding: 34px 0;
		font-size: 14px;
		line-height: 16px;
	}

	.header-logo__desc {
		margin-top: 16px;
		font-size: 12px;
		line-height: 18px;
	}
}

.footer-title {
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 15px;
	line-height: 18px;
}

.footer-nav {
	li {
		padding: 6px 0;
	}

	a {
		opacity: .8;
		font-size: 13px;
		line-height: 19px;
	}
}

.contact-list {
	li {
		padding: 6px 0;
		opacity: .8;
		font-size: 13px;
		line-height: 19px;
	}
}

.payment-list {
	display: flex;
	flex-wrap: wrap;

	&-wrap {
		margin-top: -8px;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

/*-------------------------------------------------------------------------------*/

@media screen and (max-width: 1279px) {
	.box-footer__top {
		flex-wrap: wrap;
		padding: 41px 0 18px;
	}

	.box-footer__top .col_1 {
		width: 22%;
	}

	.box-footer__top .col_2 {
		width: 23%;
	}

	.box-footer__top .col_3 {
		width: 23%;
	}

	.box-footer__top .col_4 {
		width: 32%;
	}

	.box-footer__top .col_5 {
		width: 100%;
		margin-top: 33px;
		padding-left: calc(22% + 10px);
	}

	.payment-list-wrap {
		margin-top: 0;
	}

	.payment-list {
		max-width: 100%;
		margin: -17.5px -25px;
	}

	.payment-list__item {
		width: auto;
		height: auto;
		padding: 17.5px 25px;
	}

}

@media screen and (max-width: 767px) {
	.box-footer__top .col_1 {
		display: none;
	}

	.box-footer__top .col {
		width: 33.3%;
	}

	.box-footer__top .col_5 {
		width: 100%;
		padding: 0 10px;
	}

	.payment-list {
		max-width: inherit;
	}

	.box-footer__bottom {
		opacity: .5;
	}

	.payment-list {
		margin: -13px -20px;
	}
	
	.payment-list__item {
		padding: 13px 20px;
	}
	
}

@media screen and (max-width: 500px) {
	.box-footer__top {
		padding: 41px 0 24px;
	}

	.box-footer__top .col {
		width: 100%;
		margin-bottom: 45px;
		text-align: center;

		&_3 {
			display: none;
		}

		&_5 {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.payment-list {
		justify-content: center;
	}

	.box-footer__bottom {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 25px 0;
	}

	.footer-title {
		margin-bottom: 27px;
	}

	.footer-nav ul {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: -6px -7.5px;

		li {
			padding: 6px 7.5px;
		}
	}

	.contact-list-wrap {
		margin-top: -12px;
	}

	.footer-copy {
		margin-bottom: 12px;
	}
}