/*
================================================================================
|                                CATALOG PAGE                                  |
================================================================================
*/

.box-catalog {
	margin: 20px 0 0;

	&__wrap {
		display: flex;
		align-items: flex-start;
	}
}

.catalog-aside {
	width: 325px;
	padding: 0 20px;
	background-color: #fff;

	h2 {
		font-size: 20px;
		line-height: 30px;
	}
}

.catalog-main {
	width: calc(100% - 335px);
	margin-left: 30px;

	h1 {
		padding: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
	}
}

.aside-list {
	margin: 0 -20px;

	$this: &;

	&__item {
		border-top: 1px solid #ECF1F4;
	}

	&__top {
		display: flex;
		align-items: center;
		padding: 8.5px 20px;
		cursor: pointer;

		&.active {
			#{$this}__icon {
				color: $color-2;
			}

			#{$this}__arrow {
				transform: rotate(90deg);
			}
		}

		&:hover {
			#{$this}__icon {
				color: $color-2;
			}

			#{$this}__arrow {
				color: rgba(58, 100, 121, 1);
			}
		}
	}

	&__icon {
		width: 20px;
		color: $color-3;
		font-size: 20px;
		text-align: center;
		transition: $transition;
	}

	&__name {
		padding-left: 20px;
		font-size: 13px;
		line-height: normal;
	}

	&__arrow {
		margin-left: auto;
		font-size: 12px;
		color: rgba(58, 100, 121, .5);
		transition: $transition;
	}

	&__info {
		display: none;
		border-top: 1px solid #ECF1F4;
	}
}

.aside-innerlist {
	padding: 10px 20px 10px 60px;

	&__item {
		padding: 6px 0;
	}

	&__link {
		font-size: 13px;
		line-height: normal;
		color: rgba(51, 51, 51, .7);
	}
}

.catalog-production {
	margin: 0 0 20px;

	h1 {
		padding: 0 0 15px;
	}
}

.catalog-production-list {
	display: flex;
	flex-wrap: wrap;
	margin: -5px;

	$this: &;

	&__item {
		width: 25%;
		padding: 5px;
	}

	&__wrap {
		display: block;
		width: 100%;

		&:hover {
			color: $color-1;

			#{$this}__img:after {
				background: rgba(58, 100, 121, 0);
			}
		}

	}

	&__img {
		position: relative;
		height: 130px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(58, 100, 121, 0.6);
			transition: $transition;
		}
	}

	&__info {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		min-height: 60px;
		padding: 0 30px;
		background-color: #fff;
	}

	h2 {
		font-size: 16px;
		line-height: 19px;
		text-align: center;
	}
}

/*------------------------------*/

.catalog-aside.catalog-aside_filter {
	position: relative;

	.form-filter-top {
		display: none;
	}
}

.form-filter-top {
	display: flex;
	align-items: center;
	margin: 0 -20px;
	padding: 7px 20px;
	background-color: #587E8F;
	color: #fff;
	cursor: pointer;

	&.active {
	}

	&__icon {
		width: 25px;
		font-size: 25px;
		color: #fff;
	}

	&__title {
		padding-left: 12px;
		margin-right: auto;
		font-size: 16px;
		line-height: normal;
	}

	&__icon {
		width: 25px;
		font-size: 25px;
		color: #fff;
	}

	&__arrow {
		color: rgba(255, 255, 255, .5);
		font-size: 12px;
		transform: rotate(90deg);
		transition: $transition;
	}
}

.form-filter {
	margin: 0 -20px;
	$this: &;

	&__accord {
		&:last-child {
			margin-bottom: 0;
		}
		&.active #{$this}__accord-icon {
			color: rgba(58, 100, 121, .5);
			transform: rotate(90deg);

		}
	}

	&__accord-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}

	&__accord-wrap {
		padding: 17px 0 2px;
	}

	&__accord-title {
		font-weight: bold;
		font-size: 16px;
		line-height: normal;
	}

	&__accord-icon {
		color: rgba(58, 100, 121, 1);
		transform: rotate(90deg) scaleX(-1);
		font-size: 12px;
		transition: $transition;
	}

	&__price-wrap {
		padding: 6px 0;
	}

	&__price-inputs {
		display: flex;
		align-items: center;
		margin-bottom: 23px;
	}

	&__price-input-wrap {
		position: relative;

		white-space: nowrap;
		span {
			position: absolute;
			top: 5px;
			left: 9px;
			font-size: 13px;
			font-weight: bold;
			line-height: normal;
		}
	}

	&__price-input {
		width: 80px;
		height: 26px;
		border: 1px solid #C3D3DD;
		border-radius: 4px;
		padding: 0 5px 0 26px;
		color: #6E6E6E;
		font-size: 13px;
		font-weight: bold;
		line-height: normal;
		display: inline-block;
	}

	&__price-divider {
		width: 32px;
		font-weight: bold;
		font-size: 13px;
		line-height: normal;
		text-align: center;
	}

	&__checkbox label {
		margin: 10px 0;
		width: calc(100% - 30px);
	}

	&__checkbox-input {
		display: none;
	}

	&__checkbox-label {
		position: relative;
		display: inline-block;
		padding-left: 25px;
		font-weight: bold;
		font-size: 13px;
		line-height: normal;
		cursor: pointer;
	}

	&__checkbox-label::before {
		content: "";
		position: absolute;
		top: -1px;
		left: 0;
		display: block;
		width: 16px;
		height: 16px;
		border: 2px solid $color-3;
		border-radius: 4px;
		color: #fff;
		background: #fff;
		font-size: 6px;
		line-height: 12px;
		text-align: center;
		transition: $transition;
	}
	&__bottom {
		padding: 15px 0 20px;
		text-align: center;
	}

	&__count {
		font-weight: bold;
		font-size: 13px;
		line-height: normal;
		color: rgba(51, 51, 51, .6);
	}

	&__btn {
		margin: 20px 0 16px;

		.btn {
			padding: 0 65px;
		}
	}

	&__reset {
		display: inline-block;
		height: auto;
		width: 100%;
		border: none;
		border-bottom: 1px solid;
		border-radius: 0;
		padding: 0;
		font-weight: bold;
		font-size: 13px;
		line-height: normal;
		color: rgba(51, 51, 51, .4);
		cursor: pointer;
		text-decoration: underline;
		border: none;
		margin: 0;
		transition: $transition;

		&:hover {
			color: $color-2;
		}
	}
}

.mfilter-input-active {
	.form-filter__checkbox-label::before {
		content: "\e910";
		border-color: $color-2;
		color: $color-2;
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
	}
}

/*------------------------------*/

.catalog-main-list {
	margin: 20px 0;
	background-color: #fff;

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 20px;
		border-bottom: 1px solid #ECF1F4;

		.col {
			display: flex;
			align-items: center;
		}
	}

	&__compare {
		display: flex;
		align-items: center;
		margin-right: 20px;
		font-size: 13px;
		line-height: normal;
		color: #000;

		&:hover {
			i {
				color: $color-2;
			}
		}

		i {
			margin-right: 10px;
			font-size: 18px;
			color: $color-3;
			transition: $transition;
		}
	}

	&__view {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: rgba(58, 100, 121, .5);
		i {
			color: rgba(58, 100, 121, .5);
		}
	}

	&__list,
	&__tile {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 34px;
		height: 34px;
		border-radius: 50%;
		background: rgba(100, 135, 152, 0);
		cursor: pointer;
		transition: $transition;

		&:hover {
			color: rgba(58, 100, 121, 1);
			i {
				color: rgba(58, 100, 121, 1);
			}
		}

		&.active {
			background: rgba(100, 135, 152, .2);
			color: rgba(58, 100, 121, 1);
			i {
				color: rgba(58, 100, 121, 1);
			}
		}

	}

	.product-list {
		display: flex;
		flex-wrap: wrap;
		margin: -5px;
		width: 100%;

		&-wrap {
			padding: 14px 20px;
		}

		&__item {
			width: 25%;
			padding: 5px;

		}

		&__item:nth-child(n) {
			display: block;
		}

		&__row-wrap {
			margin-bottom: auto;
		}
	}

	.pagination-list-wrap {
		justify-content: space-between;
		align-items: center;
	}

	.product-list-more {
		display: block;
		margin-top: 0;
		margin-left: -55px;
	}
}

.pagination-list-info {
	font-size: 13px;

	span {
		color: rgba(51, 51, 51, .5);
	}
}

//Dropdown menu
.catalog-filter {
	display: flex;
	margin-right: 39px;

	&:last-child {
		margin-right: 0;
	}

	$this: &;

	&-wrap {
		margin-left: 40px;
	}

	&__title {
		font-size: 13px;
		line-height: normal;
		margin-right: 9px;
	}

	&__wrap {
		position: relative;
	}

	&__wrap.active {
		.filter-list {
			z-index: 30;
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}

		#{$this}__top-icon {
			transform: rotate(-90deg) scaleX(-1);
		}
	}

	&__top {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__top-type {
		color: rgba(51, 51, 51, .5);
		margin-right: 17px;
		font-size: 13px;
		line-height: normal;
	}

	&__top-icon {
		font-size: 12px;
		color: $color-3;
		transform: rotate(-90deg);
		cursor: pointer;
		transition: $transition;
	}
}

.filter-list {
	position: absolute;
	z-index: -1;
	top: calc(100% + 10px);
	left: -10px;
	overflow: hidden;
	opacity: 0;
	width: calc(100% + 20px);
	border: 1px solid #d2d2d2;
	border-radius: 4px;
	box-shadow: 1px 1px 8px #C4C4C4;
	transform: translate3d(0, 5px, 0);
	transition: $transition;

	&__item {
		display: block;
		border-bottom: 1px solid #d2d2d2;
		padding: 8px 10px;
		background: #fff;
		color: rgba(51, 51, 51, .5);
		font-size: 13px;
		line-height: normal;
		cursor: pointer;
		box-sizing: content-box;
		transition: $transition;

		&.active {
			color: rgba(51, 51, 51, 1);
		}

		&:hover {
			color: rgba(51, 51, 51, 1);
		}

		&:last-child {
			border-bottom: none;
		}
	}
}

.box-news__right {
	margin-top: 18px;
	justify-content: flex-end;
}

.aside-innerlist__item.active a {
	color: $color-2;
}

.box-search .selectric-items {
	right: auto;
	left: 0;
}

.box-search .selectric-items li {
	color: rgba(51, 51, 51, 0.5);
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
}

.box-search .catalog-filter {
	.selectric {
		width: 300px;
		max-width: 100%;
		height: 40px;
		border: 1px solid #D3D3D3;
		border-radius: 50px;
		padding: 0 15px;
		font-family: "Gilroy", sans-serif;
		.label {
			height: 100%;
			text-align: left;
			color: rgba(51, 51, 51, 0.5);
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
		}
		b:after {
			width: 9px;
			height: 9px;
			border-bottom: 2px solid rgba(51, 51, 51, 0.3);
			border-right: 2px solid rgba(51, 51, 51, 0.3);
			right: 20px;
			top: 13px;
		}
	}
	.selectric-items {
		border: 1px solid #d2d2d2;
		border-radius: 4px;
		-webkit-box-shadow: 1px 1px 8px #C4C4C4;
		box-shadow: 1px 1px 8px #C4C4C4;
		right: auto;
		top: calc(100% + 6px);
		left: 0;
		border-radius: 20px;
		overflow: hidden;
		padding-left: 9px;
		max-width: 400px;
		.selectric-scroll {
			padding-top: 5px;
		}
		li {
			white-space: initial;
		}
		.lvl_1 {
			padding-left: 10px;
		}
		.lvl_2 {
			padding-left: 30px;
		}
		.lvl_3 {
			padding-left: 60px;
		}
	}

}

.box-search .selectric-items li.selected, .box-search .selectric-items li.highlighted, .box-search .selectric-items li:hover {
	color: #333;
	background: none;
}

.feedback-form__rating input {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 30px !important;
	height: 30px !important;
	border: 1px solid #DAE4EA;
	border-radius: 4px;
	margin-right: 12px;
	background: #FFFFFF;
}

.feedback-form__rating input:checked {
	background: black;
}

body .box-basket #content {
	padding-top: 0;
}

.popup-form h2 {
	display: initial;
}

.popup-form h2 a {
	display: initial;
	font-size: 20px;
}

.success {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.70);
	z-index: 9999;
	margin: 0;
	h2 {
		display: inline-block;
	}
	.popup-compare__close {
		font-weight: 400;
	}
}

.fancybox-overlay {
	display: inline-block;
	width: 100%;
	height: 100%;
}

.success .popup {
	width: 640px;
	height: auto;
	position: absolute;
	top: 20%;
	left: calc(50% - 320px);
	opacity: 1;
	overflow: visible;
	background: white;
	margin: 0 auto;
}

.popup.popup-product {
	background: white;
}

.fancybox-opened .fancybox-skin {
	padding: 0 !important;
}

.fancybox-lock {
	z-index: 9999;
}

.fancybox-overlay + .fancybox-overlay {
	display: none !important;
}

#reserve2_form, #reserve_form_one_click {
	z-index: 99999;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal {
	.modal-dialog {
		max-width: 490px;
	}
	h2, .h2, h3, .h3 {
		font-weight: bold;
		font-size: 22px;
		line-height: 28px;
		text-align: center;
	}
	.form-group {
		margin: 14px 0;
		input, textarea {
			height: 32px;
			padding: 0;
			border: none;
			border-bottom: 1px solid #333;
			border-radius: 0;
			font-family: "Roboto", sans-serif;
			color: #333;
			font-weight: 500;
			font-size: 14px;
			display: block;
			width: 100%;
			&:focus {
				border-bottom: 1px solid #f90;
			}
		}
	}
	label {
		font-family: "Roboto", sans-serif;
		color: #999999;
	}
	.btn-primary, input.btn-primary, .callback_button {
		height: 50px;
		padding: 0 48px;
		font-family: "Gilroy", sans-serif;
		font-size: 14px;
		font-weight: bold;
		background-color: #f90;
		color: #fff;
		width: auto;
		background-image: none;
		&:hover {
			color: #333;
			background-color: transparent;
		}
	}
	.close::before {
		content: "\e934";
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 12px;
		color: #B1C3CB;
		-webkit-transition: 0.4s ease-out;
		-o-transition: 0.4s ease-out;
		transition: 0.4s ease-out;

	}
	.modal-content {
		padding: 20px 27px 26px;
	}
	.modal-header, .modal-body, .col-sm-12 {
		padding: 0;
		border: none;
	}
	.row {
		margin: 0;
	}
	.close {
		position: relative;
		color: white;
		fill: white;
		opacity: 1;
	}
	.close:hover::before {
		color: #333;
	}
	.error {
		padding: 0;
		color: red;
	}
}

#callback_form {
	label {
		display: none;
	}
	.col-sm-9 {
		width: 100%;
	}
	h2 {
		max-width: 280px;
		margin: 0 auto -24px;
	}
	.modal-header {
		min-height: 0px;
	}
	.callback_button {
		float: none !important;
		margin: 0 auto;
		display: flex;
		max-width: 200px;
	}
}

.catalog-main-list__view {
	button {
		border: none;
		background: none;
	}
}

#input-sort .filter-list {
	min-width: 200px;
}

/* pagination */
.pagination li span, .pagination li a {
	border: 1px solid #f90;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	-o-border-radius: 50px;
	border-radius: 50px;
}

.pagination {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 -5px;
}

.pagination > li {
	padding: 0 5px;
}

.pagination > li > a,
.pagination > li > span {
	width: 34px;
	height: 34px;
	border: 1px solid #E8E8E8;
	border-radius: 50%;
	background: #FFFFFF;
	font-size: 15px;
	line-height: 32px;
	text-align: center;
	display: inline-block;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
	border: 1px solid #f90;
	-webkit-box-shadow: inset 0 0 0 1px #f90;
	box-shadow: inset 0 0 0 1px #f90;

}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
	margin-left: 0;
}

.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
	border: 1px solid #f90;
	-webkit-box-shadow: inset 0 0 1px 1px #f90;
	box-shadow: inset 0 0 1px 1px #f90;
	color: #333;
	cursor: pointer;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
	margin-left: 0;
}

.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {

	cursor: pointer;
}

.sphinxsearch {
	width: 535px;
	right: 90px;
	left: auto;
	position: absolute;
	top: 100%;
	margin-top: 0;
	border: 1px solid #ebebeb;
	z-index: 99999;
	background: #fff;
	border-radius: 0;
	a {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: 1px solid rgba(51, 51, 51, 0.1);
		padding: 11px;
	}
	img {
		display: block;
		width: 90px;
		height: 70px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.product_sp {
		width: calc(100% - 180px);
		padding: 3px 10px 0 10px;
		font-family: "Gilroy", sans-serif;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		padding-right: 45px;
	}
	.price_sp {
		margin-top: 5px;
		font-size: 22px;
		line-height: 26px;
		font-weight: 500;
		white-space: nowrap;
		-webkit-transition: 0.4s ease-out;
		-o-transition: 0.4s ease-out;
		transition: 0.4s ease-out;
	}
	.price_sp::after {
		content: "\e929";
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		padding-left: 3px;
		font-size: 15px;
	}
	.price_sp .old {
		width: 100%;
		display: block;
		color: rgba(51, 51, 51, 0.3);
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		-webkit-text-decoration-line: line-through;
		text-decoration-line: line-through;
		text-align: right;
		padding-right: 13px;
	}
	#view-all {
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		border: 2px solid #f90;
		border-radius: 50px;
		line-height: normal;
		text-align: center;
		cursor: pointer;
		-webkit-transition: 0.4s ease-out;
		-o-transition: 0.4s ease-out;
		transition: 0.4s ease-out;
		font-family: "Gilroy", sans-serif;
		font-size: 14px;
		font-weight: bold;
		background-color: #f90;
		color: #fff;
		height: 40px;
		padding: 0 26px;
	}
	a:hover {
		color: inherit;
		.product_sp {
			color: $color-2;
		}
	}
}

.search-form__btn .btn {
	width: auto;
}

.box-search {
	.col_2 {

		.form-filter__checkbox:not(:last-child) label {
			margin: 0;
		}
	}

	.selectric-wrapper {
		width: 100%;

	}
}

.form-filter__checkbox-input:checked + .form-filter__checkbox-label::before,
.form-filter__checkbox-input:disabled + .form-filter__checkbox-label::before {
	content: "\e910";
	border-color: #f90;
	color: #f90;
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
}

.img-responsive {
	max-height: 73px;
}

.header-logo__img {
	width: 100%;
}

.navbar-nav > li > a {
	padding: 0;
}

.box-subscribe__wrap #nl_error, .box-subscribe__wrap #nl_success {
	margin: 5px 21px;
}

.box-subscribe__wrap #nl_error {
	color: red;
}

.mfilter-counter.mfilter-close {
	display: none;
}

.basket-form__row #checkout-form .form-filter__checkbox {
	position: relative;
	input.form-filter__checkbox-input {
		display: block;
		border: none;
		background: antiquewhite;
		position: absolute;
		z-index: 2;
		top: 0;
		border-radius: 0;
		left: 0;
		margin: 0;
		opacity: 0;
		cursor: pointer;
		height: 100%;
	}
	a {
		position: relative;
		z-index: 3;
	}
}

#coupon_info {
	display: block;
	width: 100%;
}

.thumbnail > img, .thumbnail a > img {
	opacity: 0;
}

.fancybox-nav.fancybox-next,
.fancybox-nav.fancybox-prev {
	visibility: visible;
}

.header-logo__img img {
	max-height: 60px;
}

.header-date__link,
.header-price,
.header-catalog js-header-catalog,
.card-info-position__btn,
.card-info-top__compare,
.card-price__summ,
.card-price__credit-link,
.cat_bott {
	cursor: pointer;
}

.mfilter-ajax-loader-container img {
	margin: 0 auto;
}

.overlay_popup {
	position: absolute;
	width: 100%;
	height: 100%;
}

.product-special .box-search .catalog-main-list__top {
	width: 100%;
}

.quick-view .card-price__top {
	border: none;
	max-width: 200px;
	float: right;
}

.product-special .pagination-list-wrap {
	width: 100%;
}

article {
	font-size: 14px;
	line-height: 20px;
}

.about-page__desc, .box-about {
	ul {
		margin: 2px 0 9px;
		li {
			position: relative;
			padding-left: 21px;
			font-size: 14px;
			line-height: 26px;
		}
		li::before {
			content: "";
			position: absolute;
			top: 10px;
			left: 0;
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #3A6479;
		}
	}

}

.basket-form-list-wrap .table > tbody > tr > td {
	border: none;
	padding: 0;
}

.basket-form-list-wrap .table > tbody > tr > td.basket-form-price__value {
	display: block;
}

#buy_form_container {
	width: 100%;
}

/*-----------------------------------------------------------------------------*/

@media (max-width: 1440px) {
	.catalog-main-list .product-list__item {
		width: 33.33%;
	}
}

@media (max-width: 1279px) {

	.nav.navbar-nav.megamenu {
		margin: 0;
	}
	.pagination-list-info {
		display: none;
	}

	.catalog-main-list .product-list-more {
		margin: 0 auto;
		padding-left: 220px;
	}

	.catalog-aside {
		width: 280px;
	}

	.catalog-main {
		width: calc(100% - 300px);
		margin-left: 20px;
	}

	.catalog-production-list__item {
		width: 33.33%;
	}

	.catalog-main-list .product-list__item {
		width: 50%;
	}
}

@media (max-width: 1023px) {
	.box-search {
		.col_2 {
			padding-top: 7px;
		}
	}

	.box-catalog {
		margin-top: 10px;
	}

	.box-catalog__wrap {
		flex-direction: column;
	}

	.catalog-main h1 {
		padding-bottom: 15px;
	}

	.catalog-aside {
		display: none;
	}

	.catalog-main {
		width: 100%;
		margin-left: 0px;
	}

	.catalog-main-list {
		margin: 0 -20px 20px;
	}

	.catalog-main-list .product-list-wrap {
		padding: 10px 0;
	}

	.catalog-main-list .product-list__item {
		width: 33.33%;
	}

	.catalog-main-list__top {
		padding: 10px 0;
	}

	.catalog-main-list {
		padding: 0 20px;
	}

	.catalog-aside.catalog-aside_filter {
		position: relative;
		z-index: 999;
		display: block;
		margin: 5px 0 65px;

		.form-filter-top {
			display: flex;
		}

		.form-filter {
			position: absolute;
			z-index: 20;
			top: 100%;
			display: none;
			left: auto;
			width: 100%;
			background-color: #fff;
			box-shadow: 0px 4px 8px rgba(92, 104, 112, 0.16);
		}
	}

	.catalog-aside {
		width: 320px;
	}
}

@media (max-width: 991px) {

	.sphinxsearch {
		width: 100%;
		right: 0;
		min-width: 400px;
	}
	.success .popup {
		top: 5%;
	}

}

@media (max-width: 767px) {
	.header-logo__img img {
		max-width: 80px;
	}
	.sphinxsearch {
		width: 100%;
		right: 0;
		min-width: 400px;
	}

	.catalog-aside.catalog-aside_filter {
		margin: 10px 0 30px;
	}

	.catalog-main-list__view {
		display: none;
	}

	.catalog-main-list__top {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 20px 0;
	}

	.catalog-main-list__compare {
		margin-top: 10px;
	}

	.catalog-production-list__item,
	.catalog-main-list .product-list__item {
		width: 50%;
	}

	.catalog-main-list .product-list-more {
		padding-left: 0;
		margin-left: 0;
	}
	.success .popup {
		width: 98%;
		left: 1%;
	}

}

/*------------------------------*/

@media (min-width: 767px) {
	.product-list_row {
		.product-list__item {
			width: 100%;
		}

		.product-list__wrap {
			position: relative;
			flex-direction: row;
		}

		.product-list__img-wrap {
			width: 170px;
			padding: 10px;
			margin: -14px;
			margin-right: 0;
		}

		.product-list__img {
			width: 100%;
			height: 112px;
		}

		.product-list__row-wrap {
			flex: 1;
			padding: 0 20px;
		}

		.product-list__name {
			max-width: 525px;
			margin: 28px 0 12px;
		}

		.product-list__bottom {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			margin-top: 0;
			text-align: right;
		}

		.product-list__price-old {
			position: static;
		}

		.product-list__price {
			margin: 8px 0 6px;
		}

		.product-list__info {
			left: 190px;
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
		}

		.product-list__order,
		.product-list__availability {
			position: absolute;
			bottom: 10px;
			left: 85px;
			transform: translateX(-50%);
		}

	}
}

@media (max-width: 500px) {
	.box-search {
		.col_2 {
			padding-top: 0;
		}
		.selectric-wrapper {
			width: 100%;
			.selectric {
				width: 100%;
			}
		}
	}

	.catalog-aside.catalog-aside_filter {
		margin: 0px 0 30px;
	}

	.box-catalog {
		margin-top: 20px;
	}

	.catalog-production-list__item,
	.catalog-main-list .product-list__item {
		width: 100%;
	}

	.catalog-main-list__top .col_1 {
		flex-direction: column;
		align-items: flex-start;
	}

	.catalog-filter {
		margin-right: 0;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-filter__checkbox-label {
		font-weight: normal;
		text-shadow: 0 0;
	}

	.form-filter__accord-title {
		font-weight: normal;
	}

	.catalog-main-list .pagination-list-wrap {
		display: flex;

		.pagination-list {
			display: block;
			width: 100%;
			overflow-x: auto;
			padding-bottom: 25px;
		}

		.product-list-more {
			margin: 0 auto;
		}
	}

	.catalog-main-list__top {
		padding: 24px 0 16px;
	}

	.catalog-aside {
		width: 280px;
	}
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

	.product-list__stock,
	.product-list__novelty,
	.product-list__day {
		display: block;
	}
}
