.box-breadcrumbs {
	margin: 15px 0 -5px;
	font-size: 14px;
}

.breadcrumbs-list {
	display: block;

	&__item {
		position: relative;
		display: inline;
		margin-right: 18px;
		color: rgba(100, 135, 152, .8);
		font-size: 14px;
		line-height: 21px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {

		&::after {
			content: "\e901";
			display: block;
			position: absolute;
			top: 0;
			left: calc(100% + 5px);
			color: $color-4;
			font-size: 9px;
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 19px;
		}
	}
}

@media (max-width: 500px) {
	.box-breadcrumbs {
		display: none;
	}
}