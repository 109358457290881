/*
================================================================================
|                                     POPUPS                                  |
================================================================================
*/

.fancybox-margin {
	.web {
		margin-right: 17px !important;
	}
}

.popup {
	width: 490px;
	max-width: 100%;
	padding: 36px 27px;
	color: $color-1;
	box-shadow: 0px 14px 36px rgba(38, 50, 56, 0.2);
	text-align: center;

	&-box {
		position: absolute;
		left: -9999px;
		top: -9999px;
		opacity: 0;
	}

	&__btn {
		margin-top: 15px;
	}

	h2 {
		font-weight: bold;
		font-size: 22px;
		line-height: 28px;
	}

	p {
		font-size: 12px;
		line-height: 15px;
		color: rgba(51, 51, 51, .6);
	}

	.form-filter__checkbox {
		margin: 19px 0 25px;
		text-align: left;
	}

	.form-filter__checkbox-label {
		padding-left: 22px;
		font-size: 12px;
		line-height: 16px;
		font-weight: normal;
	}

	.btn {
		height: 50px;
		padding: 0 48px;
		font-family: $custom-font-family;
		font-size: 14px;
		font-weight: bold;
	}
}

.popup-form {
	&__row {
		margin: 14px 0;
	}

	&__input,
	&__textarea {
		height: 32px;
		padding: 0;
		border: none;
		border-bottom: 1px solid $color-1;
		border-radius: 0;
		font-family: $base-font-family;
		color: $color-1;
		font-weight: 500;
		font-size: 14px;

		&::placeholder {
			color: rgba(51, 51, 51, .4);
		}
	}

	&__textarea {
		height: 83px;
	}

	&__link {
		display: inline;
		border-bottom: 1px solid;
		font-size: 12px;
		line-height: 15px;
		font-weight: bold;
	}
}

.popup-video {
	width: 854px;
	height: 480px;
	padding: 0;

	iframe {
		width: 100%;
		height: 100%;
	}
}

.popup-thanks {
	width: 293px;
	padding: 20px 16px;

	p {
		padding: 0;
	}
}

.popup-callback {
	width: 351px;
	padding: 24px 16px;

	h2 {
		padding-bottom: 0;
	}
}

.popup-recall {
	width: 370px;
	padding: 20px 27px 26px;

	h2 {
		max-width: 280px;
		margin: 0 auto -24px;
	}

	.btn {
		display: inline-block;
		width: 260px;
		max-width: 100%;
	}
}

.popup-link {
	padding: 26px 27px;
}

.popup-order {
	padding: 20px 27px 39px;

	.popup__btn {
		margin-top: 35px;
	}
}

.popup-reserve {
	padding: 24px 27px 39px;

	p {
		color: $color-1;
		text-align: left;
	}
}

.popup-basket,
.popup-compare {
	width: 640px;
	padding: 24px 27px 32px;
	text-align: left;

	h2 {
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 26px;
		font-weight: normal;
	}

	&__close {
		margin-right: 33px;
		color: $color-3;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		cursor:pointer;
	}

	&__btns {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.popup-search {
	text-align: left;
	width: 695px;
	padding: 20px 0;

	&__btn {
		margin-top: 20px;
		text-align: center;

		.btn {
			height: 40px;
			padding: 0 26px;
		}
	}
}

.popup-search-list {
	&__item {
		display: flex;
		align-items: flex-start;
		border-bottom: 1px solid rgba(51, 51, 51, .1);
		padding: 11px 36px 11px 10px;
	}

	&__img-wrap {
		width: 90px;
	}

	&__img {
		display: block;
		width: 100%;
		height: 70px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	&__name {
		width: calc(100% - 180px);
		padding: 3px 10px 0 10px;
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}

	&__price-wrap {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 110px;
		padding-bottom: 12px;
		text-align: center;
	}

	&__price-old {
		color: rgba(51, 51, 51, .3);
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-decoration-line: line-through;
	}

	&__price {
		margin-top: 5px;
		font-size: 22px;
		line-height: 26px;
		font-weight: 500;
		white-space: nowrap;

		i {
			padding-left: 3px;
			font-size: 15px;
		}
	}
}

.popup-product {
	width: 710px;
	padding: 20px;
	text-align: left;

	$this: &;
	
	h2 {
		padding-top: 0;
		font-size: 20px;
		line-height: 26px;
		font-weight: 400;
	}

	&__wrap {
		display: flex;
		border-bottom: 1px solid #DAE4EA;
		padding-bottom: 15px;
	}

	.card-info-top__compare {
		margin-left: 28px;
	}

	.card-info-list-top {
		margin-top: 10px;
	}

	.card-info-list-top__item {
		line-height: 27px;
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 17px;

		.btn {
			font-size: 30px;
			padding: 0 26px;
		}

		.product-list__quantity {
			align-self: center;
			margin-right: 30px;
		}
	}

	&__basket {
		position: relative;
		
		&:hover #{$this}__basket-info{
			z-index: 20;
			visibility: visible;
			opacity: 1;
			transform: translate3d(0, 3px, 0);
		}
	}

	&__basket-info {
		position: absolute;
		visibility: hidden;
		z-index: -1;
		top: -40px;
		right: 0;
		width: auto;
		border-radius: 4px;
		border: 1px solid rgba(100, 135, 152, 0.1);
		box-shadow: 0px 8px 16px rgba(38, 50, 56, 0.16);
		padding: 4px 9px;
		opacity: 0;
		color: rgba(51, 51, 51, .86);
		background: #FFFFFF;
		font-size: 15px;
		line-height: 19px;
		letter-spacing: -0.1px;
		white-space: nowrap;
		transform: translate3d(0, 0px, 0);
		transition: $transition;

		&::after,
		&::before {
			position: absolute;
			content: "";
			top: 100%;
			right: 40px;
			height: 0;
			width: 0;
			border: solid transparent;
			pointer-events: none;
		}

		&::after {
			border-color: rgba(255, 255, 255, 0);
			border-top-color: #ffffff;
			border-width: 6px;
			margin-right: -6px;
		}

		&::before {
			border-color: rgba(0, 245, 0, 0);
			border-top-color: rgba(100, 135, 152, 0.1);
			border-width: 6px;
			margin-right: -6px;
		}
	}
}

.popup-slider-box {
	width: 340px;
}

.popup-product-info {
	width: calc(100% - 340px);
	padding: 7px 0 0 26px;
}


.popup-slider {
	&__item {
		width: 100%;
		height: 234px;
		border: 1px solid #DAE4EA;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 90%;
	}

}

.popup-slider-nav {
	margin-top: 10px;

	.slick-list {
		margin: 0 -5px;
	}

	&__item {
		position: relative;
		width: 60px;
		height: 40px;
		border: 1px solid #DAE4EA;
		margin: 0 5px;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 3px;
			background-color: $color-2;
			opacity: 0;
			transition: $transition;
		}

		&.slick-current::before {
			opacity: 1;
		}

	}

	&__img {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 90%;
	}
}


/*-------------------------------------------------------------------*/

@media (max-width: 767px) {	
	.popup-slider-box {
    	width: 50%;
	} 
	
	.popup-product-info {
		width: 50%;
		padding-left: 15px;
	}
	
	.popup-product h2 {
		font-size: 17px;
		line-height: normal; 
	}
	
	.popup-product .card-info-top__compare i {
		margin-right: 4px;
	}
	
	.popup-product .card-info-top__compare {
		margin-left: 4px;
		width: auto;
		margin-top: 0;
	}
}
	
@media (max-width: 500px) {
	.popup {
		padding: 20px 15px 25px;
	}

	.popup h2 {
		font-size: 18px;
		line-height: normal;
	}

	.popup-search {
		padding: 20px 0;
	}

	.popup-search-list__item {
		padding: 10px;
		flex-wrap: wrap;
	}

	.popup-search-list__img-wrap {
		width: 50px;
	}

	.popup-search-list__img {
		height: 50px;
	}

	.popup-search-list__name {
		width: calc(100% - 50px);
		font-size: 14px;
		line-height: normal;
	}

	.popup-search-list__price-wrap {
		width: 100%;
		margin-top: 15px;
	}

	.popup-search-list__price {
		font-size: 18px;
		line-height: normal;
	}

	.popup-basket h2,
	.popup-compare h2 {
		font-size: 18px;
		line-height: normal;
	}

	.popup-basket__btns,
	.popup-compare__btns {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.popup-basket__close,
	.popup-compare__close {
		order: 1;
		margin-right: 0;
		margin-top: 15px;
	}
	
	.popup-product__wrap {
		flex-direction: column;
	}
	
	.popup-slider-box {
		width: 100%;
	}
	
	.popup-product-info {
		width: 100%;
		padding: 15px 0 0;
	}
	
	.popup-product__bottom .btn {
    	font-size: 25px;
	}
	
	.popup-product .card-info-top__compare {
		margin-left: 15px;
	}
}