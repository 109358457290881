/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/

.box-header {
	width: 100%;
	min-width: $min-width;
	height: auto;
	background-color: #fff;
	text-align: left;
	transition: $transition;
}

.header-call-top {
	display: none;
}

.header-top {
	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 0 5px;
	}

	nav {
		margin-left: -10px;
		margin-right: -8px;
	}

	.header-price {
		display: none;
		background-color: transparent;
	}

	.header-price__icon {
		color: $color-2;

		&:hover {
			color: $color-2;
		}
	}
}

.header-logo {
	&__sure {
		margin-right: 14px;
		font-size: 9.5px;
	}

	&__wrap {
		display: flex;
		align-items: center;
	}

	&__img {
		width: 60px;
	}

	&__desc {
		margin-top: 4px;
		color: $color-1;
		font-size: 9px;
		line-height: 12px;
	}
}

.header-nav {
	ul {
		display: flex;
	}

	li {
		position: relative;
		padding: 0 17.4px;

		&:not(:last-child)::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			width: 1px;
			height: 20px;
			background: rgba(0, 0, 0, .2);
		}
	}

	a {
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
	}
}

.header-search-icon {
	display: none;
	font-size: 19px;
}

.header-date {
	font-size: 12px;
	line-height: 18px;

	&__title {
		color: rgba(51, 51, 51, .6);
		font-size: 12px;
		line-height: 18px;
	}

	&__value {
		margin: 4px 0;
		font-weight: 500;
		letter-spacing: .15px;

		br {
			display: none;
		}
	}

	&__link-wrap {
		position: relative;
		&:hover {
			.header-date-info {
				z-index: 20;
				visibility: visible;
				opacity: 1;
				transform: translate3d(0, 5px, 0);
			}
		}
	}

	&__link {
		color: $color-2;
		border-bottom: 1px dashed rgba(255, 153, 0, .4);

		&:hover {
			border-color: transparent;
		}
	}
}

.header-date-info {
	position: absolute;
	visibility: hidden;
	z-index: -1;
	top: 100%;
	left: 0;
	width: max-content;
	border-radius: 4px;
	border: 1px solid rgba(100, 135, 152, 0.1);
	box-shadow: 0px 14px 36px rgba(38, 50, 56, 0.2);
	padding: 14px 16px;
	opacity: 0;
	background: #FFFFFF;
	transform: translate3d(0, 8px, 0);
	transition: $transition;

	&::after,
	&::before {
		position: absolute;
		content: "";
		bottom: 100%;
		left: 15px;
		height: 0;
		width: 0;
		border: solid transparent;
		pointer-events: none;
	}

	&::after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #ffffff;
		border-width: 6px;
		margin-left: -6px;
	}

	&::before {
		border-color: rgba(0, 245, 0, 0);
		border-bottom-color: rgba(100, 135, 152, 0.1);
		border-width: 7px;
		margin-left: -7px
	}

	&__item {
		margin-bottom: 6px;
		font-size: 13px;
		line-height: normal;

		&:last-child {
			margin-bottom: 0;
		}
	}

	span {
		font-weight: 500;
		font-size: 13px;
		line-height: normal;
		color: rgba(51, 51, 51, .8);
	}
}

.header-call {
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 8px;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: -10px;
		display: block;
		width: 1px;
		height: 20px;
		background: rgba(0, 0, 0, .2);
		transform: translateY(-50%);
	}

	&::after {
		right: -20px;
		left: auto;
	}

	&__icon {
		color: #FF9900;
		font-size: 18px;
	}

	&__wrap {
		margin-left: 12px;
	}

	&__link-wrap {
		margin: 4px 0;
	}

	&__link {
		font-size: 15px;
		line-height: 20px;
		font-weight: bold;
		border-bottom: 1px dashed rgba(51, 51, 51, .4);
	}
}

.header-compare {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 10px;

	&:hover .header-compare__title {
		color: $color-2;
	}

	&__icon {
		position: relative;
		margin-top: 21px;
		margin-right: 11px;
		color: #FF9900;
		font-size: 26px;
	}

	&__count {
		position: absolute;
		top: -21px;
		right: -10px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: $color-1;
		color: #FFFFFF;
		font-size: 13px;
		line-height: 18px;
	}

	&__title {
		color: $color-1;
		font-size: 12px;
		line-height: 18px;
		transition: $transition;
	}
}

.header-btn {
	display: block;
	width: 20px;
	cursor: pointer;

	&:hover span {
		background-color: $color-2;
	}

	&-wrap {
		display: none;
		align-items: center;
	}

	span {
		position: relative;
		display: block;
		height: 2px;
		width: 20px;
		border-radius: 20px;
		background: $color-1;
		transition: $transition;

		&:nth-child(2) {
			right: 0;
			margin: 4px 0;
		}
	}
}

.header-bottom {
	background-color: $color-4;

	&__wrap {
		position: relative;
		display: flex;
		justify-content: space-between;
	}
}

@media (min-width: 1280px) {
	.header-catalog-box:hover {
		.header-catalog {
			.header-catalog__icon span:nth-child(2) {
				width: 23px;
			}

			.header-catalog__icon span:nth-child(3) {
				width: 26px;
			}
		}

		.header-catalog-wrap {
			z-index: 998;
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}

.header-catalog-box {
	display: flex;
}

.header-catalog {
	display: flex;
	align-items: center;
	width: 320px;
	padding: 0 20px;
	background-color: $color-2;

	&:hover {
		.header-catalog__icon span:nth-child(2) {
			width: 23px;
		}

		.header-catalog__icon span:nth-child(3) {
			width: 26px;
		}
	}

	&__icon {
		width: 40px;

		span {
			display: block;
			width: 20px;
			height: 2px;
			margin: 4px 0;
			background-color: #fff;
			transition: $transition;
		}
	}

	&__title {
		color: #FFFFFF;
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
	}
}

.header-form {
	width: calc(100% - 550px);
	padding: 5px 40px 5px 20px;

	&__input-wrap {
		position: relative;
		display: flex;
		width: 100%;
		height: 50px;
		border-radius: 50px;
	}

	&__icon {
		position: absolute;
		top: 50%;
		left: 17px;
		color: rgba(25, 25, 25, .3);
		font-size: 20px;
		transform: translateY(-50%);
	}

	&__input {
		overflow: hidden;
		width: calc(100% - 90px);
		height: 100%;
		border: none;
		border-radius: 50px 0 0 50px;
		padding: 0 10px 0 53px;
		text-overflow: ellipsis;
		white-space: nowrap;
		-webkit-appearance: none;
	}

	&__submit {
		width: 90px;
		height: 100%;
		border: none;
		border-radius: 0 50px 50px 0;
		background: #3A6479;
		color: #FFFFFF;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		-webkit-appearance: none;
		transition: $transition;

		&:hover {
			background-color: $color-2;
		}
	}
}

.header-price {
	display: flex;
	align-items: center;
	width: 230px;
	padding: 0 17px;
	background-color: $color-2;
	color: #fff;

	&:hover {
		color: #fff;
	}

	&__icon {
		margin-top: 6px;
		color: #fff;
		position: relative;
		font-size: 30px;

		&:hover {
			color: #fff;
		}
	}

	&__count {
		position: absolute;
		top: -7px;
		right: -15px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: $color-1;
		color: #FFFFFF;
		font-size: 13px;
		line-height: 18px;
	}

	&__title {
		margin: 0 9px 0 19px;
		font-size: 14px;
		line-height: 16px;
	}

	&__value {
		display: flex;
		align-items: center;
		font-size: 21px;
		line-height: 26px;
		font-weight: 500;
		white-space: nowrap;
		i {
			margin-left: 5px;
			font-size: 15px;
		}
	}
}

.header-catalog-wrap {
	position: absolute;
	z-index: -1;
	top: 100%;
	left: 0;
	width: 100%;
	padding-top: 1px;
	background: #FFFFFF;
	opacity: 0;
	box-shadow: 0px 4px 8px rgba(92, 104, 112, 0.16);
	transform: translate3d(0, 10px, 0);
	transition: $transition;
}

.catalog-nav-list {
	display: flex;
	margin: 0 -.5px;

	&__item {
		width: 8.33%;
		padding: 0 .5px;
	}

	&__wrap {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		min-height: 107px;
		padding: 10px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(58, 100, 121, 0.85);
			transition: $transition;
		}

		&.active {
			&::after {
				background-color: $color-2;
			}

			.catalog-nav-list__title {
				font-weight: bold;
			}
		}

		&:hover {
			&::after {
				background-color: $color-2;
			}

			.catalog-nav-list__title {
				font-weight: bold;
			}
		}
	}

	&__title {
		z-index: 2;
		color: #FFFFFF;
		font-size: 13px;
		line-height: 18px;
		text-align: center;
		transition: $transition;
		a, a:hover {
			color: #FFFFFF;
			text-decoration: none;
		}
	}
}

.catalog-cont {
	&__item {
		display: flex;
		transform: translate3d(0, 0, 0);
		transition: 1s;
	}

	&__info {
		width: calc(100% - 576px);
		padding: 39px 40px 39px 116px;
	}

	&__title {
		color: #000000;
		font-size: 34px;
		line-height: 40px;
		font-weight: 500;
	}

	&__img {
		width: 576px;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.hide {
		transform: translate3d(0, 5px, 0);
	}

	.header-nav {
		display: none;
	}

	@media (min-width: 1280px) {
		.mCSB_inside > .mCSB_container {
			margin-right: 0;
		}

		#mCSB_1_scrollbar_vertical {
			display: none !important;
		}
	}
}

.hide {
	position: absolute;
	z-index: -1;
	top: -9999px;
	left: -9999px;
	overflow: hidden;
	opacity: 0;
}

.catalog-cont-list {
	max-width: 290px;

	&-wrap {
		margin-top: 24px;
	}

	&__item {
		padding: 10px 0px 10px 12px;
		border-top: 1px solid rgba(218, 228, 234, .5);

		&:last-child {
			border-bottom: 1px solid rgba(218, 228, 234, .5);
		}
	}

	&__wrap {
		display: flex;
		align-items: center;

		&:hover {
			.catalog-cont-list__icon {
				color: $color-2;
			}

			.catalog-cont-list__title {
				color: $color-3;
			}
		}
	}

	&__icon {
		margin-right: 12px;
		font-size: 10px;
		transition: $transition;
	}

	&__title {
		color: #000000;
		font-size: 13px;
		line-height: 16px;
		transition: $transition;
	}
}

.header-catalog-close {
	display: none;
	position: absolute;
	top: 50%;
	right: -28px;
	color: #fff;
	font-size: 12px;
	transform: translateY(-50%);
}

/*-----------------------------------------------------------------------------*/

@media screen and (max-width: 1440px) {
	.header-top nav {
		margin: 0;
	}

	.header-date__value {
		br {
			display: block;
		}

		span {
			display: none;
		}
	}
}

@media screen and (max-width: 1279px) {
	.header-catalog {
		width: 206px;
	}

	.header-form {
		width: calc(100% - 436px);
		padding: 5px 20px;
	}

	.header-catalog__icon {
		width: 35px;
	}

	.header-nav {
		display: none;

		ul {
			flex-direction: column;
		}

		li {
			padding: 22.5px 20px;
			border-top: 1px solid white;

			&:last-child {
				border-bottom: 1px solid white;
			}
		}

		a {
			color: #000;
			font-size: 13px;
			line-height: normal;
			font-weight: normal;
		}
	}

	.header-nav li:not(:last-child)::before {
		display: none;
	}

	.header-btn-wrap {
		display: flex;
	}

	.catalog-nav-list,
	.catalog-cont__img,
	.catalog-cont-list-wrap {
		display: none;
	}

	.header-catalog-box.active .header-catalog-wrap {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	.header-catalog-wrap {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		width: calc(100% - 44px);
		height: 100%;
		background-color: #E0E7EA;
		transform: translate3d(-120%, 0, 0);

		.header-nav {
			display: block;
		}
	}

	.catalog-cont {
		height: 100%;
	}

	.catalog-cont .hide {
		position: relative;
		top: 0;
		left: 0;
		opacity: 1;
		overflow: visible;
		z-index: auto;
		transform: none;
		display: block !important;
	}

	.catalog-cont__title {
		position: relative;
		padding: 22.5px 40px 22.5px 20px;
		font-size: 13px;
		line-height: normal;
		font-weight: normal;

		&.active::after {
			transform: translateY(-50%) rotate(90deg) scaleX(-1);
		}

		&::after {
			content: '\e901';
			position: absolute;
			top: 50%;
			right: 25px;
			display: block;
			color: $color-3;
			font-family: 'icomoon' !important;
			font-size: 13px;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			transform: translateY(-50%) rotate(90deg);
			transition: $transition;
		}
	}

	.catalog-cont__info {
		width: 100%;
		padding: 0;
	}

	.catalog-cont__item {
		border-top: 1px solid white;
	}

	.catalog-cont__item:last-child {
		border-bottom: 1px solid white;
	}

	.catalog-cont-list {
		max-width: 100%;
	}

	.catalog-cont-list-wrap {
		margin-top: 0;
		background-color: #fff;
	}

	.catalog-cont-list__icon {
		display: none;
	}

	.catalog-cont-list__item {
		padding: 10px 20px;
		font-size: 13px;
	}

	.catalog-cont-list__item:last-child {
		border-bottom: none;
	}

	.header-catalog-close {
		display: block;
	}
}

@media screen and (max-width: 1023px) {
	.header-top__wrap {
		padding: 7px 0;
	}

	.header-date {
		margin-top: -2px;
		margin-left: 39px;
	}

	.header-date__value {
		margin: 0;
		letter-spacing: 0;
	}

	.header-call,
	.header-compare {
		padding-left: 0;
	}

	.header-call::before,
	.header-call::after {
		left: -20px;
	}

	.header-call::after {
		left: auto;
		right: -14px;
	}
}

@media screen and (max-width: 767px) {
	.header-search-icon {
		display: block;
		margin-left: 17px;
	}

	.header-form {
		position: absolute;
		visibility: hidden;
		top: -64px;
		left: -5px;
		width: calc(100% + 10px);
		padding: 5px 0;
		opacity: 0;
		transition: $transition;

		&.active {
			visibility: visible;
			opacity: 1;
		}
	}

	.header-catalog,
	.header-price,
	.header-date,
	.header-compare__title,
	.header-logo__desc,
	.header-call {
		display: none;
	}

	.header-top .header-price {
		display: flex;
		width: auto;
		padding: 0;
	}

	.header-call-top {
		display: block;
		padding: 4px 0;
		background: #648798;

		&__wrap {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	.header-call__icon {
		color: #A2B7C1;
	}

	.header-call__link {
		border-bottom: none;
		color: #fff;
		font-size: 12px;
		line-height: normal;
	}

	.header-call__link-wrap {
		margin: 3px 0px 3px 10px;
	}

	.header-form__input-wrap {
		box-shadow: 2px 2px 12px #C4C4C4;
	}

	.catalog-cont {
		overflow-y: auto;
	}
}

@media screen and (max-width: 500px) {
	.header-logo__sure {
		margin-right: 10px;
		font-size: 5.5px;
	}

	.header-logo__img {
		width: 36px;
	}

	.header-top__wrap {
		padding: 9px 0;
	}

	.catalog-cont-list__wrap:hover .catalog-cont-list__title {
		color: #000;
	}
}