$cdn: 'https://cdn.testoshop.ru/image';

@import "layout/oldcss";
@import "base/reset";
@import "base/fonts";
@import "components/slick";
@import "components/fancybox"; 
@import "components/star-rating";
@import "components/mCustomScrollbar";
@import "components/jquery-ui";
@import "components/mfResponsive";
@import "layout/header";
@import "layout/footer";
@import "layout/content";
@import "layout/breadcrumbs";
@import "layout/card";
@import "layout/catalog";
@import "layout/basket";
@import "layout/inner";
@import "layout/popups";
@import "layout/range-slider";



