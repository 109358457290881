
.lead {
	margin-bottom: 22px;
	font-size: 51px;
	font-weight: 300;
	line-height: 1.4;
}

@media (min-width: 768px) {
	.lead {
		font-size: 21px;
	}
}

small,
.small {
	font-size: 85%;
}

mark,
.mark {
	background-color: #fcf8e3;
	padding: .2em;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-justify {
	text-align: justify;
}

.text-nowrap {
	white-space: nowrap;
}

.text-lowercase {
	text-transform: lowercase;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-muted {
	color: #fff;
}

.text-primary {
	color: #1e487b;
}

a.text-primary:hover {
	color: #143052;
}

.text-success {
	color: #3c763d;
}

a.text-success:hover {
	color: #2b542c;
}

.text-info {
	color: #31708f;
}

a.text-info:hover {
	color: #245269;
}

.text-warning {
	color: #8a6d3b;
}

a.text-warning:hover {
	color: #66512c;
}

.text-danger {
	color: #a94442;
}

a.text-danger:hover {
	color: #843534;
}

.bg-primary {
	color: #fff;
}

.bg-primary {
	background-color: #1e487b;
}

a.bg-primary:hover {
	background-color: #143052;
}

.bg-success {
	background-color: #dff0d8;
}

a.bg-success:hover {
	background-color: #c1e2b3;
}

.bg-info {
	background-color: #d9edf7;
}

a.bg-info:hover {
	background-color: #afd9ee;
}

.bg-warning {
	background-color: #fcf8e3;
}

a.bg-warning:hover {
	background-color: #f7ecb5;
}

.bg-danger {
	background-color: #f2dede;
}

a.bg-danger:hover {
	background-color: #e4b9b9;
}

.page-header {
	padding-bottom: 10px;
	margin: 44px 0 22px;
	border-bottom: 1px solid #999;
}

ul,
ol {
	margin-top: 0;
	margin-bottom: 11px;
}

ul ul,
ul ol,
ol ul,
ol ol {
	margin-bottom: 0;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
	margin-left: -5px;
}

.list-inline > li {
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px;
}

dl {
	margin-top: 0;
	margin-bottom: 22px;
}

dt,
dd {
	line-height: 1.625;
}

dt {
	font-weight: bold;
}

dd {
	margin-left: 0;
}

.dl-horizontal dd:before, .dl-horizontal dd:after {
	content: " ";
	display: table;
}

.dl-horizontal dd:after {
	clear: both;
}

@media (min-width: 768px) {
	.dl-horizontal dt {
		float: left;
		width: 160px;
		clear: left;
		text-align: right;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.dl-horizontal dd {
		margin-left: 180px;
	}
}

abbr[title],
abbr[data-original-title] {
	cursor: help;
	border-bottom: 1px dotted #bbbbbb;
}

.initialism {
	font-size: 90%;
	text-transform: uppercase;
}

blockquote {
	padding: 13px 26px 13px 70px;
	margin: 0 0 22px;
	font-size: 17.5px;
	border-left: 3px solid #999;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
	margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
	font-size: 12px;
	line-height: 1.625;
	color: #444;
	font-weight: 800;
	text-transform: uppercase;
	font-style: normal;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
	content: '\2014 \00A0';
}

.blockquote-reverse,
blockquote.pull-right {
	padding-right: 15px;
	padding-left: 0;
	border-right: 3px solid #999;
	border-left: 0;
	text-align: right;
}

.blockquote-reverse footer:before,
.blockquote-reverse small:before,
.blockquote-reverse .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before,
blockquote.pull-right .small:before {
	content: '';
}

.blockquote-reverse footer:after,
.blockquote-reverse small:after,
.blockquote-reverse .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after,
blockquote.pull-right .small:after {
	content: '\00A0 \2014';
}

address {
	margin-bottom: 22px;
	font-style: normal;
	line-height: 1.625;
}

.container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}

.container:before, .container:after {
	content: " ";
	display: table;
}

.container:after {
	clear: both;
}

@media (min-width: 768px) {
	.container {
		width: 780px;
	}
}

@media (min-width: 992px) {
	.container {
		width: 1000px;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1200px;
	}
}

.container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}

.container-fluid:before, .container-fluid:after {
	content: " ";
	display: table;
}

.container-fluid:after {
	clear: both;
}

.row {
	margin-left: -15px;
	margin-right: -15px;
}

.row:before, .row:after {
	content: " ";
	display: table;
}

.row:after {
	clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
	float: left;
}

.col-xs-1 {
	width: 8.3333333333%;
}

.col-xs-2 {
	width: 16.6666666667%;
}

.col-xs-3 {
	width: 25%;
}

.col-xs-4 {
	width: 33.3333333333%;
}

.col-xs-5 {
	width: 41.6666666667%;
}

.col-xs-6 {
	width: 50%;
}

.col-xs-7 {
	width: 58.3333333333%;
}

.col-xs-8 {
	width: 66.6666666667%;
}

.col-xs-9 {
	width: 75%;
}

.col-xs-10 {
	width: 83.3333333333%;
}

.col-xs-11 {
	width: 91.6666666667%;
}

.col-xs-12 {
	width: 100%;
}

.col-xs-pull-0 {
	right: auto;
}

.col-xs-pull-1 {
	right: 8.3333333333%;
}

.col-xs-pull-2 {
	right: 16.6666666667%;
}

.col-xs-pull-3 {
	right: 25%;
}

.col-xs-pull-4 {
	right: 33.3333333333%;
}

.col-xs-pull-5 {
	right: 41.6666666667%;
}

.col-xs-pull-6 {
	right: 50%;
}

.col-xs-pull-7 {
	right: 58.3333333333%;
}

.col-xs-pull-8 {
	right: 66.6666666667%;
}

.col-xs-pull-9 {
	right: 75%;
}

.col-xs-pull-10 {
	right: 83.3333333333%;
}

.col-xs-pull-11 {
	right: 91.6666666667%;
}

.col-xs-pull-12 {
	right: 100%;
}

.col-xs-push-0 {
	left: auto;
}

.col-xs-push-1 {
	left: 8.3333333333%;
}

.col-xs-push-2 {
	left: 16.6666666667%;
}

.col-xs-push-3 {
	left: 25%;
}

.col-xs-push-4 {
	left: 33.3333333333%;
}

.col-xs-push-5 {
	left: 41.6666666667%;
}

.col-xs-push-6 {
	left: 50%;
}

.col-xs-push-7 {
	left: 58.3333333333%;
}

.col-xs-push-8 {
	left: 66.6666666667%;
}

.col-xs-push-9 {
	left: 75%;
}

.col-xs-push-10 {
	left: 83.3333333333%;
}

.col-xs-push-11 {
	left: 91.6666666667%;
}

.col-xs-push-12 {
	left: 100%;
}

.col-xs-offset-0 {
	margin-left: 0%;
}

.col-xs-offset-1 {
	margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
	margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
	margin-left: 25%;
}

.col-xs-offset-4 {
	margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
	margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
	margin-left: 50%;
}

.col-xs-offset-7 {
	margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
	margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
	margin-left: 75%;
}

.col-xs-offset-10 {
	margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
	margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
	margin-left: 100%;
}

@media (min-width: 768px) {
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
		float: left;
	}

	.col-sm-1 {
		width: 8.3333333333%;
	}

	.col-sm-2 {
		width: 16.6666666667%;
	}

	.col-sm-3 {
		width: 25%;
	}

	.col-sm-4 {
		width: 33.3333333333%;
	}

	.col-sm-5 {
		width: 41.6666666667%;
	}

	.col-sm-6 {
		width: 50%;
	}

	.col-sm-7 {
		width: 58.3333333333%;
	}

	.col-sm-8 {
		width: 66.6666666667%;
	}

	.col-sm-9 {
		width: 75%;
	}

	.col-sm-10 {
		width: 83.3333333333%;
	}

	.col-sm-11 {
		width: 91.6666666667%;
	}

	.col-sm-12 {
		width: 100%;
	}

	.col-sm-pull-0 {
		right: auto;
	}

	.col-sm-pull-1 {
		right: 8.3333333333%;
	}

	.col-sm-pull-2 {
		right: 16.6666666667%;
	}

	.col-sm-pull-3 {
		right: 25%;
	}

	.col-sm-pull-4 {
		right: 33.3333333333%;
	}

	.col-sm-pull-5 {
		right: 41.6666666667%;
	}

	.col-sm-pull-6 {
		right: 50%;
	}

	.col-sm-pull-7 {
		right: 58.3333333333%;
	}

	.col-sm-pull-8 {
		right: 66.6666666667%;
	}

	.col-sm-pull-9 {
		right: 75%;
	}

	.col-sm-pull-10 {
		right: 83.3333333333%;
	}

	.col-sm-pull-11 {
		right: 91.6666666667%;
	}

	.col-sm-pull-12 {
		right: 100%;
	}

	.col-sm-push-0 {
		left: auto;
	}

	.col-sm-push-1 {
		left: 8.3333333333%;
	}

	.col-sm-push-2 {
		left: 16.6666666667%;
	}

	.col-sm-push-3 {
		left: 25%;
	}

	.col-sm-push-4 {
		left: 33.3333333333%;
	}

	.col-sm-push-5 {
		left: 41.6666666667%;
	}

	.col-sm-push-6 {
		left: 50%;
	}

	.col-sm-push-7 {
		left: 58.3333333333%;
	}

	.col-sm-push-8 {
		left: 66.6666666667%;
	}

	.col-sm-push-9 {
		left: 75%;
	}

	.col-sm-push-10 {
		left: 83.3333333333%;
	}

	.col-sm-push-11 {
		left: 91.6666666667%;
	}

	.col-sm-push-12 {
		left: 100%;
	}

	.col-sm-offset-0 {
		margin-left: 0%;
	}

	.col-sm-offset-1 {
		margin-left: 8.3333333333%;
	}

	.col-sm-offset-2 {
		margin-left: 16.6666666667%;
	}

	.col-sm-offset-3 {
		margin-left: 25%;
	}

	.col-sm-offset-4 {
		margin-left: 33.3333333333%;
	}

	.col-sm-offset-5 {
		margin-left: 41.6666666667%;
	}

	.col-sm-offset-6 {
		margin-left: 50%;
	}

	.col-sm-offset-7 {
		margin-left: 58.3333333333%;
	}

	.col-sm-offset-8 {
		margin-left: 66.6666666667%;
	}

	.col-sm-offset-9 {
		margin-left: 75%;
	}

	.col-sm-offset-10 {
		margin-left: 83.3333333333%;
	}

	.col-sm-offset-11 {
		margin-left: 91.6666666667%;
	}

	.col-sm-offset-12 {
		margin-left: 100%;
	}
}

@media (min-width: 992px) {
	.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
		float: left;
	}

	.col-md-1 {
		width: 8.3333333333%;
	}

	.col-md-2 {
		width: 16.6666666667%;
	}

	.col-md-3 {
		width: 25%;
	}

	.col-md-4 {
		width: 33.3333333333%;
	}

	.col-md-5 {
		width: 41.6666666667%;
	}

	.col-md-6 {
		width: 50%;
	}

	.col-md-7 {
		width: 58.3333333333%;
	}

	.col-md-8 {
		width: 66.6666666667%;
	}

	.col-md-9 {
		width: 75%;
	}

	.col-md-10 {
		width: 83.3333333333%;
	}

	.col-md-11 {
		width: 91.6666666667%;
	}

	.col-md-12 {
		width: 100%;
	}

	.col-md-pull-0 {
		right: auto;
	}

	.col-md-pull-1 {
		right: 8.3333333333%;
	}

	.col-md-pull-2 {
		right: 16.6666666667%;
	}

	.col-md-pull-3 {
		right: 25%;
	}

	.col-md-pull-4 {
		right: 33.3333333333%;
	}

	.col-md-pull-5 {
		right: 41.6666666667%;
	}

	.col-md-pull-6 {
		right: 50%;
	}

	.col-md-pull-7 {
		right: 58.3333333333%;
	}

	.col-md-pull-8 {
		right: 66.6666666667%;
	}

	.col-md-pull-9 {
		right: 75%;
	}

	.col-md-pull-10 {
		right: 83.3333333333%;
	}

	.col-md-pull-11 {
		right: 91.6666666667%;
	}

	.col-md-pull-12 {
		right: 100%;
	}

	.col-md-push-0 {
		left: auto;
	}

	.col-md-push-1 {
		left: 8.3333333333%;
	}

	.col-md-push-2 {
		left: 16.6666666667%;
	}

	.col-md-push-3 {
		left: 25%;
	}

	.col-md-push-4 {
		left: 33.3333333333%;
	}

	.col-md-push-5 {
		left: 41.6666666667%;
	}

	.col-md-push-6 {
		left: 50%;
	}

	.col-md-push-7 {
		left: 58.3333333333%;
	}

	.col-md-push-8 {
		left: 66.6666666667%;
	}

	.col-md-push-9 {
		left: 75%;
	}

	.col-md-push-10 {
		left: 83.3333333333%;
	}

	.col-md-push-11 {
		left: 91.6666666667%;
	}

	.col-md-push-12 {
		left: 100%;
	}

	.col-md-offset-0 {
		margin-left: 0%;
	}

	.col-md-offset-1 {
		margin-left: 8.3333333333%;
	}

	.col-md-offset-2 {
		margin-left: 16.6666666667%;
	}

	.col-md-offset-3 {
		margin-left: 25%;
	}

	.col-md-offset-4 {
		margin-left: 33.3333333333%;
	}

	.col-md-offset-5 {
		margin-left: 41.6666666667%;
	}

	.col-md-offset-6 {
		margin-left: 50%;
	}

	.col-md-offset-7 {
		margin-left: 58.3333333333%;
	}

	.col-md-offset-8 {
		margin-left: 66.6666666667%;
	}

	.col-md-offset-9 {
		margin-left: 75%;
	}

	.col-md-offset-10 {
		margin-left: 83.3333333333%;
	}

	.col-md-offset-11 {
		margin-left: 91.6666666667%;
	}

	.col-md-offset-12 {
		margin-left: 100%;
	}
}

@media (min-width: 1200px) {
	.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
		float: left;
	}

	.col-lg-1 {
		width: 8.3333333333%;
	}

	.col-lg-2 {
		width: 16.6666666667%;
	}

	.col-lg-3 {
		width: 25%;
	}

	.col-lg-4 {
		width: 33.3333333333%;
	}

	.col-lg-5 {
		width: 41.6666666667%;
	}

	.col-lg-6 {
		width: 50%;
	}

	.col-lg-7 {
		width: 58.3333333333%;
	}

	.col-lg-8 {
		width: 66.6666666667%;
	}

	.col-lg-9 {
		width: 75%;
	}

	.col-lg-10 {
		width: 83.3333333333%;
	}

	.col-lg-11 {
		width: 91.6666666667%;
	}

	.col-lg-12 {
		width: 100%;
	}

	.col-lg-pull-0 {
		right: auto;
	}

	.col-lg-pull-1 {
		right: 8.3333333333%;
	}

	.col-lg-pull-2 {
		right: 16.6666666667%;
	}

	.col-lg-pull-3 {
		right: 25%;
	}

	.col-lg-pull-4 {
		right: 33.3333333333%;
	}

	.col-lg-pull-5 {
		right: 41.6666666667%;
	}

	.col-lg-pull-6 {
		right: 50%;
	}

	.col-lg-pull-7 {
		right: 58.3333333333%;
	}

	.col-lg-pull-8 {
		right: 66.6666666667%;
	}

	.col-lg-pull-9 {
		right: 75%;
	}

	.col-lg-pull-10 {
		right: 83.3333333333%;
	}

	.col-lg-pull-11 {
		right: 91.6666666667%;
	}

	.col-lg-pull-12 {
		right: 100%;
	}

	.col-lg-push-0 {
		left: auto;
	}

	.col-lg-push-1 {
		left: 8.3333333333%;
	}

	.col-lg-push-2 {
		left: 16.6666666667%;
	}

	.col-lg-push-3 {
		left: 25%;
	}

	.col-lg-push-4 {
		left: 33.3333333333%;
	}

	.col-lg-push-5 {
		left: 41.6666666667%;
	}

	.col-lg-push-6 {
		left: 50%;
	}

	.col-lg-push-7 {
		left: 58.3333333333%;
	}

	.col-lg-push-8 {
		left: 66.6666666667%;
	}

	.col-lg-push-9 {
		left: 75%;
	}

	.col-lg-push-10 {
		left: 83.3333333333%;
	}

	.col-lg-push-11 {
		left: 91.6666666667%;
	}

	.col-lg-push-12 {
		left: 100%;
	}

	.col-lg-offset-0 {
		margin-left: 0%;
	}

	.col-lg-offset-1 {
		margin-left: 8.3333333333%;
	}

	.col-lg-offset-2 {
		margin-left: 16.6666666667%;
	}

	.col-lg-offset-3 {
		margin-left: 25%;
	}

	.col-lg-offset-4 {
		margin-left: 33.3333333333%;
	}

	.col-lg-offset-5 {
		margin-left: 41.6666666667%;
	}

	.col-lg-offset-6 {
		margin-left: 50%;
	}

	.col-lg-offset-7 {
		margin-left: 58.3333333333%;
	}

	.col-lg-offset-8 {
		margin-left: 66.6666666667%;
	}

	.col-lg-offset-9 {
		margin-left: 75%;
	}

	.col-lg-offset-10 {
		margin-left: 83.3333333333%;
	}

	.col-lg-offset-11 {
		margin-left: 91.6666666667%;
	}

	.col-lg-offset-12 {
		margin-left: 100%;
	}
}

table {
	background-color: transparent;
}

caption {
	padding: 15px 10px;
	padding: 15px 10px;
	color: #fff;
	text-align: left;
}

th {
	text-align: left;
}

.table {
	width: 100%;
	max-width: 100%;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
	padding: 10px 5px;
	line-height: 1.625;
	vertical-align: top;
	border-top: 1px solid #ededed;
}

.table > thead > tr > th {
	vertical-align: bottom;
	border-bottom: 2px solid #ededed;
}

.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
	border-top: 0;
}

.table > tbody + tbody {
	border-top: 1px solid #ededed;
}

.table .table {
	background-color: #fff;
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
	padding: 5px;
}

.table-bordered {
	border: 1px solid #ededed;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
	border: 1px solid #ededed;
}

.table-striped > tbody > tr:nth-child(odd) {
	background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
	background-color: #f5f5f5;
}

table col[class*="col-"] {
	position: static;
	float: none;
	display: table-column;
}

table td[class*="col-"],
table th[class*="col-"] {
	position: static;
	float: none;
	display: table-cell;
}

.table > thead > tr > td.active,
.table > thead > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
	background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
	background-color: #e8e8e8;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
	background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
	background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
	background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
	background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
	background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
	background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
	background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
	background-color: #ebcccc;
}

.table-responsive {
	overflow-x: auto;
	min-height: 0.01%;
}

@media screen and (max-width: 767px) {
	.table-responsive {
		width: 100%;
		margin-bottom: 16.5px;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ededed;
	}
	.table-responsive > .table {
		margin-bottom: 0;
	}
	.table-responsive > .table > thead > tr > th,
	.table-responsive > .table > thead > tr > td,
	.table-responsive > .table > tbody > tr > th,
	.table-responsive > .table > tbody > tr > td,
	.table-responsive > .table > tfoot > tr > th,
	.table-responsive > .table > tfoot > tr > td {
		white-space: nowrap;
	}
	.table-responsive > .table-bordered {
		border: 0;
	}
	.table-responsive > .table-bordered > thead > tr > th:first-child,
	.table-responsive > .table-bordered > thead > tr > td:first-child,
	.table-responsive > .table-bordered > tbody > tr > th:first-child,
	.table-responsive > .table-bordered > tbody > tr > td:first-child,
	.table-responsive > .table-bordered > tfoot > tr > th:first-child,
	.table-responsive > .table-bordered > tfoot > tr > td:first-child {
		border-left: 0;
	}
	.table-responsive > .table-bordered > thead > tr > th:last-child,
	.table-responsive > .table-bordered > thead > tr > td:last-child,
	.table-responsive > .table-bordered > tbody > tr > th:last-child,
	.table-responsive > .table-bordered > tbody > tr > td:last-child,
	.table-responsive > .table-bordered > tfoot > tr > th:last-child,
	.table-responsive > .table-bordered > tfoot > tr > td:last-child {
		border-right: 0;
	}
	.table-responsive > .table-bordered > tbody > tr:last-child > th,
	.table-responsive > .table-bordered > tbody > tr:last-child > td,
	.table-responsive > .table-bordered > tfoot > tr:last-child > th,
	.table-responsive > .table-bordered > tfoot > tr:last-child > td {
		border-bottom: 0;
	}
}

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	min-width: 0;
}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: 22px;
	font-size: 16px;
	line-height: inherit;
	color: #444;
	border: 0;
	border-bottom: 1px solid #e5e5e5;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

input[type="search"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9
;
	line-height: normal;
}

input[type="file"] {
	display: block;
}

input[type="range"] {
	display: block;
	width: 100%;
}

select[multiple],
select[size] {
	height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: none;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

output {
	display: block;
	padding-top: 10px;
	font-size: 14px;
	line-height: 1.625;
	color: #8899a6;
}

.form-control {
	display: block;
	width: 100%;
	height: 36px;
	padding: 9px 15px;
	font-size: 13px;
	font-weight: 400;
	font-family: "Open Sans", sans-serif;
	line-height: 1.625;
	color: #8899a6;
	background-color: #fff;
	background-image: none;
	border: 1px solid #e5e5e5;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
	border-color: #e5e5e5;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control::-moz-placeholder {
	color: #999;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #999;
}

.form-control::-webkit-input-placeholder {
	color: #999;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
	cursor: not-allowed;
	background-color: #999;
	opacity: 1;
}

textarea.form-control {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		line-height: 36px;
	}

	input[type="date"].input-sm, .input-group-sm > input[type="date"].form-control,
	.input-group-sm > input[type="date"].input-group-addon,
	.input-group-sm > .input-group-btn > input[type="date"].btn,
	input[type="time"].input-sm,
	.input-group-sm > input[type="time"].form-control,
	.input-group-sm > input[type="time"].input-group-addon,
	.input-group-sm > .input-group-btn > input[type="time"].btn,
	input[type="datetime-local"].input-sm,
	.input-group-sm > input[type="datetime-local"].form-control,
	.input-group-sm > input[type="datetime-local"].input-group-addon,
	.input-group-sm > .input-group-btn > input[type="datetime-local"].btn,
	input[type="month"].input-sm,
	.input-group-sm > input[type="month"].form-control,
	.input-group-sm > input[type="month"].input-group-addon,
	.input-group-sm > .input-group-btn > input[type="month"].btn {
		line-height: 30px;
	}

	input[type="date"].input-lg, .input-group-lg > input[type="date"].form-control,
	.input-group-lg > input[type="date"].input-group-addon,
	.input-group-lg > .input-group-btn > input[type="date"].btn,
	input[type="time"].input-lg,
	.input-group-lg > input[type="time"].form-control,
	.input-group-lg > input[type="time"].input-group-addon,
	.input-group-lg > .input-group-btn > input[type="time"].btn,
	input[type="datetime-local"].input-lg,
	.input-group-lg > input[type="datetime-local"].form-control,
	.input-group-lg > input[type="datetime-local"].input-group-addon,
	.input-group-lg > .input-group-btn > input[type="datetime-local"].btn,
	input[type="month"].input-lg,
	.input-group-lg > input[type="month"].form-control,
	.input-group-lg > input[type="month"].input-group-addon,
	.input-group-lg > .input-group-btn > input[type="month"].btn {
		line-height: 50px;
	}
}

.form-group {
	margin-bottom: 15px;
}

.radio label,
.checkbox label {
	min-height: 22px;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	position: absolute;
	margin-left: -20px;
	margin-top: 4px \9
;
}

.radio + .radio,
.checkbox + .checkbox {
	margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	margin-top: 0;
	margin-left: 10px;
}

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled] input[type="checkbox"] {
	cursor: not-allowed;
}

.radio-inline.disabled, fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
	cursor: not-allowed;
}

.radio.disabled label, fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
	cursor: not-allowed;
}

.form-control-static {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 0;
}

.form-control-static.input-lg, .input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
	padding-left: 0;
	padding-right: 0;
}

.input-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn, .form-group-sm .form-control {
	height: 30px;
	padding: 5px 20px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 0px;
}

select.input-sm, .input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn, .form-group-sm .form-control {
	height: 30px;
	line-height: 30px;
}

textarea.input-sm, .input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn, .form-group-sm .form-control,
select[multiple].input-sm,
.input-group-sm > select[multiple].form-control,
.input-group-sm > select[multiple].input-group-addon,
.input-group-sm > .input-group-btn > select[multiple].btn, .form-group-sm .form-control {
	height: auto;
}

.input-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn, .form-group-lg .form-control {
	height: 50px;
	padding: 13px 28px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 0px;
}

select.input-lg, .input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn, .form-group-lg .form-control {
	height: 50px;
	line-height: 50px;
}

textarea.input-lg, .input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn, .form-group-lg .form-control,
select[multiple].input-lg,
.input-group-lg > select[multiple].form-control,
.input-group-lg > select[multiple].input-group-addon,
.input-group-lg > .input-group-btn > select[multiple].btn, .form-group-lg .form-control {
	height: auto;
}

.has-feedback {
	position: relative;
}

.has-feedback .form-control {
	padding-right: 45px;
}

.form-control-feedback {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 36px;
	height: 36px;
	line-height: 36px;
	text-align: center;
	pointer-events: none;
}

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback,
.input-group-lg > .input-group-addon + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback {
	width: 50px;
	height: 50px;
	line-height: 50px;
}

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback,
.input-group-sm > .input-group-addon + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
	color: #3c763d;
}

.has-success .form-control {
	border-color: #3c763d;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
	border-color: #2b542c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
	color: #3c763d;
	border-color: #3c763d;
	background-color: #dff0d8;
}

.has-success .form-control-feedback {
	color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
	color: #8a6d3b;
}

.has-warning .form-control {
	border-color: #8a6d3b;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
	border-color: #66512c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
	color: #8a6d3b;
	border-color: #8a6d3b;
	background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
	color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
	color: #a94442;
}

.has-error .input-group-addon {
	color: #a94442;
	border-color: #a94442;
	background-color: #f2dede;
}

.has-error .form-control-feedback {
	color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
	top: 27px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
	top: 0;
}

.help-block {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	color: #848484;
}

@media (min-width: 768px) {
	.form-inline .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
	.form-inline .form-control-static {
		display: inline-block;
	}
	.form-inline .input-group {
		display: inline-table;
		vertical-align: middle;
	}
	.form-inline .input-group .input-group-addon,
	.form-inline .input-group .input-group-btn,
	.form-inline .input-group .form-control {
		width: auto;
	}
	.form-inline .input-group > .form-control {
		width: 100%;
	}
	.form-inline .control-label {
		margin-bottom: 0;
		vertical-align: middle;
	}
	.form-inline .radio,
	.form-inline .checkbox {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.form-inline .radio label,
	.form-inline .checkbox label {
		padding-left: 0;
	}
	.form-inline .radio input[type="radio"],
	.form-inline .checkbox input[type="checkbox"] {
		position: relative;
		margin-left: 0;
	}
	.form-inline .has-feedback .form-control-feedback {
		top: 0;
	}
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 10px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
	min-height: 32px;
}

.form-horizontal .form-group {
	margin-left: -15px;
	margin-right: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
	content: " ";
	display: table;
}

.form-horizontal .form-group:after {
	clear: both;
}

@media (min-width: 768px) {
	.form-horizontal .control-label {
		margin-bottom: 0;
		padding-top: 10px;
	}
}

.form-horizontal .has-feedback .form-control-feedback {
	right: 15px;
}

@media (min-width: 768px) {
	.form-horizontal .form-group-lg .control-label {
		padding-top: 18.29px;
	}
}

@media (min-width: 768px) {
	.form-horizontal .form-group-sm .control-label {
		padding-top: 6px;
	}
}

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: 600;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 0 15px;
	font-size: 14px;
	line-height: 36px;
	border-radius: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-transform: uppercase;
	font-family: "Open Sans", sans-serif;
	height: 36px;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
	outline: none;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
	color: #444;
	text-decoration: none;
}

.btn:active, .btn.active {
	outline: 0;
	background-image: none;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-default {
	color: #fff;
	background-color: #414141;
	border-color: #414141;
}

.btn-default:hover, .btn-default:focus, .btn-default.focus, .btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
	color: #fff;
	background-color: #282828;
	border-color: #222222;
}

.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
	background-image: none;
}

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, .btn-default[disabled]:active, .btn-default[disabled].active, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
	background-color: #414141;
	border-color: #414141;
}

.btn-default .badge {
	color: #414141;
	background-color: #fff;
}

.btn-primary {
	color: #fff;
	background-color: #1e487b;
	border-color: #1e487b;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #143052;
	border-color: #122b4a;
}

.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
	background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
	background-color: #1e487b;
	border-color: #1e487b;
}

.btn-primary .badge {
	color: #1e487b;
	background-color: #fff;
}

.btn-success {
	color: #fff;
	background-color: #6eae34;
	border-color: transparent;
}

.btn-success:hover, .btn-success:focus, .btn-success.focus, .btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
	color: #fff;
	background-color: #558728;
	border-color: rgba(0, 0, 0, 0);
}

.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
	background-image: none;
}

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active, fieldset[disabled] .btn-success, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active {
	background-color: #6eae34;
	border-color: transparent;
}

.btn-success .badge {
	color: #6eae34;
	background-color: #fff;
}

.btn-info {
	color: #fff;
	background-color: #03a9f4;
	border-color: #0398db;
}

.btn-info:hover, .btn-info:focus, .btn-info.focus, .btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
	color: #fff;
	background-color: #0286c2;
	border-color: #026e9e;
}

.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
	background-image: none;
}

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active, fieldset[disabled] .btn-info, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info.focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info.active {
	background-color: #03a9f4;
	border-color: #0398db;
}

.btn-info .badge {
	color: #03a9f4;
	background-color: #fff;
}

.btn-warning {
	color: #fff;
	background-color: #efc73a;
	border-color: #edc022;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning.focus, .btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
	color: #fff;
	background-color: #e4b512;
	border-color: #c39b10;
}

.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
	background-image: none;
}

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active, fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active {
	background-color: #efc73a;
	border-color: #edc022;
}

.btn-warning .badge {
	color: #efc73a;
	background-color: #fff;
}

.btn-danger {
	color: #fff;
	background-color: #f6821f;
	border-color: #f2750a;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger.focus, .btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #d96909;
	border-color: #b75807;
}

.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
	background-image: none;
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active, fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
	background-color: #f6821f;
	border-color: #f2750a;
}

.btn-danger .badge {
	color: #f6821f;
	background-color: #fff;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-link:hover, .btn-link:focus {
	color: #2d5cb8;
	text-decoration: none;
	background-color: transparent;
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus {
	color: #bbbbbb;
	text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
	height: 46px;
	padding: 18px 33px;
	font-size: 13px;
	line-height: 10px;
	border-radius: 0px;
}

.btn-md {
	height: 42px;
	padding: 12px 25px;
	font-size: 13px;
	line-height: 1.33;
	border-radius: 0px;
}

.btn-sm, .btn-group-sm > .btn {
	height: 40px;
	padding: 0px 25px;
	font-size: 12px;
	line-height: 40px;
	border-radius: 3px;
}

.btn-xs, .btn-group-xs > .btn {
	height: 32px;
	padding: 0 20px;
	font-size: 13px;
	line-height: 31px;
	border-radius: 3px;
	font-weight: 500;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-block + .btn-block {
	margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%;
}

.fade {
	opacity: 0;
	-webkit-transition: opacity 0.15s linear;
	-o-transition: opacity 0.15s linear;
	transition: opacity 0.15s linear;
}

.fade.in {
	opacity: 1;
}

.collapse {
	display: none;
	visibility: hidden;
}

.collapse.in {
	display: block;
	visibility: visible;
}

tr.collapse.in {
	display: table-row;
}

tbody.collapse.in {
	display: table-row-group;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-property: height, visibility;
	transition-property: height, visibility;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 8px;
	vertical-align: middle;
	border-top: 4px solid;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

.dropdown {
	position: relative;
}

.dropdown-toggle:focus {
	outline: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 400px;
	padding: 10px;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	text-align: left;
	background-color: #fff;
	border-radius: 3px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
	color: #444;
}

.dropdown-menu.pull-right {
	right: 0;
	left: auto;
}

.dropdown-menu .divider {
	height: 1px;
	margin: 10px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}

.dropdown-menu > li > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	color: #444;
	white-space: nowrap;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
	text-decoration: none;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	background-color: #1e487b;
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
	color: #bbbbbb;
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
	text-decoration: none;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	cursor: not-allowed;
}

.open > .dropdown-menu {
	display: block;
}

.open > a {
	outline: 0;
}

.dropdown-menu-right {
	left: auto;
	right: 0;
}

.dropdown-menu-left {
	left: 0;
	right: auto;
}

.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 12px;
	line-height: 1.625;
	color: #bbbbbb;
	white-space: nowrap;
}

.dropdown-backdrop {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 990;
}

.pull-right > .dropdown-menu {
	right: 0;
	left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px solid;
	content: "";
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 1px;
}

@media (min-width: 768px) {
	.navbar-right .dropdown-menu {
		right: 0;
		left: auto;
	}
	.navbar-right .dropdown-menu-left {
		left: 0;
		right: auto;
	}
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
	position: relative;
	float: left;
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
	z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
	margin-left: -1px;
}

.btn-toolbar {
	margin-left: -5px;
}

.btn-toolbar:before, .btn-toolbar:after {
	content: " ";
	display: table;
}

.btn-toolbar:after {
	clear: both;
}

.btn-toolbar .btn-group,
.btn-toolbar .input-group {
	float: left;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
	margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0;
}

.btn-group > .btn:first-child {
	margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.btn-group > .btn-group {
	float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}

.btn-group > .btn-group:first-child > .btn:last-child,
.btn-group > .btn-group:first-child > .dropdown-toggle {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.btn-group > .btn-group:last-child > .btn:first-child {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
	outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
	padding-left: 8px;
	padding-right: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
	padding-left: 12px;
	padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.open .dropdown-toggle.btn-link {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn .caret {
	margin-left: 0;
}

.btn-lg .caret, .btn-group-lg > .btn .caret {
	border-width: 5px 5px 0;
	border-bottom-width: 0;
}

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
	border-width: 0 5px 5px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
}

.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
	content: " ";
	display: table;
}

.btn-group-vertical > .btn-group:after {
	clear: both;
}

.btn-group-vertical > .btn-group > .btn {
	float: none;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
	margin-top: -1px;
	margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
	border-bottom-left-radius: 3px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.btn-group-justified {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
}

.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
	float: none;
	display: table-cell;
	width: 1%;
}

.btn-group-justified > .btn-group .btn {
	width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
	left: auto;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}

.input-group[class*="col-"] {
	float: none;
	padding-left: 0;
	padding-right: 0;
}

.input-group .form-control {
	position: relative;
	float: left;
	width: 100%;
	margin-bottom: 0;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
	display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.input-group-addon,
.input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}

.input-group-addon {
	padding: 9px 28px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	color: #8899a6;
	text-align: center;
	background-color: #999;
	border: 1px solid #e5e5e5;
	border-radius: 3px;
}

.input-group-addon.input-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
	padding: 5px 20px;
	font-size: 12px;
	border-radius: 0px;
}

.input-group-addon.input-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
	padding: 13px 28px;
	font-size: 18px;
	border-radius: 0px;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
	margin-top: 0;
}

.input-group-addon:first-child {
	border-right: 0;
}

.input-group-addon:last-child {
	border-left: 0;
}

.input-group-btn {
	position: relative;
	white-space: nowrap;
}

.input-group-btn > .btn {
	position: relative;
}

.input-group-btn > .btn + .btn {
	margin-left: -1px;
}

.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
	z-index: 2;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
	margin-right: -1px;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
	margin-left: -1px;
}

.nav {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

.nav:before, .nav:after {
	content: " ";
	display: table;
}

.nav:after {
	clear: both;
}

.nav > li {
	position: relative;
	display: block;
}

.nav > li > a {
	position: relative;
	display: block;
	padding: 10px 25px;
}

.nav > li > a:hover, .nav > li > a:focus {
	text-decoration: none;
}

.nav > li.disabled > a {
	color: #bbbbbb;
}

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
	color: #bbbbbb;
	text-decoration: none;
	background-color: transparent;
	cursor: not-allowed;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
	background-color: #999;
	border-color: #2d5cb8;
}

.nav .nav-divider {
	height: 1px;
	margin: 10px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}

.nav > li > a > img {
	max-width: none;
}

.nav-tabs > li {
	float: left;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.nav-tabs > li > a {
	line-height: 1.625;
	color: #fff;
	padding: 20px;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	cursor: default;
}

.tab-content {
	border: 1px solid transparent;
}

.nav-pills > li {
	float: left;
}

.nav-pills > li > a {
	font-weight: 800;
	text-transform: uppercase;
	border-radius: 3px;
}

.nav-pills > li + li {
	margin-left: 2px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
	color: #444;
	background-color: transparent;
	border-bottom: 2px solid #6eae34;
}

.nav-stacked > li {
	float: none;
}

.nav-stacked > li + li {
	margin-left: 0;
}

.nav-justified, .nav-tabs.nav-justified {
	width: 100%;
}

.nav-justified > li, .nav-tabs.nav-justified > li {
	float: none;
}

.nav-justified > li > a, .nav-tabs.nav-justified > li > a {
	text-align: center;
	margin-bottom: 5px;
}

.nav-justified > .dropdown .dropdown-menu {
	top: auto;
	left: auto;
}

@media (min-width: 768px) {
	.nav-justified > li, .nav-tabs.nav-justified > li {
		display: table-cell;
		width: 1%;
	}
	.nav-justified > li > a, .nav-tabs.nav-justified > li > a {
		margin-bottom: 0;
	}
}

.nav-tabs-justified, .nav-tabs.nav-justified {
	border-bottom: 0;
}

.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
	margin-right: 0;
	border-radius: 3px;
}

.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:focus {
	border: 1px solid #ededed;
}

@media (min-width: 768px) {
	.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
		border-bottom: 1px solid #ededed;
		border-radius: 3px 3px 0 0;
	}
	.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
	.nav-tabs-justified > .active > a:hover,
	.nav-tabs.nav-justified > .active > a:hover,
	.nav-tabs-justified > .active > a:focus,
	.nav-tabs.nav-justified > .active > a:focus {
		border-bottom-color: #fff;
	}
}

.tab-content > .tab-pane {
	display: none;
	visibility: hidden;
}

.tab-content > .active {
	display: block;
	visibility: visible;
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.navbar {
	position: relative;
	min-height: 40px;
	margin-bottom: 22px;
	border: 1px solid transparent;
}

.navbar:before, .navbar:after {
	content: " ";
	display: table;
}

.navbar:after {
	clear: both;
}

@media (min-width: 768px) {
	.navbar {
		border-radius: 3px;
	}
}

.navbar-header:before, .navbar-header:after {
	content: " ";
	display: table;
}

.navbar-header:after {
	clear: both;
}

@media (min-width: 768px) {
	.navbar-header {
		float: left;
	}
}

.navbar-collapse {
	overflow-x: visible;
	padding-right: 15px;
	padding-left: 15px;
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
	-webkit-overflow-scrolling: touch;
}

.navbar-collapse:before, .navbar-collapse:after {
	content: " ";
	display: table;
}

.navbar-collapse:after {
	clear: both;
}

.navbar-collapse.in {
	overflow-y: auto;
}

@media (min-width: 768px) {
	.navbar-collapse {
		width: auto;
		border-top: 0;
		box-shadow: none;
	}
	.navbar-collapse.collapse {
		display: block !important;
		visibility: visible !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important;
	}
	.navbar-collapse.in {
		overflow-y: visible;
	}
	.navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
		padding-left: 0;
		padding-right: 0;
	}
}

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
	max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
	.navbar-fixed-top .navbar-collapse,
	.navbar-fixed-bottom .navbar-collapse {
		max-height: 200px;
	}
}

.container > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-header,
.container-fluid > .navbar-collapse {
	margin-right: -15px;
	margin-left: -15px;
}

@media (min-width: 768px) {
	.container > .navbar-header,
	.container > .navbar-collapse,
	.container-fluid > .navbar-header,
	.container-fluid > .navbar-collapse {
		margin-right: 0;
		margin-left: 0;
	}
}

.navbar-static-top {
	z-index: 1000;
	border-width: 0 0 1px;
}

@media (min-width: 768px) {
	.navbar-static-top {
		border-radius: 0;
	}
}

.navbar-fixed-top,
.navbar-fixed-bottom {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
}

@media (min-width: 768px) {
	.navbar-fixed-top,
	.navbar-fixed-bottom {
		border-radius: 0;
	}
}

.navbar-fixed-top {
	top: 0;
	border-width: 0 0 1px;
}

.navbar-fixed-bottom {
	bottom: 0;
	margin-bottom: 0;
	border-width: 1px 0 0;
}

.navbar-brand {
	float: left;
	padding: 9px 15px;
	font-size: 18px;
	line-height: 22px;
	height: 40px;
}

.navbar-brand:hover, .navbar-brand:focus {
	text-decoration: none;
}

.navbar-brand > img {
	display: block;
}

@media (min-width: 768px) {
	.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
		margin-left: -15px;
	}
}

.navbar-toggle {
	position: relative;
	float: right;
	margin-right: 15px;
	padding: 9px 10px;
	margin-top: 3px;
	margin-bottom: 3px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 3px;
}

.navbar-toggle:focus {
	outline: 0;
}

.navbar-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	border-radius: 1px;
}

.navbar-toggle .icon-bar + .icon-bar {
	margin-top: 4px;
}

@media (min-width: 768px) {
	.navbar-toggle {
		display: none;
	}
}

.navbar-nav {
	margin: 4.5px -15px;
}

.navbar-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
}

@media (max-width: 767px) {
	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		box-shadow: none;
	}
	.navbar-nav .open .dropdown-menu > li > a,
	.navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 5px 15px 5px 25px;
	}
	.navbar-nav .open .dropdown-menu > li > a {
		line-height: 22px;
	}
	.navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
		background-image: none;
	}
}

@media (min-width: 768px) {
	.navbar-nav {
		float: left;
		margin: 0;
	}
	.navbar-nav > li {
		float: left;
	}
	.navbar-nav > li > a {
		padding-top: 9px;
		padding-bottom: 9px;
	}
}

.navbar-form {
	margin-left: -15px;
	margin-right: -15px;
	padding: 10px 15px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	margin-top: 2px;
	margin-bottom: 2px;
}

@media (min-width: 768px) {
	.navbar-form .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.navbar-form .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
	.navbar-form .form-control-static {
		display: inline-block;
	}
	.navbar-form .input-group {
		display: inline-table;
		vertical-align: middle;
	}
	.navbar-form .input-group .input-group-addon,
	.navbar-form .input-group .input-group-btn,
	.navbar-form .input-group .form-control {
		width: auto;
	}
	.navbar-form .input-group > .form-control {
		width: 100%;
	}
	.navbar-form .control-label {
		margin-bottom: 0;
		vertical-align: middle;
	}
	.navbar-form .radio,
	.navbar-form .checkbox {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.navbar-form .radio label,
	.navbar-form .checkbox label {
		padding-left: 0;
	}
	.navbar-form .radio input[type="radio"],
	.navbar-form .checkbox input[type="checkbox"] {
		position: relative;
		margin-left: 0;
	}
	.navbar-form .has-feedback .form-control-feedback {
		top: 0;
	}
}

@media (max-width: 767px) {
	.navbar-form .form-group {
		margin-bottom: 5px;
	}
	.navbar-form .form-group:last-child {
		margin-bottom: 0;
	}
}

@media (min-width: 768px) {
	.navbar-form {
		width: auto;
		border: 0;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
		padding-bottom: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.navbar-nav > li > .dropdown-menu {
	margin-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.navbar-btn {
	margin-top: 2px;
	margin-bottom: 2px;
}

.navbar-btn.btn-sm, .btn-group-sm > .navbar-btn.btn {
	margin-top: 5px;
	margin-bottom: 5px;
}

.navbar-btn.btn-xs, .btn-group-xs > .navbar-btn.btn {
	margin-top: 9px;
	margin-bottom: 9px;
}

.navbar-text {
	margin-top: 9px;
	margin-bottom: 9px;
}

@media (min-width: 768px) {
	.navbar-text {
		float: left;
		margin-left: 15px;
		margin-right: 15px;
	}
}

@media (min-width: 768px) {
	.navbar-left {
		float: left !important;
	}

	.navbar-right {
		float: right !important;
		margin-right: -15px;
	}
	.navbar-right ~ .navbar-right {
		margin-right: 0;
	}
}

.navbar-default {
	background-color: #f8f8f8;
	border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
	color: #777;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
	color: #5e5e5e;
	background-color: transparent;
}

.navbar-default .navbar-text {
	color: #777;
}

.navbar-default .navbar-nav > li > a {
	color: #777;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
	color: #333;
	background-color: transparent;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	color: #555;
	background-color: #e7e7e7;
}

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
	color: #ccc;
	background-color: transparent;
}

.navbar-default .navbar-toggle {
	border-color: #ededed;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #ededed;
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: #888;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #e7e7e7;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
	background-color: #e7e7e7;
	color: #555;
}

@media (max-width: 767px) {
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: #777;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #333;
		background-color: transparent;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #555;
		background-color: #e7e7e7;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #ccc;
		background-color: transparent;
	}
}

.navbar-default .navbar-link {
	color: #777;
}

.navbar-default .navbar-link:hover {
	color: #333;
}

.navbar-default .btn-link {
	color: #777;
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
	color: #333;
}

.navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:hover, fieldset[disabled] .navbar-default .btn-link:focus {
	color: #ccc;
}

.navbar-inverse {
	background-color: #222;
	border-color: #090909;
}

.navbar-inverse .navbar-brand {
	color: #e1e1e1;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
	color: #fff;
	background-color: transparent;
}

.navbar-inverse .navbar-text {
	color: #e1e1e1;
}

.navbar-inverse .navbar-nav > li > a {
	color: #e1e1e1;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
	color: #fff;
	background-color: transparent;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
	color: #fff;
	background-color: #090909;
}

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
	color: #444;
	background-color: transparent;
}

.navbar-inverse .navbar-toggle {
	border-color: #333;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
	background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
	background-color: #fff;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
	border-color: #101010;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
	background-color: #090909;
	color: #fff;
}

@media (max-width: 767px) {
	.navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
		border-color: #090909;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu .divider {
		background-color: #090909;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
		color: #e1e1e1;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #fff;
		background-color: transparent;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #fff;
		background-color: #090909;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #444;
		background-color: transparent;
	}
}

.navbar-inverse .navbar-link {
	color: #e1e1e1;
}

.navbar-inverse .navbar-link:hover {
	color: #fff;
}

.navbar-inverse .btn-link {
	color: #e1e1e1;
}

.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
	color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:hover, fieldset[disabled] .navbar-inverse .btn-link:focus {
	color: #444;
}

.breadcrumb > li {
	display: inline-block;
}

.breadcrumb > li:first-child a span {
	font-size: 16px;
	line-height: 1;
}

.breadcrumb > li + li:before {
	content: "/ ";
	padding: 0 5px;
	color: #444;
}

.breadcrumb > .active {
	font-weight: 600;
}

.pager {
	padding-left: 0;
	margin: 22px 0;
	list-style: none;
	text-align: center;
}

.pager:before, .pager:after {
	content: " ";
	display: table;
}

.pager:after {
	clear: both;
}

.pager li {
	display: inline;
}

.pager li > a,
.pager li > span {
	display: inline-block;
	padding: 5px 14px;
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 15px;
}

.pager li > a:hover,
.pager li > a:focus {
	text-decoration: none;
	background-color: #999;
}

.pager .next > a,
.pager .next > span {
	float: right;
}

.pager .previous > a,
.pager .previous > span {
	float: left;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
	color: #bbbbbb;
	background-color: #fff;
	cursor: not-allowed;
}

.label {
	display: inline-block;
	font-size: 100%;
	font-weight: normal;
	line-height: 64px;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 100%;
	width: 64px;
	height: 64px;
}

.label:empty {
	display: none;
}

.btn .label {
	position: relative;
	top: -1px;
}

a.label:hover, a.label:focus {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}

.label-default {
	background-color: #bbbbbb;
}

.label-default[href]:hover, .label-default[href]:focus {
	background-color: #a2a2a2;
}

.label-primary {
	background-color: #1e487b;
}

.label-primary[href]:hover, .label-primary[href]:focus {
	background-color: #143052;
}

.label-success {
	background-color: #6eae34;
}

.label-success[href]:hover, .label-success[href]:focus {
	background-color: #558728;
}

.label-info {
	background-color: #03a9f4;
}

.label-info[href]:hover, .label-info[href]:focus {
	background-color: #0286c2;
}

.label-warning {
	background-color: #efc73a;
}

.label-warning[href]:hover, .label-warning[href]:focus {
	background-color: #e4b512;
}

.label-danger {
	background-color: #f6821f;
}

.label-danger[href]:hover, .label-danger[href]:focus {
	background-color: #d96909;
}

.alert {
	padding: 10px;
	margin: 10px 0;
	border: 1px solid transparent;
	border-radius: 5px;
}

.alert h4 {
	margin-top: 0;
	color: inherit;
}

.alert .alert-link {
	font-weight: bold;
}

.alert > p,
.alert > ul {
	margin-bottom: 0;
}

.alert > p + p {
	margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
	padding-right: 30px;
}

.alert-dismissable .close,
.alert-dismissible .close {
	position: relative;
	top: -2px;
	right: -21px;
	color: inherit;
}

.alert-success {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #3c763d;
}

.alert-success hr {
	border-top-color: #c9e2b3;
}

.alert-success .alert-link {
	color: #2b542c;
}

.alert-info {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f;
}

.alert-info hr {
	border-top-color: #a6e1ec;
}

.alert-info .alert-link {
	color: #245269;
}

.alert-warning {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b;
}

.alert-warning hr {
	border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
	color: #66512c;
}

.alert-danger {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442;
}

.alert-danger hr {
	border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
	color: #843534;
}

.list-group {
	margin-bottom: 20px;
	padding-left: 0;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
}

.list-group-item:first-child {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.list-group-item a {
	color: #555;
}

.list-group-item a .list-group-item-heading {
	color: #333;
}

.list-group-item a:hover, .list-group-item a:focus {
	text-decoration: none;
	color: #444;
	background-color: transparent;
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
	background-color: #999;
	color: #bbbbbb;
	cursor: not-allowed;
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
	color: inherit;
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
	color: #bbbbbb;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
	z-index: 2;
	color: #fff;
	background-color: #1e487b;
	border-color: #1e487b;
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading > .small {
	color: inherit;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
	color: #84aee1;
}

.list-group-item-success {
	color: #3c763d;
	background-color: #dff0d8;
}

a.list-group-item-success {
	color: #3c763d;
}

a.list-group-item-success .list-group-item-heading {
	color: inherit;
}

a.list-group-item-success:hover, a.list-group-item-success:focus {
	color: #3c763d;
	background-color: #d0e9c6;
}

a.list-group-item-success.active, a.list-group-item-success.active:hover, a.list-group-item-success.active:focus {
	color: #fff;
	background-color: #3c763d;
	border-color: #3c763d;
}

.list-group-item-info {
	color: #31708f;
	background-color: #d9edf7;
}

a.list-group-item-info {
	color: #31708f;
}

a.list-group-item-info .list-group-item-heading {
	color: inherit;
}

a.list-group-item-info:hover, a.list-group-item-info:focus {
	color: #31708f;
	background-color: #c4e3f3;
}

a.list-group-item-info.active, a.list-group-item-info.active:hover, a.list-group-item-info.active:focus {
	color: #fff;
	background-color: #31708f;
	border-color: #31708f;
}

.list-group-item-warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
}

a.list-group-item-warning {
	color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading {
	color: inherit;
}

a.list-group-item-warning:hover, a.list-group-item-warning:focus {
	color: #8a6d3b;
	background-color: #faf2cc;
}

a.list-group-item-warning.active, a.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus {
	color: #fff;
	background-color: #8a6d3b;
	border-color: #8a6d3b;
}

.list-group-item-danger {
	color: #a94442;
	background-color: #f2dede;
}

a.list-group-item-danger {
	color: #a94442;
}

a.list-group-item-danger .list-group-item-heading {
	color: inherit;
}

a.list-group-item-danger:hover, a.list-group-item-danger:focus {
	color: #a94442;
	background-color: #ebcccc;
}

a.list-group-item-danger.active, a.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus {
	color: #fff;
	background-color: #a94442;
	border-color: #a94442;
}

.list-group-item-heading {
	margin-top: 0;
	margin-bottom: 5px;
}

.list-group-item-text {
	margin-bottom: 0;
	line-height: 1.3;
}

.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	border: 0;
}

.embed-responsive.embed-responsive-16by9 {
	padding-bottom: 56.25%;
}

.embed-responsive.embed-responsive-4by3 {
	padding-bottom: 75%;
}

.close {
	float: right;
	font-size: 21px;
	font-weight: bold;
	line-height: 1;
	color: #444;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.2;
	filter: alpha(opacity=20);
}

.close:hover, .close:focus {
	color: #444;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.5;
	filter: alpha(opacity=50);
}

button.close {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}

.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}

.modal.fade .modal-dialog {
	-webkit-transform: translate(0, -25%);
	-ms-transform: translate(0, -25%);
	-o-transform: translate(0, -25%);
	transform: translate(0, -25%);
	-webkit-transition: -webkit-transform 0.3s ease-out;
	-moz-transition: -moz-transform 0.3s ease-out;
	-o-transition: -o-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px auto;
}

.modal-content {
	position: relative;
	background-color: #fff;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0px;
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	background-clip: padding-box;
	outline: 0;
}

.modal-backdrop {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background-color: #444;
}

.modal-backdrop.fade {
	opacity: 0;
	filter: alpha(opacity=0);
}

.modal-backdrop.in {
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.modal-header {
	padding: 15px;
	border-bottom: 1px solid #e5e5e5;
	min-height: 16.625px;
}

.modal-header .close {
	margin-top: -2px;
}

.modal-title {
	margin: 0;
	line-height: 1.625;
}

.modal-body {
	position: relative;
	padding: 15px;
}

.modal-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid #e5e5e5;
}

.modal-footer:before, .modal-footer:after {
	content: " ";
	display: table;
}

.modal-footer:after {
	clear: both;
}

.modal-footer .btn + .btn {
	margin-left: 5px;
	margin-bottom: 0;
}

.modal-footer .btn-group .btn + .btn {
	margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
	margin-left: 0;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

@media (min-width: 768px) {
	.modal-dialog {
		width: 600px;
		margin: 30px auto;
	}

	.modal-content {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	}

	.modal-sm {
		width: 300px;
	}
}

@media (min-width: 992px) {
	.modal-lg {
		width: 900px;
	}
}

.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	visibility: visible;
	font-family: "Open Sans", sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 1.4;
	opacity: 0;
	filter: alpha(opacity=0);
}

.tooltip.in {
	opacity: 0.9;
	filter: alpha(opacity=90);
}

.tooltip.top {
	margin-top: -3px;
	padding: 5px 0;
}

.tooltip.right {
	margin-left: 3px;
	padding: 0 5px;
}

.tooltip.bottom {
	margin-top: 3px;
	padding: 5px 0;
}

.tooltip.left {
	margin-left: -3px;
	padding: 0 5px;
}

.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	background-color: #444;
	border-radius: 3px;
}

.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}

.tooltip.top .tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #444;
}

.tooltip.top-left .tooltip-arrow {
	bottom: 0;
	right: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #444;
}

.tooltip.top-right .tooltip-arrow {
	bottom: 0;
	left: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #444;
}

.tooltip.right .tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #444;
}

.tooltip.left .tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #444;
}

.tooltip.bottom .tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #444;
}

.tooltip.bottom-left .tooltip-arrow {
	top: 0;
	right: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #444;
}

.tooltip.bottom-right .tooltip-arrow {
	top: 0;
	left: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #444;
}

.clearfix:before, .clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.pull-right {
	float: right !important;
}

.pull-left {
	float: left !important;
}

.hide {
	display: none !important;
}

.show {
	display: block !important;
}

.invisible {
	visibility: hidden;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.hidden {
	display: none !important;
	visibility: hidden !important;
}

.affix {
	position: fixed;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg {
	display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
	display: none !important;
}

@media (max-width: 767px) {
	.visible-xs {
		display: block !important;
	}

	table.visible-xs {
		display: table;
	}

	tr.visible-xs {
		display: table-row !important;
	}

	th.visible-xs,
	td.visible-xs {
		display: table-cell !important;
	}
}

@media (max-width: 767px) {
	.visible-xs-block {
		display: block !important;
	}
}

@media (max-width: 767px) {
	.visible-xs-inline {
		display: inline !important;
	}
}

@media (max-width: 767px) {
	.visible-xs-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm {
		display: block !important;
	}

	table.visible-sm {
		display: table;
	}

	tr.visible-sm {
		display: table-row !important;
	}

	th.visible-sm,
	td.visible-sm {
		display: table-cell !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-block {
		display: block !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-inline {
		display: inline !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md {
		display: block !important;
	}

	table.visible-md {
		display: table;
	}

	tr.visible-md {
		display: table-row !important;
	}

	th.visible-md,
	td.visible-md {
		display: table-cell !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-block {
		display: block !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-inline {
		display: inline !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: 1200px) {
	.visible-lg {
		display: block !important;
	}

	table.visible-lg {
		display: table;
	}

	tr.visible-lg {
		display: table-row !important;
	}

	th.visible-lg,
	td.visible-lg {
		display: table-cell !important;
	}
}

@media (min-width: 1200px) {
	.visible-lg-block {
		display: block !important;
	}
}

@media (min-width: 1200px) {
	.visible-lg-inline {
		display: inline !important;
	}
}

@media (min-width: 1200px) {
	.visible-lg-inline-block {
		display: inline-block !important;
	}
}

@media (max-width: 767px) {
	.hidden-xs {
		display: none !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.hidden-sm {
		display: none !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.hidden-md {
		display: none !important;
	}
}

@media (min-width: 1200px) {
	.hidden-lg {
		display: none !important;
	}
}

.visible-print {
	display: none !important;
}

@media print {
	.visible-print {
		display: block !important;
	}

	table.visible-print {
		display: table;
	}

	tr.visible-print {
		display: table-row !important;
	}

	th.visible-print,
	td.visible-print {
		display: table-cell !important;
	}
}

.visible-print-block {
	display: none !important;
}

@media print {
	.visible-print-block {
		display: block !important;
	}
}

.visible-print-inline {
	display: none !important;
}

@media print {
	.visible-print-inline {
		display: inline !important;
	}
}

.visible-print-inline-block {
	display: none !important;
}

@media print {
	.visible-print-inline-block {
		display: inline-block !important;
	}
}

@media print {
	.hidden-print {
		display: none !important;
	}
}

ul.links {
	padding: 0;
	position: relative;
	margin: 0;
}

ul.links li {
	display: inline;
	line-height: normal;
	position: relative;
}

ul.links li a {
	padding-left: 10px;
	color: #444;
}

ul.links li a i {
	margin-right: 5px;
}

ul.links li a:hover {
	color: #1e487b;
}

ul.list {
	padding: 0;
	position: relative;
	margin: 0;
}

ul.list li {
	list-style: none;
	line-height: 30px;
}

ul.list li a {
	color: #444;
}

ul.list li a:hover {
	color: #1e487b;
}

.tree-menu {
	padding: 0;
}

.tree-menu ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.tree-menu ul li {
	padding: 4px 10px 4px 30px;
	border-top: 1px solid #ebebeb;
}

.tree-menu ul li:hover {
	background-color: #1e487b;
	color: #FFFFFF;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
	position: relative;
}

.tree-menu ul li:hover a {
	color: #FFFFFF;
}

.tree-menu ul li:hover .accordion-heading .fa {
	color: #FFFFFF;
}

.tree-menu ul li:first-child {
	border-top: none;
}

.tree-menu ul li .accordion-heading {
	cursor: pointer;
}

.tree-menu ul li .accordion-heading .fa {
	font-size: 9px;
	color: #101010;
}

.tree-menu ul li.open a, .tree-menu ul li a {
	color: #444;
	font-size: 16px;
}

.tree-menu ul ul {
	padding-left: 20px;
}

.tree-menu ul ul li {
	list-style: none;
	border: none;
	padding: 8px 0;
}

.tree-menu ul ul li:hover:after, .tree-menu ul ul li:hover:before {
	content: none;
}

.tree-menu ul ul li a {
	color: #444;
	font-weight: normal;
}

.tree-menu .vertical .dropdown-submenu:hover > .dropdown-menu {
	display: block;
}

.tree-menu .vertical .dropdown-menu {
	top: 0;
	left: 100%;
	width: 100%;
}

.list-group-item ul {
	padding-left: 20px;
}

.list-group-item ul li {
	list-style: none;
	padding: 8px 0;
}

.list-group-item ul li a {
	color: #bbb;
}

div.required .control-label:before {
	color: #f00;
	content: "* ";
	font-weight: bold;
}

.noborder .box {
	border: none !important;
}

.white {
	color: #FFFFFF;
}

.red {
	color: #f44336;
	line-height: 20px;
}

.table-responsive .btn-primary {
	padding: 0 14px;
}

.table.shopping-cart-table > tbody > tr > td {
	color: #444;
}

/*Text Colors
------------------------------------*/
.text-default {
	color: #222 !important;
}

.text-primary {
	color: #1e487b !important;
}

.text-info {
	color: #03a9f4 !important;
}

.text-danger {
	color: #f6821f !important;
}

.text-warning {
	color: #efc73a !important;
}

.text-success {
	color: #6eae34 !important;
}

/*Background Colors
------------------------------------*/
.bg-primary {
	background-color: #1e487b !important;
}

.bg-info {
	background: #03a9f4 !important;
}

.bg-danger {
	background: #f6821f !important;
}

.bg-warning {
	background: #efc73a !important;
}

.bg-success {
	background: #6eae34 !important;
}

.accordion .panel {
	-webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
}

.accordion .panel-heading {
	padding: 15px;
	position: relative;
}

.accordion .panel-heading a {
	text-decoration: none;
	font-weight: bold;
}

.accordion .panel-heading a:before {
	content: "-";
	font-size: 22px;
	font-weight: 600;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 16px;
	display: inline-block;
	color: #1e487b;
}

.accordion .panel-heading a.collapsed:before {
	content: "+";
	font-size: 18px;
	line-height: 20px;
}

.accordion .panel-body {
	border-top-color: transparent !important;
}

.accordion.collapse-right .panel-heading a:before {
	position: absolute;
	right: 20px;
}

.accordion.collapse-color .panel-heading a:before {
	color: #fff;
	background: #1e487b;
}

.accordion.noborder .panel-heading {
	padding-left: 0;
	padding-right: 0;
}

.accordion.noborder .panel, .accordion.noborder .panel-heading, .accordion.noborder .panel-body {
	background-color: transparent;
	border: none;
}

.accordion.nobackground .panel, .accordion.nobackground .panel-heading, .accordion.nobackground .panel-body {
	background-color: transparent;
}

.breadcrumbs {
	padding: 22px 0;
	margin-bottom: 0;
	list-style: none;
	background-color: #f3f3f3;
	border-radius: 3px;
}

.breadcrumbs .breadcrumb {
	margin: 0;
	padding: 0 2px;
}

.breadcrumbs h2 {
	margin: 0;
	float: left;
	font-weight: 400;
	position: relative;
}

.breadcrumbs .breadcrumb-links {
	margin: 0;
	position: relative;
	padding: 0 0 10px;
	border-bottom: 1px solid #e5e5e5;
}

.breadcrumbs .breadcrumb-links > li {
	display: inline-block;
}

.breadcrumbs .breadcrumb-links > li a {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 12px;
}

.breadcrumbs .breadcrumb-links > li + li:before {
	color: #757575;
	content: "\f105";
	font-family: FontAwesome;
	padding: 0 15px;
	font-size: 14px;
}

.breadcrumbs .breadcrumb-links > li:last-child a {
	color: #444;
}

.breadcrumbs .breadcrumb-links > .active {
	color: #444;
}

.breadcrumbs-left h2 {
	float: none;
	text-align: left;
}

.breadcrumbs-left .breadcrumb-links {
	text-align: left;
}

.heading {
	z-index: 1;
	position: relative;
	text-align: center;
}

.heading h1, .heading h2, .heading h3, .heading h4, .heading h5, .heading h6 {
	text-transform: uppercase;
}

.heading p, .heading small {
	margin-bottom: 15px;
	display: inline-block;
}

.heading.heading-light {
	color: #eee;
}

.heading.heading-light h1, .heading.heading-light h2, .heading.heading-light h3, .heading.heading-light h4, .heading.heading-light h5, .heading.heading-light h6 {
	color: #fff;
}

.heading.heading-light p, .heading.heading-light small {
	color: #eee;
}

.list-unstyled {
	list-style: none;
	padding-left: 0;
}

.buttons {
	padding: 20px 0;
}

.buttons:before, .buttons:after {
	content: " ";
	display: table;
}

.buttons:after {
	clear: both;
}

.buttons .left {
	float: left;
}

.buttons .right {
	float: right;
}

.checkbox, .radio {
	padding-left: 20px;
}

.btn {
	line-height: 36px;
	padding: 0 10px;
	-webkit-transition: all 200ms ease 0.2s;
	-o-transition: all 200ms ease 0.2s;
	transition: all 200ms ease 0.2s;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
}

.btn-outline-inverse {
	color: #777777;
	background-color: #FFFFFF;
	border-color: #f2f2f2;
	border: none;
	border-right: 1px solid #f2f2f2;
}

.btn-outline-inverse:hover, .btn-outline-inverse:focus, .btn-outline-inverse:active, .btn-outline-inverse.active {
	color: #FFFFFF;
	background-color: #6eae34;
	border-color: #f2f2f2;
}

.open .btn-outline-inverse.dropdown-toggle {
	color: #FFFFFF;
	background-color: #6eae34;
	border-color: #f2f2f2;
}

.btn-outline-inverse:active, .btn-outline-inverse.active {
	background-image: none;
}

.open .btn-outline-inverse.dropdown-toggle {
	background-image: none;
}

.btn-outline-inverse.disabled, .btn-outline-inverse.disabled:hover, .btn-outline-inverse.disabled:focus, .btn-outline-inverse.disabled:active, .btn-outline-inverse.disabled.active, .btn-outline-inverse[disabled], .btn-outline-inverse[disabled]:hover, .btn-outline-inverse[disabled]:focus, .btn-outline-inverse[disabled]:active, .btn-outline-inverse[disabled].active, fieldset[disabled] .btn-outline-inverse, fieldset[disabled] .btn-outline-inverse:hover, fieldset[disabled] .btn-outline-inverse:focus, fieldset[disabled] .btn-outline-inverse:active, fieldset[disabled] .btn-outline-inverse.active {
	background-color: #FFFFFF;
	border-color: #f2f2f2;
}

.btn-outline-inverse .badge {
	color: #FFFFFF;
	background-color: #777777;
}

.btn-outline-inverse.last {
	border-right: none;
}

.btn-primary {
	color: #fff;
	background-color: #1e487b;
	border-color: #1e487b;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
	color: #fff;
	background-color: #5c5c5c;
	border-color: #5c5c5c;
}

.open .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #5c5c5c;
	border-color: #5c5c5c;
}

.btn-primary:active, .btn-primary.active {
	background-image: none;
}

.open .btn-primary.dropdown-toggle {
	background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
	background-color: #1e487b;
	border-color: #1e487b;
}

.btn-primary .badge {
	color: #1e487b;
	background-color: #fff;
}

.btn-default {
	color: #fff;
	background-color: #5c5c5c;
	border-color: #5c5c5c;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active {
	color: #fff;
	background-color: #1e487b;
	border-color: #1e487b;
}

.open .btn-default.dropdown-toggle {
	color: #fff;
	background-color: #1e487b;
	border-color: #1e487b;
}

.btn-default:active, .btn-default.active {
	background-image: none;
}

.open .btn-default.dropdown-toggle {
	background-image: none;
}

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default[disabled].active, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
	background-color: #5c5c5c;
	border-color: #5c5c5c;
}

.btn-default .badge {
	color: #5c5c5c;
	background-color: #fff;
}

.btn-white {
	color: #1e487b;
	background-color: #fff;
	border-color: #1e487b;
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active {
	color: #5c5c5c;
	background-color: #fff;
	border-color: #5c5c5c;
}

.open .btn-white.dropdown-toggle {
	color: #5c5c5c;
	background-color: #fff;
	border-color: #5c5c5c;
}

.btn-white:active, .btn-white.active {
	background-image: none;
}

.open .btn-white.dropdown-toggle {
	background-image: none;
}

.btn-white.disabled, .btn-white.disabled:hover, .btn-white.disabled:focus, .btn-white.disabled:active, .btn-white.disabled.active, .btn-white[disabled], .btn-white[disabled]:hover, .btn-white[disabled]:focus, .btn-white[disabled]:active, .btn-white[disabled].active, fieldset[disabled] .btn-white, fieldset[disabled] .btn-white:hover, fieldset[disabled] .btn-white:focus, fieldset[disabled] .btn-white:active, fieldset[disabled] .btn-white.active {
	background-color: #fff;
	border-color: #1e487b;
}

.btn-white .badge {
	color: #fff;
	background-color: #1e487b;
}

.btn-credit {
	margin-left: 15px;
	color: #5c5c5c;
	background-color: #f2f1ef;
	border-color: #f2f1ef;
}

.btn-credit:hover, .btn-credit:focus, .btn-credit:active, .btn-credit.active {
	color: #f2f1ef;
	background-color: #5c5c5c;
	border-color: #5c5c5c;
}

.open .btn-credit.dropdown-toggle {
	color: #f2f1ef;
	background-color: #5c5c5c;
	border-color: #5c5c5c;
}

.btn-credit:active, .btn-credit.active {
	background-image: none;
}

.open .btn-credit.dropdown-toggle {
	background-image: none;
}

.btn-credit.disabled, .btn-credit.disabled:hover, .btn-credit.disabled:focus, .btn-credit.disabled:active, .btn-credit.disabled.active, .btn-credit[disabled], .btn-credit[disabled]:hover, .btn-credit[disabled]:focus, .btn-credit[disabled]:active, .btn-credit[disabled].active, fieldset[disabled] .btn-credit, fieldset[disabled] .btn-credit:hover, fieldset[disabled] .btn-credit:focus, fieldset[disabled] .btn-credit:active, fieldset[disabled] .btn-credit.active {
	background-color: #f2f1ef;
	border-color: #f2f1ef;
}

.btn-credit .badge {
	color: #f2f1ef;
	background-color: #5c5c5c;
}

.btn-outline-success {
	padding: 15px 25px;
	line-height: 13px;
}

.btn-link {
	font-size: 12px;
	text-transform: lowercase;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 2px;
}

html {
	overflow-x: hidden;
}

body.layout-boxed-lg #page {
	max-width: 1220px;
	-webkit-box-shadow: 0 0 5px #e6e6e6;
	-moz-box-shadow: 0 0 5px #e6e6e6;
	-o-box-shadow: 0 0 5px #e6e6e6;
	box-shadow: 0 0 5px #e6e6e6;
	margin: 0 auto;
	background: #FFFFFF;
}

body[class*="page-"] #content {
	padding: 20px 15px;
}

body[class*="page-"] #content img {
	max-width: 100%;
}

body.common-home #content {
	padding: 0;
}

body[class*="page-category"] #content {
	padding: 0;
}

body[class*="page-category"] #content h1 {
	margin-top: 0;
}

body[class*="-account"] #content h1, body[class*="-account"] #content h2 {
	font-size: 19px;
}

body[class*="page-product"] #content {
	overflow: visible;
	padding: 0;
}

body[class*="page-product"] #content .owl-carousel .owl-wrapper-outer {
	overflow: visible;
}

body[class*="page-product"] #content .owl-carousel {
	margin-bottom: 35px;
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

/*
* HEADER
*/
#topbar {
	background: #101010;
	font-size: 13px;
	color: #444;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
}

#topbar .topbar-left {
	position: relative;
}

#topbar .custom-top:nth-child(1) {
	margin-right: 20px;
}

#topbar .custom-top i {
	padding-right: 5px;
}

#topbar .container .inside {
	background: #e9ecf5;
	padding: 10px 10px 10px 20px;
}

.headercart {
	flex: 0 1 330px;
	display: block;
	text-align: right;
}

.headercart > div {
	float: right;
}

.header-bottom {
	background: #fff;
}

.header-bottom > .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 120px;
}

.header-bottom > .container:before, .header-bottom > .container:after {
	content: none;
}

.header-bottom #topcontacts {
	flex: 0 1 330px;
	display: flex;
}

.header-bottom .topcontacts {
	align-self: center;
	display: flex;
	font-size: 13px;
	margin: 5px 0;
}

.header-bottom .topcontacts .media-title {
	font-size: 21px;
	padding: 0 5px 0 0;
}

.header-bottom .topcontacts .media-title em {
	vertical-align: -6px;
}

.header-bottom .topcontacts .media-body {
	max-width: 160px;
}

.header-bottom .topcontacts .media-body span {
	white-space: nowrap;
	margin-right: 10px;
	display: inline-block;
}

.header-bottom .topcontacts .media-body span a {
	color: #444 !important;
}

.header-bottom .logo-container {
	flex: 1 0 auto;
}

.header-bottom .logo-container .logo-store {
	text-align: center;
}

.header-bottom .logo-container img {
	max-width: 260px;
}

.header-bottom .main-menu {
	flex: 1 0 auto;
	align-self: center;
}

.header-bottom .nav-search {
	flex: 0 1 200px;
	align-self: center;
}

.header-menu {
	background-color: #1e487b;
}

.header-menu > .container {
	display: flex;
	justify-content: space-between;
}

.header-menu > .container:before, .header-menu > .container:after {
	content: none;
}

.shopping-cart-table {
	background: #FFFFFF;
}

.shopping-cart-table tbody .column-image {
	position: relative;
}

.shopping-cart-table tbody .column-image img {
	border: none;
}

.shopping-cart-table tbody .column-image .product-label {
	top: 5px;
	left: 5px;
	padding: 0 5px;
	background: #1e487b;
}

.shopping-cart-table tbody .column-image .product-label span {
	color: #fff;
}

.shopping-cart-table > thead:first-child > tr:first-child > td {
	border-top: 1px solid #ededed;
}

.shopping-cart-table.table tr td {
	border: 1px #ededed solid;
}

.shopping-cart-table .btn {
	font-size: 23px;
	padding: 0 7px;
}

.shopping-cart-table .btn i {
	vertical-align: 2px;
}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: #bbb;
	cursor: pointer;
	border-right: 1px solid #ebebeb;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search.open {
	height: 44px;
	top: 0;
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}

/* multi logo */
.logo-store {
	position: relative;
}

.logo-store a {
	vertical-align: middle;
}

#logo-theme a {
	background: url(../image/logo.png) no-repeat;
	display: inline-block;
	width: 170px;
	height: 37px;
}

#logo-theme a span {
	display: none;
}

/*
* CONTENT
 */
#pav-masshead .inner {
	background: #FFFFFF;
	color: #1e487b;
}

#pav-masshead .inner a:hover {
	color: #235490;
}

.breadcrumbs .container {
	background: transparent;
}

/*
* NAVIGATION
*/
#pav-mainnav .inner {
	background-color: #505050;
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: #353536;
	padding: 30px 0;
	color: #FFFFFF;
}

.footer-center .box .box-heading {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 30px;
}

.footer-center .panel {
	background: transparent;
	margin-bottom: 0;
}

.footer-center .panel .panel-heading {
	padding: 0;
}

.footer-center .panel .panel-body {
	padding: 20px 0 0;
}

.footer-center .panel .panel-title {
	color: #FFFFFF;
}

.footer-center .footer-name {
	text-align: center;
	font-size: 12px;
}

.footer-center .main-footer {
	display: flex;
	justify-content: space-between;
}

.footer-bottom {
	background: #607d8b;
}

.footer-bottom .inside {
	background: url("../image/pattern.jpg") repeat 0 0;
	position: relative;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	border-radius: 3px;
}

.footer-bottom .inside:before {
	position: absolute;
	content: "";
	width: 10px;
	height: 10px;
	display: block;
	border-top: 1px solid #FFFFFF;
	border-left: 1px solid #FFFFFF;
	left: 0;
	top: 0;
	z-index: 2;
}

.footer-bottom .inside:after {
	position: absolute;
	content: "";
	width: 10px;
	height: 10px;
	display: block;
	border-bottom: 1px solid #FFFFFF;
	border-right: 1px solid #FFFFFF;
	bottom: 0;
	right: 0;
	z-index: 2;
}

.footer-bottom .inside .bg-blue {
	background: url("../image/pattern2.jpg") repeat 0 0;
}

/* POWER BY */
#powered {
	background: #444;
}

#powered a {
	font-size: 12px;
	color: #ccd6dd;
	font-weight: 500;
	text-transform: uppercase;
}

#powered a:hover {
	color: #FFFFFF;
}

#powered .copyright {
	padding: 5px 0 0 15px;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
}

#powered ul.links {
	line-height: 16px;
	padding-left: 20px;
}

#powered ul.links li {
	padding: 0 10px;
}

#powered .inner {
	display: flex;
	align-items: center;
}

.panel-highlighted {
	border: solid 1px red;
}

.panel-highlighted .panel-heading {
	background: red;
	color: #FFFFFF;
}

.nopadding .panel-body {
	padding: 0;
}

.sidebar .panel {
	margin-bottom: 32px;
}

.nostylingboxs .panel-heading {
	padding: 0;
	margin-bottom: 30px;
}

.nostylingboxs .panel-body {
	padding: 0;
}

.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block .owl-carousel {
	margin: 0;
}

.price {
	font-weight: 400;
	font-family: "Rubik", sans-serif;
}

.price .price-new {
	color: #444;
	font-size: 22px;
}

.price .price-old {
	text-decoration: line-through;
	color: #959595;
	font-size: 18px;
}

.price.detail .price-new {
	font-size: 40px;
}

.price.detail .price-old {
	font-size: 26px;
}

.price.detail ul {
	margin: 0;
}

.cheap_invoice a {
	font-size: 16px;
	margin-bottom: 5px;
	text-decoration: underline dotted;
	display: block;
	color: #444;
}

.cheap_invoice a:hover {
	color: #444;
}

.product-block {
	position: relative;
	background-color: #FFFFFF;
	border-top: 1px solid transparent;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	border-radius: 3px;
}

.product-block .description {
	display: none;
}

.product-block .product-meta {
	line-height: 25px;
	padding: 0;
}

.product-block .bottom {
	display: block;
}

.product-block .price {
	text-align: center;
}

.product-block .name {
	margin: 10px 0;
	font-size: 14px;
	font-family: "Open Sans", sans-serif;
	text-transform: inherit;
	overflow: hidden;
	font-weight: 600;
}

.product-block .name a {
	color: #444;
	padding: 5px 0;
}

.product-block .name a:hover {
	color: #444;
}

.product-block .name a span {
	text-align: center;
	display: block;
}

.product-block .name a span.title1 {
	font-weight: 400;
}

.product-block .name a span.title2 {
	font-family: "Rubik", sans-serif;
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
}

.product-block .name .rating {
	font-size: 14px;
	margin-top: 7px;
	text-align: center;
}

.product-block .image {
	position: relative;
	overflow: hidden;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	padding: 10px 10px 0 10px;
}

.product-block .top {
	position: relative;
	padding: 0 20px 10px 20px;
}

.cart {
	position: relative;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	padding-bottom: 15px;
}

.cart .icon-cart {
	display: block;
	width: 16px;
	height: 17px;
}

.product-label {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	z-index: 99;
	position: absolute;
	text-align: center;
	border: 1px solid #1e487b;
	line-height: 17px;
	padding: 5px 15px;
	top: 7px;
}

.product-label.sale {
	background-color: #1e487b;
	left: 7px;
	color: #fff;
}

.product-label.new {
	right: 7px;
	background-color: #fff;
	color: #1e487b;
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.product-info .tab-content {
	border: none;
	padding: 0;
	margin-bottom: 30px;
}

.product-info .tab-content .desc-header-wrapper {
	border: 1px #1e487b solid;
	margin-top: 30px;
	margin-bottom: 15px;
}

.product-info .tab-content .desc-header-wrapper .desc-header {
	padding: 15px;
	color: #1e487b;
	text-transform: uppercase;
	font-size: 18px;
	position: relative;
	top: 1px;
	display: inline-block;
}

.product-info .tab-content #tab-description {
	padding: 0 15px;
}

.product-info .tab-content #tab-specification td {
	padding: 10px 15px !important;
	color: #444 !important;
}

.product-info .tab-content .owl-related .owl-next, .product-info .tab-content .owl-related .owl-prev {
	top: -57px;
}

.product-info .tab-content .owl-related .owl-next {
	right: 18px;
}

.product-info .tab-content .owl-related .owl-prev {
	right: 48px;
}

.product-info .tab-content .owl-related .owl-stage {
	padding: 0;
}

.product-info .tab-content .owl-related .product-block {
	width: 100%;
	margin: 0;
}

.product-info .tab-content .owl-related .product-block .image {
	border: none;
}

.product-info .tab-content .widget-products {
	margin: 0 -2px;
}

.product-info .tab-content .scroll-button {
	margin-bottom: 5px;
}

.product-info .tab-content .product-grid.no-scroll .product-block {
	margin: 4px 2px;
	width: calc(100% / 4 - 7px);
}

.product-info .tab-content .product-grid.no-scroll .product-block .image {
	border: 0;
}

.cheap_invoice {
	margin-bottom: 15px;
}

.storage_widget {
	border-top-color: #c4c4c4;
}

.storage_widget > span {
	color: #1e487b !important;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.storage_widget table tr td:last-child span {
	background: #1e487b !important;
	border-radius: 0 !important;
}

.storage_widget table tr td:last-child span:hover {
	background: #444 !important;
}

.banner-wrapper-margin .banner-wrapper {
	margin-bottom: 20px;
}

.image-item-margin .image-item {
	margin-bottom: 20px;
}

.nomargin .box {
	margin-bottom: 0;
}

/* slidelayer */
.layerslider-wrapper {
	overflow: hidden;
}

.layerslider-wrapper:hover .tp-rightarrow {
	right: 20px !important;
}

.layerslider-wrapper:hover .tp-leftarrow {
	left: 20px !important;
}

.tparrows {
	position: absolute;
	top: 50% !important;
	z-index: 9999;
	cursor: pointer;
	display: block;
	width: 45px;
	height: 45px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	font-size: 0;
}

.tparrows:before {
	content: "";
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	z-index: 12;
	width: 40px;
	height: 35px;
	-webkit-transition: all 0.35s ease 0s;
	-o-transition: all 0.35s ease 0s;
	transition: all 0.35s ease 0s;
	display: block;
}

.tparrows.tp-rightarrow:before {
	background-position: -50px -2px;
}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
	background-color: #1e487b;
}

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.navbar .bullet:hover,
.tp-bullets.simplebullets.navbar .bullet.selected {
	background-color: #1e487b;
}

.effect-carousel-v2 .carousel-controls-v1 {
	position: absolute;
	right: 0;
}

.effect-carousel-v2 .carousel-controls-v1 .carousel-control {
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all 0.5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
	transition: all 0.5s ease-in-out 0s;
	top: -20px;
}

.effect-carousel-v2 .carousel-controls-v1 .carousel-control.left {
	left: -63px;
}

.effect-carousel-v2:hover .carousel-control {
	opacity: 1;
	filter: alpha(opacity=100);
	top: 2px;
}

.effect-carousel .carousel-control {
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all 0.5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
	transition: all 0.5s ease-in-out 0s;
}

.effect-carousel .carousel-control.left {
	left: -45px;
}

.effect-carousel .carousel-control.right {
	right: -45px;
}

.effect-carousel:hover .carousel-control {
	opacity: 1;
	filter: alpha(opacity=100);
}

.effect-carousel:hover .carousel-control.left {
	left: 0;
}

.effect-carousel:hover .carousel-control.right {
	right: 0;
}

/* tablist-v1 */
.tablist-v1 .product-grid .products-row .product-col:last-child {
	border-right: 1px solid #ebebeb;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	border-radius: 3px;
}

.featured-category .panel-body {
	overflow: hidden;
	padding: 0;
}

.featured-category ul {
	margin: 0;
}

.featured-category ul li {
	display: inline-block;
	width: 33%;
	position: relative;
}

.featured-category ul li:before {
	position: absolute;
	right: 0;
	background: #ebebeb;
	content: "";
	width: 1px;
	height: 200%;
	top: -20px;
}

.featured-category ul li:after {
	position: absolute;
	left: 25px;
	background: #ebebeb;
	content: "";
	width: 87%;
	height: 1px;
	top: 0;
}

.featured-category ul li:nth-child(1):after, .featured-category ul li:nth-child(2):after, .featured-category ul li:nth-child(3):after {
	content: none;
}

.featured-category ul li:nth-child(3n):before {
	content: none;
}

.featured-category ul li .image {
	margin: 5px 0 5px 25px;
}

.featured-category ul li .caption {
	overflow: hidden;
	padding: 30px 20px;
}

.featured-category ul li .caption h6 {
	font-weight: normal;
	font-size: 16px;
}

.featured-category ul li .caption h6 a {
	color: #444;
}

.pav-megamenu {
	/* level 2 */
}

.pav-megamenu .navbar {
	margin: 0;
	border: 0;
}

.pav-megamenu .navbar-collapse {
	padding: 0;
}

.pav-megamenu .navbar-nav > li {
	height: 50px;
	border-left: 1px #fff solid;
}

.pav-megamenu .navbar-nav > li:last-child {
	border-right: 1px #fff solid;
}

.pav-megamenu .navbar-nav > li.parent a {
	padding-right: 28px;
}

.pav-megamenu .navbar-nav > li.aligned-left .dropdown-menu {
	left: -1px;
	right: auto;
}

.pav-megamenu .navbar-nav > li.aligned-left .dropdown-submenu .dropdown-menu {
	left: 100%;
}

.pav-megamenu .navbar-nav > li > .dropdown-menu {
	top: 100%;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	-o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	display: block;
	position: absolute;
	visibility: hidden;
}

.pav-megamenu .navbar-nav > li:hover > .dropdown-menu, .pav-megamenu .navbar-nav > li:focus > .dropdown-menu {
	opacity: 1;
	filter: alpha(opacity=100);
	visibility: visible;
	display: block;
	top: 100%;
}

.pav-megamenu .navbar-nav > li > a {
	padding: 14px 18px;
	font-size: 16px;
	font-weight: 400;
	font-family: "Rubik", sans-serif;
	text-transform: uppercase;
	color: #f2f1ef;
	background-color: transparent !important;
	-webkit-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
	line-height: 22px;
}

.pav-megamenu .navbar-nav > li > a:hover, .pav-megamenu .navbar-nav > li > a:focus, .pav-megamenu .navbar-nav > li > a:active {
	color: #FFFFFF;
}

.pav-megamenu .navbar-nav > li > a:hover .caret:before, .pav-megamenu .navbar-nav > li > a:focus .caret:before, .pav-megamenu .navbar-nav > li > a:active .caret:before {
	color: #FFFFFF !important;
}

.pav-megamenu .navbar-nav > li > a .caret {
	border: 0;
	position: relative;
}

.pav-megamenu .navbar-nav > li > a .caret:before {
	-webkit-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
	content: '\f110';
	font-family: "FontAwesome";
	position: absolute;
	right: -10px;
	top: -12px;
	font-size: 13px;
	font-weight: 100;
	color: #f2f1ef;
}

.pav-megamenu .navbar-nav > li.full-width {
	position: static;
}

.pav-megamenu .dropdown-menu {
	top: 100%;
	left: -9999px;
	height: auto;
	color: #444;
	border: 1px #fff solid;
	min-width: 185px;
	padding: 0;
	background-color: #FFFFFF;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
	-webkit-transition: opacity 0.3s linear 0s;
	-o-transition: opacity 0.3s linear 0s;
	transition: opacity 0.3s linear 0s;
	z-index: 9999;
}

.pav-megamenu .dropdown-menu ul, .pav-megamenu .dropdown-menu ol {
	padding: 0;
}

.pav-megamenu .dropdown-menu li {
	padding: 0;
	line-height: 20px;
	list-style: none;
	position: relative;
}

.pav-megamenu .dropdown-menu li a {
	padding: 12px 20px;
	display: inline-block;
	width: 100%;
	color: #444;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
}

.pav-megamenu .dropdown-menu li a:hover {
	background-color: transparent;
}

.pav-megamenu .dropdown-menu li.haschildren:after {
	position: absolute;
	content: '\f112';
	font-family: "FontAwesome";
	font-size: 15px;
	width: 10px;
	height: 17px;
	right: 8px;
	top: 12px;
}

.pav-megamenu .dropdown-menu li.haschildren:hover:after {
	background-position: center -17px;
}

.pav-megamenu .dropdown-menu li > ul.children {
	top: 5px;
	left: 285px;
	right: -250px;
	position: absolute;
	background: #FFFFFF;
	display: none;
	z-index: 99;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.pav-megamenu .dropdown-menu li > ul.children > li a {
	padding: 8px 12px;
	color: #444;
	border-bottom: 1px #ebebeb solid;
}

.pav-megamenu .dropdown-menu li > ul.children > li:hover {
	background: #1e487b;
}

.pav-megamenu .dropdown-menu li > ul.children > li:hover a {
	color: #FFFFFF;
}

.pav-megamenu .dropdown-menu li > ul.children > li:last-child {
	border: none;
}

.pav-megamenu .dropdown-menu li:hover > ul.children {
	left: 100%;
	display: block;
	top: 0;
}

.pav-megamenu .dropdown:hover > .dropdown-menu {
	display: block;
	left: 0;
}

.pav-megamenu .dropdown:hover > a .fa {
	color: #444;
}

.pav-megamenu .mega-group > .dropdown-toggle {
	border: 0;
	display: block;
	letter-spacing: 3px;
	text-transform: uppercase;
	font-family: "Open Sans", sans-serif, sans-serif;
	color: #FFFFFF;
}

.pav-megamenu .mega-group > .dropdown-toggle .fa {
	color: #FFFFFF;
}

.pav-megamenu .widget-heading {
	font-size: 16px;
	margin: 0 0 20px;
}

.pav-megamenu .widget-images {
	margin-bottom: 20px;
	padding-top: 5px;
}

.pav-megamenu .megamenu .cols1 {
	min-width: 200px;
}

.pav-megamenu .megamenu .cols2 {
	min-width: 500px;
}

.pav-megamenu .megamenu .cols3 {
	min-width: 740px;
}

.pav-megamenu .sidebar .product-block {
	padding: 5px 0;
	border-bottom: none;
}

.pav-megamenu .sidebar .product-block .image {
	padding: 0;
}

.ocwall {
	background: rgba(30, 72, 123, 0.85);
	position: absolute;
	width: 100%;
	height: 100%;
	right: -100%;
	top: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.2s ease-in-out;
	-o-transition: opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
}

.ocwall .menutoggle {
	position: fixed;
	width: 50px;
	height: 50px;
	background: url("/image/i/closew.png") center center no-repeat;
	background-size: cover;
	top: calc(50% - 25px);
	left: calc(85vw - 25px);
}

.row-offcanvas.active .ocwall {
	opacity: 1;
	visibility: visible;
	z-index: 9999;
}

body .owl-carousel {
	background: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
}

.filter .panel-body {
	padding: 0;
}

.filter h6 {
	font-size: 16px;
	font-family: "Open Sans", sans-serif, sans-serif;
	font-weight: 400;
	display: inline-block;
	vertical-align: top;
	margin: 0;
}

.filter .list-group-item {
	position: relative;
	padding: 25px;
}

.filter .list-group-item:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: #ebebeb;
}

.filter .list-group-item:first-child:before {
	content: none;
}

.filter .list-group-item .icon-v3 {
	width: 25px;
	height: 25px;
	display: inline-block;
	background: url("../image/icon-3.png") no-repeat;
	margin-right: 5px;
}

.filter .list-group-item:nth-child(2) .icon-v3 {
	background-position: 0 -43px;
}

.filter .list-group-item:nth-child(3) .icon-v3 {
	background-position: 0 -85px;
}

/* search */

#cart {
	height: 100%;
	display: inline-block;
	width: auto;
}

#cart.open .dropdown-toggle {
	box-shadow: none;
}

#cart a {
	display: inline-block;
	position: relative;
	text-decoration: none;
}

#cart .icon-cart {
	background: transparent;
	display: block;
	position: relative;
	text-align: center;
	padding: 0 15px;
	height: 31px;
}

#cart .icon-cart i {
	font-size: 36px;
	line-height: 28px;
}

#cart .wrap-cart {
	color: #444;
	font-size: 13px;
}

#cart .wrap-cart span {
	position: relative;
}

#cart .wrap-cart .badge {
	position: absolute !important;
	top: 8px !important;
	right: 13px;
	padding: 3px;
	min-width: 17px;
	line-height: 11px;
	z-index: 99;
	background: #1e487b;
}

#cart .price-cart {
	color: #1e487b;
}

#cart .cart-inner {
	text-align: right;
}

.product-compare-header a {
	color: #444;
}

.product-compare-header a div {
	display: inline-block;
	padding-left: 5px;
}

.product-compare-header p {
	margin: 0;
}

.product-compare-header p#compare-total {
	line-height: 19px;
	font-size: 13px;
}

.product-compare-header i {
	font-size: 32px;
	line-height: 31px;
	display: block;
}

.product-compare-header .badge {
	color: #FFFFFF;
	position: absolute !important;
	top: 8px !important;
	right: 10px;
	padding: 3px;
	min-width: 17px;
	line-height: 11px;
	z-index: 99;
	background: #1e487b;
}

/* bestseller */
.bestseller.panel-warning .products-block {
	border: 2px solid #efc73a;
	border-top: none;
}

.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
}

.category-info .image {
	padding: 0;
	margin-bottom: 19px;
}

.filter-right > div {
	display: flex;
	align-items: center;
}

.filter-right .sort {
	margin-left: 15px;
}

.filter-right label {
	line-height: 14px;
}

.refine-search {
	margin-top: 15px;
}

.refine-search ul, .refine-search ol {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
}

.refine-search ul li, .refine-search ol li {
	padding: 10px 0;
	margin: 0 10px;
	line-height: normal;
	position: relative;
	width: calc(25% - 20px);
}

.refine-search ul li a, .refine-search ol li a {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	color: #444;
	align-items: center;
	background: no-repeat transparent top center;
	background-size: 90%;
	padding-top: 150px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.refine-search ul li a:hover, .refine-search ol li a:hover {
	text-decoration: none;
}

.refine-search ul li a:hover span, .refine-search ol li a:hover span {
	color: #fff;
	background: rgba(30, 72, 123, 0.7);
}

.refine-search ul li a span, .refine-search ol li a span {
	width: 100%;
	padding: 10px 5px;
	text-align: center;
	font-weight: 600;
	color: #1e487b;
	background: rgba(255, 255, 255, 0.7);
	line-height: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}

.refine-search ul li a span figure, .refine-search ol li a span figure {
	text-align: center;
}

@media (max-width: 767px) {
	.refine-search ul li, .refine-search ol li {
		width: calc((100% / 3) - 20px);
	}
}

@media (max-width: 500px) {
	.refine-search ul li, .refine-search ol li {
		width: calc((100% / 2) - 20px);
	}
}

@media (max-width: 400px) {
	.refine-search ul li, .refine-search ol li {
		font-size: 12px;
	}
}

.refine-search h3 {
	color: #474747;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}

.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
	width: calc(20% - 20px);
}

.style-panel .panel {
	margin: 15px 0;
	border: 1px solid #ddd;
}

.sidebar .products-block {
	border: 1px solid #ebebeb;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	border-radius: 3px;
}

.sidebar .product-label {
	display: none;
}

.sidebar .product-col {
	width: 100%;
	clear: both;
}

.sidebar .products-row:last-child .product-block {
	border-bottom: none;
}

.sidebar .price .price-old {
	font-size: 13px;
}

.sidebar .price .price-new {
	font-size: 14px;
}

.sidebar .product-block {
	overflow: hidden;
	padding: 15px;
	border-bottom: 1px solid #ebebeb;
}

.sidebar .product-block .image {
	padding: 0;
}

.sidebar .product-block .block-img {
	float: left;
	margin-right: 10px;
}

.sidebar .product-block .top {
	padding: 0;
}

.sidebar .product-block .product-meta {
	overflow: hidden;
	text-align: left;
	padding: 0;
}

.sidebar .product-block .name {
	padding-top: 0;
	margin-bottom: 0;
}

.sidebar .bottom {
	display: none;
}

.sidebar .product-grid .product-block {
	padding: 15px;
}

.sidebar .product-grid .product-block:hover .cart, .sidebar .product-grid .product-block:hover .top {
	margin: 0;
}

.sidebar .product-grid .product-block:hover .image {
	opacity: 1;
	filter: alpha(opacity=100);
}

.product-info .product-meta .btn-sm, .product-info .product-meta .btn-group-sm > .btn, .product-info .product-meta .btn-group-sm > .btn {
	padding: 0 15px;
}

.subcategories {
	margin-bottom: -1px;
	background-color: #777777;
}

/*
 *  LISTING PRODUCTS PAGE
 */
.product-filter {
	color: #101010;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	margin: 10px 0;
}

.product-filter span {
	margin-right: 5px;
}

.product-filter .filter-right > div {
	padding-top: 3px;
	padding-left: 5px;
}

.product-filter .display {
	float: left;
}

.product-filter .form-control {
	display: inline-block;
	padding: 0 20px;
	margin: 0 5px;
	width: auto;
	height: 36px;
	text-transform: capitalize;
}

.product-list .description {
	display: block;
}

.product-list .product-meta {
	padding-left: 0;
}

.product-list .product-block {
	display: flex;
	border: 1px solid #eee;
	margin: 10px 0;
	border-radius: 0;
}

.product-list .product-block .name {
	font-weight: 600;
	font-size: 18px;
	height: auto !important;
}

.product-list .product-block .name a span {
	display: inline-block;
}

.product-list .product-block .price {
	margin-bottom: 6px;
	text-align: left;
}

.product-list .product-block .price .price-new {
	font-size: 26px;
}

.product-list .product-block .block-img {
	width: 30%;
}

.product-list .product-block .bottom {
	display: flex;
	padding: 0 20px;
}

.product-list .product-block .bottom .cart {
	display: inline-block;
}

.product-list .product-block .bottom .cart button {
	padding: 0 30px;
}

.product-list .product-block .bottom .quickview a {
	left: -1px;
	position: relative;
}

#products .products-block {
	margin: 0 -17px;
}

.product-grid.product-related .products-block, .products-owl-carousel.product-related .products-block {
	margin: 5px;
}

.product-grid.product-related .product-block, .products-owl-carousel.product-related .product-block {
	width: calc(100% / 4 - 13px);
}

.product-grid .product-block, .products-owl-carousel .product-block {
	display: inline-block;
	margin: 15px;
	padding-bottom: 15px;
	width: calc(100% / 3 - 33px);
	border: 1px solid #f2f1ef;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
	-webkit-transition: border 0.2s ease-in-out;
	-o-transition: border 0.2s ease-in-out;
	transition: border 0.2s ease-in-out;
}

.product-grid .product-block .cart, .products-owl-carousel .product-block .cart {
	padding: 0;
	flex: 1 1 auto;
}

.product-grid .product-block .cart .btn, .products-owl-carousel .product-block .cart .btn {
	width: 100%;
}

.product-grid .product-block .compare, .products-owl-carousel .product-block .compare {
	flex: 0 0 36px;
}

.product-grid .product-block .compare i, .products-owl-carousel .product-block .compare i {
	font-size: 22px;
	vertical-align: -2px;
}

.product-grid .product-block .quickview, .products-owl-carousel .product-block .quickview {
	flex: 0 0 36px;
	margin-left: -1px;
}

.product-grid .product-block .quickview i, .products-owl-carousel .product-block .quickview i {
	font-size: 22px;
	vertical-align: -2px;
}

.product-grid .product-block .compare a, .product-grid .product-block .compare button, .product-grid .product-block .quickview a, .product-grid .product-block .quickview button, .products-owl-carousel .product-block .compare a, .products-owl-carousel .product-block .compare button, .products-owl-carousel .product-block .quickview a, .products-owl-carousel .product-block .quickview button {
	width: 100%;
}

.product-grid .product-block .bottom, .products-owl-carousel .product-block .bottom {
	-webkit-transition: all 400ms ease 0s;
	-o-transition: all 400ms ease 0s;
	transition: all 400ms ease 0s;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	background-color: #FFFFFF;
	text-align: center;
	display: flex;
	padding: 0 20px;
}

.product-grid .product-block:hover, .products-owl-carousel .product-block:hover {
	border-color: #c4c4c4;
}

.products-owl-carousel .owl-stage-outer {
	padding: 15px 0;
}

.products-owl-carousel .product-block {
	width: auto;
}

/*
* PRODUCT DEALS
*/
.productdeals .widget-inner .carousel {
	position: static;
}

/*
* PRODUCT INFORMATION PAGE
*/
.product-info .box-product-infomation {
	margin-top: 20px;
}

.product-info .title-product {
	font-size: 30px;
	margin-top: 0;
	font-weight: 600;
}

.product-info .image-additional {
	overflow: hidden;
}

.product-info .image-additional .owl-carousel {
	margin-bottom: 0 !important;
}

.product-info .image-additional .item {
	border: 1px solid #ebebeb;
	text-align: center;
	margin: 0 2px;
	background-color: #FFFFFF;
	position: relative;
}

.product-info .image-additional .item a.active:before {
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: #1e487b;
	content: "";
	left: 0;
	z-index: 1;
	top: -1px;
}

.product-info .image-additional .item a.active:after {
	position: absolute;
	left: 50%;
	margin-left: -8px;
	border: 6px solid transparent;
	border-bottom: 6px solid #1e487b;
	content: "";
	z-index: 1;
	top: -12px;
}

.product-info .thumbs-preview {
	margin-top: 20px;
}

.product-info .thumbs-preview a + a {
	margin-left: 10px;
}

.product-info .vertical.image-additional .carousel-control {
	display: none;
}

.product-info .vertical:hover .carousel-control {
	display: block;
}

.product-info .vertical .center {
	left: 0;
	right: 0;
	margin: 0 auto;
}

.product-info .vertical .top {
	top: 0;
	bottom: auto;
}

.product-info .vertical .bottom {
	top: auto;
	bottom: 0;
}

.product-info .horical {
	display: block;
	width: 100%;
	text-align: center;
	position: relative;
}

.product-info .horical:hover .carousel-control {
	display: block;
}

.product-info .check-box {
	padding-right: 5px;
	font-size: 10px;
}

.product-info .image {
	position: relative;
	text-align: center;
	overflow: hidden;
	border: 1px solid #ebebeb;
}

.product-info .share {
	padding: 20px 0;
	display: none;
}

.product-info .share:before, .product-info .share:after {
	content: " ";
	display: table;
}

.product-info .share:after {
	clear: both;
}

.product-info .wrap-qty {
	border: 1px solid #ebebeb;
	height: 32px;
	background-color: #FFFFFF;
	padding: 3px;
}

.product-info .wrap-qty > div {
	display: table-cell;
	float: left;
}

.product-info .quantity {
	padding-bottom: 15px;
}

.product-info .quantity-adder {
	display: block;
	position: relative;
	background-color: #FFFFFF;
	border: 1px solid #ebebeb;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
}

.product-info .quantity-adder + .btn-sm, .product-info .btn-group-sm > .quantity-adder + .btn {
	padding: 0 18px;
}

.product-info .quantity-adder .add-action {
	width: 40px;
	height: 35px;
	color: #444;
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 8px;
	line-height: 39px;
	text-align: center;
}

.product-info .quantity-adder .add-action:hover, .product-info .quantity-adder .add-action:focus {
	color: #1e487b;
}

.product-info .quantity-adder .quantity-number input {
	width: 40px;
	height: 35px;
	text-align: center;
	display: inline-block;
	*display: inline;
	zoom: 1;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	border: none;
	color: #444;
	font-size: 16px;
}

.product-info .quantity-adder .minimum {
	line-height: 35px;
	font-size: 11px;
	margin-left: 10px;
}

.product-info .product-meta a {
	font-weight: 500;
}

.product-info .product-meta a a.btn-sm, .product-info .product-meta a .btn-group-sm > a.btn {
	padding: 0 19px;
}

.product-info .product-meta a.btn-primary {
	margin-left: 16px;
	font-size: 20px;
	line-height: 33px;
	padding: 0 10px;
}

.product-info .product-meta .cart {
	padding-bottom: 0;
	font-size: 0;
}

.product-info .product-meta .btn-compare {
	color: #444;
	font-size: 24px;
	padding: 2px 18px;
	height: 41px;
}

.product-info .product-meta .btn-compare:hover {
	color: #1e487b;
}

.product-info .advantages_block {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 10px;
}

.product-info .advantages_block .advantage_item {
	width: 50%;
	min-width: 262px;
	display: flex;
	align-items: center;
}

.product-info .advantages_block .advantage_item div {
	width: 80px;
	height: 80px;
	margin: 10px;
}

.product-info .advantages_block .advantage_item span {
	width: calc(100% - 100px);
	margin: 10px;
}

@media (max-width: 767px) {
	.product-info .advantages_block {
		display: none;
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.h1_container {
	padding: 0 15px;
}

.h1_container:before {
	content: '';
	display: inline-block;
	height: 2px;
	width: 30px;
	background: #ed1c24;
	position: relative;
	margin: 42px 10px 10px 0;
	float: left;
}

.h1_container h1 {
	margin: 0 auto;
	text-transform: uppercase;
	padding: 25px 0 10px 0;
	font-size: 30px;
	display: inline-block;
	width: calc(100% - 40px);
}

.horital {
	position: relative;
}

.owl-carousel .owl-wrapper-outer {
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
}

.related .owl-item .product-col {
	border-left: 1px solid #ebebeb;
}

.related .owl-item:first-child .product-col {
	border-left: 1px solid transparent;
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}

/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: 992px) {
	.row-offcanvas {
		position: relative;
		-webkit-transition: all 0.25s ease-out;
		-o-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;
	}

	.row-offcanvas-right {
		right: 0;
	}

	.row-offcanvas-left {
		left: 0;
	}

	.row-offcanvas-right
	.sidebar-offcanvas {
		right: -70%;
	}

	.row-offcanvas-left
	.sidebar-offcanvas {
		left: -70%;
	}

	.row-offcanvas-right.active {
		right: 70%;
	}

	.row-offcanvas-left.active {
		left: 70%;
	}

	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		width: 70%;
		height: 100%;
	}
	.sidebar-offcanvas ul, .sidebar-offcanvas ol {
		padding: 0;
	}
	.sidebar-offcanvas ul li, .sidebar-offcanvas ol li {
		list-style: none;
	}

	.bottom-offcanvas {
		border-top: 1px solid #e5e5e5;
		padding: 10px 0;
	}
}

/*offcanvas-menu*/
#offcanvasmenu .nav .caret {
	border-bottom-color: #FFFFFF;
	border-top-color: #FFFFFF;
	margin-left: 5px;
	display: none !important;
}

#offcanvasmenu .pavo-widget .widget-heading {
	margin-top: 15px;
}

#offcanvasmenu li .fa {
	color: #444;
	font-size: 22px;
	line-height: 1;
}

#offcanvasmenu li .fa-minus-square::before {
	content: "\f28e";
}

#offcanvasmenu li .fa-plus-square::before {
	content: "\f2c2";
}

#offcanvasmenu .navbar-nav {
	float: none;
	margin: 0;
}

#offcanvasmenu .navbar-nav > li {
	float: none;
	background-color: #FFFFFF;
	border-right: none;
}

#offcanvasmenu .navbar-nav > li:hover:after {
	display: none;
}

#offcanvasmenu .navbar-nav > li .click-canavs-menu {
	cursor: pointer;
	display: inline-block;
	padding: 11px 10px;
	position: absolute;
	right: 0;
	z-index: 1;
}

#offcanvasmenu .navbar-nav > li li.dropdown-submenu .click-canavs-menu {
	padding: 10px 25px;
}

#offcanvasmenu .navbar-nav > li li.dropdown-submenu li.dropdown-submenu > .click-canavs-menu {
	padding: 10px 15px;
}

#offcanvasmenu .navbar-nav > li > a {
	font-weight: 600;
	font-size: 16px;
	display: block;
	-webkit-box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
	-o-box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	color: #444;
	padding-left: 15px;
}

#offcanvasmenu .dropdown-menu {
	border: 0 none;
	border-radius: 0;
	box-shadow: none;
	display: none;
	float: none;
	margin: 0;
	position: static;
	visibility: visible;
	width: auto !important;
	min-width: 160px;
	opacity: 1;
	filter: alpha(opacity=100);
}

#offcanvasmenu .dropdown-menu .mega-col {
	padding: 0;
}

#offcanvasmenu .dropdown-menu .row {
	margin: 0;
}

#offcanvasmenu .dropdown-menu a {
	font-size: 13px;
	padding: 5px 0;
	display: block;
	color: #444;
}

#offcanvasmenu .dropdown-menu a:hover {
	color: #1e487b;
}

#offcanvasmenu .w-product {
	padding: 0;
}

#offcanvasmenu .product-block {
	border: none;
	padding: 0;
}

#offcanvasmenu .product-block .image {
	float: none;
	margin: 0;
}

#offcanvasmenu .cal_all li {
	padding: 5px 20px;
}

.canvas-menu {
	margin: 0;
	background: transparent;
	color: #1e487b;
	font-size: 28px;
	padding: 0 10px;
	border: none;
}

.col-nopadding .row {
	margin-left: 0;
	margin-right: 0;
}

.col-nopadding .row [class*="col-"] {
	padding-left: 0;
	padding-right: 0;
}

.no-padding {
	padding: 0;
}

.no-margin {
	margin: 0;
}

.nopadding [class*="col-"], .nopadding [class^="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.arrow {
	width: 20px;
	height: 20px;
	line-height: 20px;
	color: #343434;
	font-size: 19px;
	background: #FFFFFF;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
}

.address li span, .address li a {
	overflow: hidden;
	display: block;
}

.address li .icon {
	width: 16px;
	height: 20px;
	background: url(../image/icon-address.png) 0 0 no-repeat;
	margin-right: 10px;
}

.address li .icon.icon-phone {
	background-position: 0 -19px;
}

.address li .icon.icon-mail {
	background-position: 0 -43px;
}

.over-hidden {
	overflow: hidden;
}

.over-hidden .col-inner {
	overflow: hidden;
}

.over-hidden .owl-carousel .owl-wrapper-outer {
	overflow: visible;
}

/* back top top */
.scrollup {
	background: #1e487b !important;
	bottom: 82px;
	position: fixed;
	right: 10px;
	z-index: 9999;
}

.vertical .carousel-inner {
	height: 100%;
}

.carousel.vertical .item {
	-webkit-transition: 0.6s ease-in-out top;
	-o-transition: 0.6s ease-in-out top;
	transition: 0.6s ease-in-out top;
}

.carousel.vertical .active {
	top: 0;
}

.carousel.vertical .next {
	top: 400px;
}

.carousel.vertical .prev {
	top: -400px;
}

.carousel.vertical .next.left,
.carousel.vertical .prev.right {
	top: 0;
}

.carousel.vertical .active.left {
	top: -400px;
}

.carousel.vertical .active.right {
	top: 400px;
}

.carousel.vertical .item {
	left: 0;
}

/*  */

.sphinxsearch > a {
	cursor: pointer;
	display: block;
	padding: 5px;
	color: initial;
	line-height: 17px;
	min-height: 85px;
	position: relative;
}

.sphinxsearch > a:first-child {
	border-top: none;
}

.sphinxsearch > a:last-child {
	border-bottom: none;
}

.sphinxsearch > a img {
	float: left;
	margin: 0 10px 0 0;
	padding: 5px;
}

.sphinxsearch .categories span, .sphinxsearch .products span {
	display: block;
	padding: 10px 0;
	font-size: 14px;
	text-align: center;
	background: #eee;
}

.sphinxsearch .categories a {
	text-transform: uppercase;
	text-indent: 10px;
}

.sphinx-viewall {
	font-weight: bold;
	text-align: center;
	padding: 15px 0;
	height: auto;
}

.sphinx-viewall:hover {
	background: #FFFFFF;
}

.sphinx-viewall-no-res {
	font-weight: bold;
	text-align: center;
	height: 30px;
	color: #444;
	width: 100%;
	line-height: 30px;
}

#input-search + .sphinxsearch {
	left: 15px;
}

@media (max-width: 430px) {
	.sphinxsearch {
		width: calc(100vw - 30px);
	}
}

button:active, button:focus {
	outline: none !important;
}

#page {
	min-height: 100vh;
}

#page > .container {
	min-height: calc(100vh - 448px);
}

.page-themecontrol-product #page {
	min-height: 1px;
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

.product-info {
	margin-top: 24px;
}

#tab-description img {
	height: auto;
}

#tab-downloads ul li {
	list-style: none;
}

#mainmenutop .nav.navbar {
	height: 60px;
}

#mainmenutop .panel-v4 .panel-body {
	border: none !important;
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #1e487b;
	top: 0;
	position: absolute;
}

.contact-icon i.fa {
	color: #FFFFFF;
	font-size: 30px;
	padding: 11px 10px 10px 10px;
	width: 50px;
	height: 50px;
	text-align: center;
	margin-top: -1px;
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
}

.contact-text div.contact-text-b-days div {
	display: inline-block;
}

.contact-text div.contact-text-b-days div:first-child {
	width: 170px;
}

.search_block {
	overflow: visible;
}

.search_block form {
	margin: 0;
}

.search_block form button {
	border: 1px solid #fff;
	padding: 0 8px;
	height: 36px;
}

.search_block form button:focus, .search_block form button:active {
	outline: none !important;
}

.search_block form button i {
	font-size: 23px;
	vertical-align: -2px;
}

.search_block .dropdown-menu.autosearch {
	margin: 0;
	width: 350px;
	min-width: 0;
	padding: 0;
	right: 0;
	left: auto;
	display: none;
	border-radius: 0;
	top: 100%;
}

.search_block .dropdown-menu.autosearch li {
	-webkit-transition: 0.2s all;
	-o-transition: 0.2s all;
	transition: 0.2s all;
	display: inline-block;
	width: 100%;
	margin-bottom: 5px;
	cursor: pointer;
	line-height: 1;
	position: relative;
	border-bottom: 1px #ebebeb solid;
}

.search_block .dropdown-menu.autosearch li:nth-last-child(-n+2) {
	border: none;
}

.search_block .dropdown-menu.autosearch li > div {
	line-height: 1.5;
	display: inline-block;
}

.search_block .dropdown-menu.autosearch li a {
	white-space: normal;
	padding: 3px 5px 20px 0;
}

.search_block .dropdown-menu.autosearch li .as-image {
	float: left;
	padding: 5px;
	width: 80px;
	margin-right: 10px;
}

.search_block .dropdown-menu.autosearch li .as-image img {
	height: auto;
	width: 100%;
}

.search_block .dropdown-menu.autosearch li .as-title {
	width: 100%;
	font-size: 16px;
	margin: 10px 0;
}

.search_block .dropdown-menu.autosearch li .as-title a {
	color: #444;
}

.search_block .dropdown-menu.autosearch li .as-price {
	width: calc(100% - 95px);
	font-size: 14px;
	font-weight: 600;
	position: absolute;
	bottom: 10px;
	left: 95px;
}

.search_block .dropdown-menu.autosearch li .as-price .price-old {
	font-size: 14px;
	text-decoration: line-through;
	margin-right: 7px;
	color: #777;
}

.search_block .dropdown-menu.autosearch li #showall {
	padding: 0 25px;
	display: inline-block;
}

@media (max-width: 1199px) {
	.search_block .dropdown-menu.autosearch {
		width: 300px;
	}
	.search_block .dropdown-menu.autosearch li .as-image {
		width: 80px;
	}
	.search_block .dropdown-menu.autosearch li .as-title {
		width: 100%;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
	}
	.search_block .dropdown-menu.autosearch li .as-price {
		width: calc(100% - 120px);
		left: 95px;
	}
}

@media (max-width: 767px) {
	.sphinxsearch {
		width: 100%;
		right: 0;
	}
	.search_block .dropdown-menu.autosearch {
		width: 260px;
		right: -15px;
		left: auto;
	}
}

@media (max-width: 421px) {
	.search_block .dropdown-menu.autosearch {
		top: calc(100% + 2px);
		width: calc(100vw - 30px);
		left: auto;
	}
}

.producttabs {
	margin-top: 30px;
	margin-bottom: 0;
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom .list_of_points {
	display: block;
	text-align: left;
	font-size: 14px;
	color: #444;
	font-weight: 500;
	padding-left: 22px;
}

.radio_custom a {
	padding-left: 10px;
	font-size: 18px;
	color: #1e487b;
}

.radio_custom .input-control {
	cursor: pointer;
	display: inline-block;
	padding-left: 22px;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: #444;
	margin: 1px 0;
}

.radio_custom .input-control input {
	position: absolute;
	width: auto !important;
	z-index: 1;
	opacity: 0;
}

.radio_custom .input-control__indicator {
	border-radius: 50%;
	display: block;
	position: absolute;
	top: 4px;
	left: 0;
	user-select: none;
	width: 15px;
	height: 15px;
	text-align: center;
	background-color: #1e487b;
}

.radio_custom .input-control.radio input ~ .input-control__indicator:after {
	border-radius: 50%;
	content: "";
	display: block;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transform: scale(0.8);
	transform-origin: center center;
	width: 100%;
	background: #1e487b;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: 0.1s opacity linear;
	-o-transition: 0.1s opacity linear;
	transition: 0.1s opacity linear;
	border: 3px #FFFFFF solid;
}

.radio_custom .input-control.radio input:checked ~ .input-control__indicator:after {
	opacity: 1;
	filter: alpha(opacity=100);
}

@media (max-width: 767px) {
	.radio_custom .input-control, .radio_custom .list_of_points {
		font-size: 14px;
	}
}

.checkbox_custom a {
	padding-left: 10px;
	font-size: 14px;
	color: #1e487b;
}

.checkbox_custom a.agree b {
	font-weight: 500;
	padding: 0;
	font-size: 12px;
}

.checkbox_custom .input-control {
	cursor: pointer;
	display: inline-block;
	padding-left: 25px;
	line-height: 16px;
	position: relative;
	font-size: 12px;
	font-weight: 500;
	color: #444;
	margin: 5px 10px 5px 14px;
	text-align: left;
}

.checkbox_custom .input-control input {
	position: absolute;
	width: auto !important;
	z-index: 1;
	opacity: 0;
}

.checkbox_custom .input-control__indicator {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	user-select: none;
	width: 16px;
	height: 16px;
	text-align: center;
	background-color: #FFFFFF;
	border: 2px #1e487b solid;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	-ms-border-radius: 0px;
	-o-border-radius: 0px;
	border-radius: 0px;
}

.checkbox_custom .input-control.checkbox input ~ .input-control__indicator:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 4px;
	transform-origin: center center;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: 0.1s opacity linear;
	-o-transition: 0.1s opacity linear;
	transition: 0.1s opacity linear;
	-webkit-transform: translateZ(1px) rotate(45deg);
	-moz-transform: translateZ(1px) rotate(45deg);
	-ms-transform: translateZ(1px) rotate(45deg);
	-o-transform: translateZ(1px) rotate(45deg);
	border-bottom: #1e487b 2px solid;
	width: 5px;
	height: 10px;
	border-right: #1e487b 2px solid;
}

.checkbox_custom .input-control.checkbox input:checked ~ .input-control__indicator:after {
	opacity: 1;
	filter: alpha(opacity=100);
}

.category_widget a .type1 {
	height: 500px;
	background: transparent center no-repeat;
	background-size: cover;
	margin-bottom: 30px;
	position: relative;
}

.category_widget a .type1 h2 {
	margin: 0;
	position: absolute;
	height: 70px;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.7);
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	color: #FFFFFF;
	font-size: 20px;
	padding: 23px 0;
	display: inline-block;
}

.product-compare .comparetable .img-thumbnail {
	border: none;
}

.product-compare .comparetable .table > thead > tr > td, .product-compare .comparetable .table > tbody > tr > td, .product-compare .comparetable .table > tfoot > tr > td {
	width: 500px;
}

.product-compare .compare_actions .btn {
	width: 100px;
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	background-color: #1e487b;
	text-indent: -9999px;
	z-index: 999;
	border: 1px #FFFFFF solid;
	-webkit-transition: 200ms all ease-in-out;
	-o-transition: 200ms all ease-in-out;
	transition: 200ms all ease-in-out;
}

#totop span {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -8px;
	margin-top: -12px;
	height: 0;
	width: 0;
	border: 8px solid transparent;
	border-bottom-color: #ffffff;
}

#totop:hover {
	background-color: #444;
}

#coupon_form h4 {
	font-size: 16px;
}

#coupon_form #button-coupon {
	padding: 0 16px;
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products .product-grid .product-block {
	margin: 5px;
	width: auto;
}

h1 {
	padding: 15px 0;
	text-transform: none;
}

.comparetable {
	padding: 15px;
	margin-bottom: 15px;
	overflow-x: auto;
}

.comparetable table {
	margin-bottom: 0;
}

.comparetable .price-old {
	text-decoration: line-through;
	color: #959595;
}

.owl-prev, .owl-next {
	position: absolute;
	top: -33px;
	width: 25px;
	height: 25px;
	color: #fff;
	-webkit-transition: 0.2s ease all;
	-o-transition: 0.2s ease all;
	transition: 0.2s ease all;
	background: #1e487b center no-repeat;
	font-family: "FontAwesome";
	font-size: 22px;
	line-height: 1;
	padding: 1px;
}

.owl-prev:hover, .owl-next:hover {
	background-color: #959595;
}

#image-additional .owl-prev, #image-additional .owl-next {
	top: 13px;
}

#image-additional .owl-next {
	right: 0;
}

#image-additional .owl-prev {
	left: 0;
	right: auto;
}

.owl-prev {
	right: 31px;
}

.owl-prev:before {
	content: '\f111';
}

.owl-next {
	right: 3px;
}

.owl-next:before {
	content: '\f112';
}

.mfilter-box .box-heading {
	display: none;
}

.mfilter-box .box-content {
	border: none;
}

.mfilter-box .box-content .mfilter-heading {
	background: transparent;
	border: none;
}

.mfilter-box .box-content .mfilter-heading .mfilter-heading-content {
	padding: 0 0 6px 0;
}

.mfilter-box .box-content .mfilter-heading .mfilter-head-icon {
	width: 25px;
	height: 20px;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
	font-size: 20px;
	line-height: 1;
	font-family: "FontAwesome";
	background: none;
	font-style: normal;
}

.mfilter-box .box-content .mfilter-heading .mfilter-head-icon:before {
	content: "\f2c2";
}

.mfilter-box .box-content .mfilter-heading.mfilter-collapsed .mfilter-head-icon:before {
	content: "\f28e";
}

.mfilter-box .box-content .mfilter-heading-.mfilter-box .box-content .mfilter-col-inputtext {
	width: calc(100% - 27px);
	margin-left: 0;
}

.mfilter-box .box-content .mfilter-heading-text > span {
	padding-left: 0;
	font-size: 16px;
	font-family: "Rubik", sans-serif;
	text-transform: uppercase;
}

.mfilter-box .box-content .mfilter-opts-container .mfilter-counter {
	border-radius: 0;
	background: #acacac;
}

.mfilter-box .box-content .mfilter-opts-container .mfilter-counter:after {
	content: none;
}

.mfilter-box .box-content .mfilter-opts-container .mfilter-counter.mfilter-close {
	background: url(/catalog/view/theme/default/stylesheet/mf/images/icons.png?v7) no-repeat 0 0;
}

.mfilter-box .box-content .mfilter-opts-container .form-control[disabled], .mfilter-box .box-content .mfilter-opts-container .form-control[readonly], .mfilter-box .box-content .mfilter-opts-container fieldset[disabled] .form-control {
	background: #FFFFFF;
}

.mfilter-box .mfilter-tb-as-td {
	font-size: 12px;
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid #f2f1ef;
	border-radius: 0;
	background: #FFFFFF;
	position: relative;
	overflow: hidden;
}

.selectric .label {
	display: block;
	white-space: nowrap;
	overflow: visible;
	width: calc(100% - 35px);
	text-overflow: ellipsis;
	margin: 0 25px 0 10px;
	padding: 8px 0;
	font-size: 14px;
	color: #444;
	height: 30px;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 1;
}

.selectric .button {
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	width: 38px;
	line-height: 38px;
	background-color: #FFFFFF;
	color: #444;
	text-align: center;
}

.selectric .button:after {
	content: '';
	position: absolute;
	width: 6px;
	height: 6px;
	border-bottom: 1px solid #444;
	border-right: 1px solid #444;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	right: 10px;
	top: 10px;
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric .button {
	color: #a2a2a2;
}

.selectric-hover .selectric .button:after {
	border-top-color: #a2a2a2;
}

.selectric-open {
	z-index: 9999;
}

.selectric-open .selectric .button::after {
	-webkit-transform: rotate(225deg);
	-moz-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	-o-transform: rotate(225deg);
	transform-origin: 4px 5px;
}

.selectric-open .selectric-items {
	display: block;
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
}

.selectric-hide-select select {
	position: absolute;
	left: -100%;
}

.selectric-hide-select.selectric-is-native {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	border: none;
	z-index: 1;
	box-sizing: border-box;
	opacity: 0;
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	right: 0;
	background: #FFFFFF;
	border: 1px solid #f2f1ef;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
	height: 100%;
	overflow: auto;
}

.selectric-items ul, .selectric-items .selectric-items li {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 14px;
	line-height: 20px;
	min-height: 20px;
}

.selectric-items li {
	display: block;
	padding: 10px;
	color: #444;
	cursor: pointer;
	line-height: 16px;
	white-space: nowrap;
}

.selectric-items li.selected, .selectric-items li.highlighted, .selectric-items li:hover {
	color: #fff;
	background-color: #1e487b;
}

.selectric-items .disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default !important;
	background: none !important;
	color: #666 !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
	font-weight: bold;
	padding-left: 10px;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: none;
	color: #444;
}

.selectric-items .selectric-group.disabled li {
	filter: alpha(opacity=100);
	opacity: 1;
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.widget-products .tab-header {
	padding: 5px 0;
	border-bottom: #c4c4c4 1px solid;
}

.widget-products .tab-header span {
	font-family: "Rubik", sans-serif;
	padding: 0 5px;
	font-size: 18px;
	text-transform: uppercase;
}

.widget-products .owl-stage {
	padding: 15px 0;
}

.widget-products .owl-item:hover {
	z-index: 400;
}

.page-information h1, .checkout-buy h1 {
	margin-top: 0;
}

.holiday_works {
	text-align: center;
	border: none !important;
	margin-left: auto !important;
	display: block;
	color: red !important;
	margin-top: 20px;
	margin-bottom: 10px;
}

.quick-view #content {
	padding: 0;
}

#review table {
	margin-bottom: 15px;
}

#review .rating {
	margin-left: 15px;
}

#form-review-container .recaptcha_container label {
	display: none;
}

.rating .fa, .stars .fa {
	color: #ffbb00;
}

.prod_rating .custom-color {
	cursor: pointer;
}

@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}

	#cart {
		margin-left: 15px;
	}
}

@media (max-width: 1199px) {
	.footer-center .main-footer {
		margin-left: 0;
	}

	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 10px;
		font-size: 14px;
	}

	.producttabs .btn-dark {
		width: 30px;
		height: 30px;
		font-size: 11px;
		line-height: 30px;
	}

	.feature-box-v1 {
		padding: 12px 20px;
	}
	.feature-box-v1 .fbox-body.white strong {
		font-size: 18px;
	}

	.home3 .featured-category ul li .caption {
		padding: 20px 5px;
	}
	.home3 .featured-category .image {
		margin: 5px 0 5px 10px;
	}

	.product-info .product-meta .btn-sm, .product-info .product-meta .btn-group-sm > .btn, .product-info .product-meta .btn-group-sm > .btn {
		padding: 0 5px;
	}

	.header-bottom .nav-search {
		margin-bottom: 7px;
	}

	.refine-search ul li a span, .refine-search ol li a span {
		line-height: 15px;
		font-size: 12px;
		overflow: hidden;
	}
}

@media (max-width: 1199px) and (min-width: 992px) {
	.logo-store img {
		margin-top: 4px;
	}

	.home3 .widget-blogs .carousel-controls-v1 {
		top: 45px;
	}
	.home3 .widget-blogs .carousel-controls-v1 .carousel-control.left {
		left: -65px;
	}
	.home3 .widget-blogs .carousel-controls-v1 .carousel-control {
		border: none;
	}
}

@media (max-width: 991px) {
	.widget-images {
		margin-bottom: 30px;
	}

	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}

	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}

	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}

	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}

	.footer-center .panel {
		margin-bottom: 15px;
	}

	#pavo-footer-center .container {
		padding-right: 25px;
	}
	#pavo-footer-center .container .footer-left {
		float: none;
		margin: 0 auto 30px auto;
	}

	.featured-category ul li .caption {
		padding: 20px 5px;
	}
	.featured-category .image {
		margin: 5px 0 5px 10px;
	}

	.zoomContainer {
		display: none;
	}

	.product-grid .product-block {
		width: calc(100% / 3 - 13px);
	}
	.product-grid .product-block .product-grid .product-block {
		width: calc(100% / 3 - 13px);
		margin: 5px;
	}

	.header-bottom > .container {
		height: 100px;
	}
	.header-bottom .logo-container {
		text-align: center;
		flex: 0 0 200px;
		order: 1;
	}
	.header-bottom .logo-container img {
		max-width: 100%;
	}
	.header-bottom #topcontacts {
		order: 2;
	}
	.header-bottom .headercart {
		order: 3;
		flex: 0 1 150px;
		min-width: 150px;
	}

	.header-menu > .container {
		padding-left: 0;
	}

	#powered .copyright {
		order: 2;
	}
	#powered .payment {
		order: 1;
		text-align: center;
	}
	#powered .payment .payment-img {
		float: none !important;
		max-width: 80%;
		display: inline-block;
	}
	.tree-menu ul li:hover {
		background-color: #FFFFFF;
	}
	.tree-menu ul li:hover a {
		color: #444;
	}

	.breadcrumbs {
		padding: 15px 0;
	}

	.h1_container:before {
		margin: 33px 10px 10px 0;
	}
	.h1_container h1 {
		padding: 18px 15px 21px 0;
		font-size: 26px;
	}

	.product-compare-header {
		width: 60px;
		position: relative;
		flex: 0;
		padding: 13px 10px 0 14px;
	}
	.product-compare-header a {
		color: #444;
	}
	.product-compare-header a:hover {
		text-decoration: none;
	}
	.product-compare-header .badge {
		right: 7px;
	}

	#cart {
		flex: 0;
		border-left: 1px solid #fff;
	}
	#cart .icon-cart {
		padding: 0 17px;
		height: 50px;
	}
	#cart .icon-cart i {
		line-height: initial;
		vertical-align: -25px;
	}

	.cat_all > li {
		position: relative;
	}
	.cat_all > li .click-allcat {
		cursor: pointer;
		display: inline-block;
		padding: 1px 15px;
		top: 8px;
		position: absolute;
		right: 0;
		z-index: 1;
		color: #444;
	}
	.cat_all > li.haschildren ul {
		display: none;
	}
	.cat_all > li.haschildren > a {
		width: calc(100% - 40px);
		display: inline-block;
	}
	.cat_all > li.haschildren.opened ul {
		display: block;
	}

	.category_widget .type1 {
		height: 300px;
	}
	.category_widget .type1 h2 a {
		font-size: 15px;
		padding: 27px 0;
	}

	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}

	.main-menu .canvas-menu {
		color: #fff;
		padding: 0 15px;
	}

	.tree-menu ul ul li {
		padding: 2px 0;
	}

	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 3 - 7px);
	}
}

@media (max-width: 768px) {
	.alert {
		margin: 10px 15px;
	}

	.quickview, .zoom {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.product-grid .product-block {
		width: calc(100% / 2 - 12px);
	}

	.logo-container {
		width: 140px;
	}

	.topcontacts {
		width: calc(100% - 140px);
	}

	.featured-category ul li {
		width: 100%;
	}

	#powered ul.links {
		margin-bottom: 40px;
	}

	.producttabs {
		margin-top: 15px;
	}

	.widget-products .owl-stage {
		padding: 15px 0;
	}

	.tab-v3 .nav-tabs {
		flex-wrap: wrap;
		border: none;
	}
	.tab-v3 .nav-tabs > li {
		width: 100%;
		text-align: center;
		border: none;
	}
	.tab-v3 .nav-tabs > li:last-child {
		border: none;
	}
	.tab-v3 .nav-tabs > li.active a {
		border: none;
	}
	.tab-v3 .nav-tabs > li > a {
		border: none;
		padding: 10px 15px;
		font-size: 18px;
	}

	.h1_container:before {
		content: none;
	}
	.h1_container h1 {
		width: 100%;
		font-size: 20px;
	}

	.table-responsive .input-group .form-control {
		margin-right: 30px;
		padding: 5px;
	}

	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}

	.shopping-cart-table > thead {
		display: none;
	}
	.shopping-cart-table .shopping-cart-table__product-image img {
		max-width: 160px;
	}
	.shopping-cart-table td.image-col {
		width: 100%;
		text-align: center !important;
	}
	.shopping-cart-table, .shopping-cart-table tbody, .shopping-cart-table td, .shopping-cart-table tr {
		display: block;
		border: 0;
		width: 100%;
	}
	.shopping-cart-table td {
		position: relative;
		padding-left: 43% !important;
		text-align: left !important;
		white-space: normal !important;
	}
	.shopping-cart-table td:first-child {
		border-top: 0;
		padding: 0 !important;
		text-align: center !important;
	}
	.shopping-cart-table td div.th-title {
		display: block;
		position: absolute;
		left: 10px;
		width: 38%;
		padding-right: 10px;
		text-align: right;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		font-weight: bold;
		text-transform: uppercase;
	}
	.shopping-cart-table td.no-title {
		padding-left: 20px !important;
		text-align: center !important;
	}
	.shopping-cart-table .input-group-qty {
		display: inline-block;
		padding-right: 10px;
	}

	.total-table .text-right {
		padding-left: 60% !important;
	}
	.total-table td div.th-title {
		width: 53% !important;
	}

	.header-bottom #topcontacts {
		justify-content: center;
		min-width: 133px;
	}
	.header-bottom #topcontacts .worktime {
		display: none;
	}

	.shopping-cart-btns {
		text-align: center;
	}
	.shopping-cart-btns .btn.pull-left, .shopping-cart-btns .btn.pull-right {
		float: none !important;
		margin: 0 !important;
	}

	.header-bottom .main-menu {
		flex: 0 0 auto;
	}

	#powered .inner {
		flex-wrap: wrap;
	}
	#powered .inner .copyright {
		order: 2;
		margin-bottom: 15px;
	}
	#powered .inner .payment {
		order: 1;
	}
	#powered .inner .payment .payment-img {
		max-width: 100%;
	}

	#input-enquiry {
		height: 100px;
	}

	.footer-center .footer-left ul {
		margin: 0 auto;
	}
	.footer-center .footer-left ul li {
		max-width: 220px;
		margin: 0 auto;
	}
	.footer-center .main-footer {
		flex-wrap: wrap;
	}
	.footer-center .main-footer .box {
		width: 100%;
	}

	.breadcrumb > li {
		display: inline;
		font-size: 13px;
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 45px 15px 15px 15px;
	}
	.success .success_button {
		padding: 6px;
		font-size: 13px;
	}
	.success > div:first-child {
		margin-top: 20px;
	}
	.success br {
		display: none;
	}

	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}

	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 2 - 7px);
	}
}

@media (max-width: 570px) {
	.fancy_holiday_works {
		width: 280px !important;
	}
	.fancy_holiday_works .fancybox-skin {
		padding: 30px 10px !important;
		background-color: #fff;
	}
	.fancy_holiday_works .fancybox-skin .fancybox-inner {
		width: 260px !important;
	}
	.fancy_holiday_works .fancybox-skin .holiday_table_wrap {
		font-size: 14px;
	}
	.fancy_holiday_works .fancybox-skin .holiday_table td {
		font-size: 14px;
	}

	.holiday_works {
		margin-top: 0;
	}

	#powered > .container {
		padding: 0;
	}

	.breadcrumbs {
		padding: 15px;
	}
	.breadcrumbs .container {
		padding: 0;
	}

	.header-bottom > .container {
		height: auto;
		position: relative;
	}
	.header-bottom .logo-container {
		flex: 0 0 150px;
		margin: 10px 0;
	}
	.header-bottom #topcontacts {
		position: absolute;
		right: 160px;
		flex: 0 1 auto;
		min-width: unset;
	}
	.header-bottom #topcontacts, .header-bottom .headercart {
		flex: 0 1 auto;
	}
	.header-bottom .headercart {
		min-width: 133px;
	}
	.header-bottom .topcontacts {
		justify-content: center;
		max-width: 35px;
		min-width: auto;
	}
	.header-bottom .topcontacts .media-title {
		padding: 0;
	}
	.header-bottom .topcontacts .media-body {
		display: none;
	}
	.header-bottom .topcontacts em {
		font-size: 30px;
	}

	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}

	.product-info .tab-content .desc-header-wrapper .desc-header {
		display: block;
	}
	.product-info .tab-content .scroll-button {
		margin-bottom: 15px;
		margin-left: 15px;
	}
}

@media (max-width: 480px) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
	}
	.product-filter .filter-right > div.limit {
		display: none;
	}

	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}

	.product-list .product-meta {
		padding: 20px;
	}

	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: 13px;
	}

	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}

	.pav-blogs .blog-body {
		overflow: visible;
	}

	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% - 5px);
	}

	.header-bottom .logo-container {
		flex: 0 0 130px;
		padding-right: 10px;
	}
	.header-bottom .headercart {
		min-width: 120px;
	}
	.header-bottom #topcontacts {
		right: 133px;
	}

	.product-compare-header {
		padding-left: 0;
		width: 40px;
	}
	.product-compare-header .badge {
		right: 2px;
	}

	#cart .wrap-cart .badge {
		right: 7px;
	}
	#cart .icon-cart {
		padding: 0 10px;
	}
}

@media (max-width: 360px) {
	.category_widget .type1 h2 a {
		font-size: 13px;
		padding: 28px 0;
	}

	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
}

@media (max-width: 480px) {
	.product-grid .product-block {
		width: calc(100% - 12px);
	}
}