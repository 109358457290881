//Gilroy
@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-Regular.eot');
	src: local('Gilroy Regular'), local('Gilroy-Regular'),
		url('/fonts/gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gilroy/Gilroy-Regular.woff') format('woff'),
		url('/fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-Medium.eot');
	src: local('Gilroy Medium'), local('Gilroy-Medium'),
		url('/fonts/gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gilroy/Gilroy-Medium.woff') format('woff'),
		url('/fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal; 
}

@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-SemiBold.eot');
	src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
		url('/fonts/gilroy/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gilroy/Gilroy-SemiBold.woff') format('woff'),
		url('/fonts/gilroy/Gilroy-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-Bold.eot');
	src: local('Gilroy Bold'), local('Gilroy-Bold'),
		url('/fonts/gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gilroy/Gilroy-Bold.woff') format('woff'),
		url('/fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-ExtraBold.eot');
	src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
		url('/fonts/gilroy/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff'),
		url('/fonts/gilroy/Gilroy-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('/fonts/gilroy/Gilroy-Black.eot');
	src: local('Gilroy Black'), local('Gilroy-Black'),
		url('/fonts/gilroy/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gilroy/Gilroy-Black.woff') format('woff'),
		url('/fonts/gilroy/Gilroy-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

//Roboto
@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/Roboto-Light.eot');
	src: url('/fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
		url('/fonts/roboto/Roboto-Light.woff') format('woff'),
		url('/fonts/roboto/Roboto-Light.ttf') format('truetype'),
		url('/fonts/roboto/Roboto-Light.svg#Roboto Light Italic') format('svg');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2044;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/Roboto.eot');
	src: url('/fonts/roboto/Roboto.eot?#iefix') format('embedded-opentype'),
		url('/fonts/roboto/Roboto.woff') format('woff'),
		url('/fonts/roboto/Roboto.ttf') format('truetype'),
		url('/fonts/roboto/Roboto.svg#Roboto') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2044;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/Roboto-Medium.eot');
	src: url('/fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
		url('/fonts/roboto/Roboto-Medium.woff') format('woff'),
		url('/fonts/roboto/Roboto-Medium.ttf') format('truetype'),
		url('/fonts/roboto/Roboto-Medium.svg#Roboto Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2044;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/Roboto-Bold.eot');
	src: url('/fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/roboto/Roboto-Bold.woff') format('woff'),
		url('/fonts/roboto/Roboto-Bold.ttf') format('truetype'),
		url('/fonts/roboto/Roboto-Bold.svg#Roboto Bold') format('svg');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2044;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/roboto/Roboto-Black.eot');
	src: url('/fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
		url('/fonts/roboto/Roboto-Black.woff') format('woff'),
		url('/fonts/roboto/Roboto-Black.ttf') format('truetype'),
		url('/fonts/roboto/Roboto-Black.svg#Roboto Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2044;
}

//Icomoon
@font-face {
	font-family: 'icomoon';
	src: url('/fonts/icomoon/icomoon.eot?wujn4d');
	src: url('/fonts/icomoon/icomoon.eot?wujn4d#iefix') format('embedded-opentype'),
		url('/fonts/icomoon/icomoon.ttf?wujn4d') format('truetype'),
		url('/fonts/icomoon/icomoon.woff?wujn4d') format('woff'),
		url('/fonts/icomoon/icomoon.svg?wujn4d#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-delivery-1:before {
	content: "\e937";
}

.icon-delivery-2:before {
	content: "\e938";
}

.icon-delivery-3:before {
	content: "\e939";
}

.icon-email:before {
	content: "\e93a";
}

.icon-filter:before {
	content: "\e936";
}

.icon-speed-1:before {
	content: "\e935";
}

.icon-close:before {
	content: "\e934";
}

.icon-arrow:before {
	content: "\e900";
}

.icon-arrow-bold:before {
	content: "\e901";
}

.icon-basket:before {
	content: "\e902";
}

.icon-be-sure:before {
	content: "\e903";
}

.icon-catalog-1:before {
	content: "\e904";
}

.icon-catalog-2:before {
	content: "\e905";
}

.icon-catalog-3:before {
	content: "\e906";
}

.icon-catalog-4:before {
	content: "\e907";
}

.icon-catalog-5:before {
	content: "\e908";
}

.icon-catalog-6:before {
	content: "\e909";
}

.icon-catalog-7:before {
	content: "\e90a";
}

.icon-catalog-8:before {
	content: "\e90b";
}

.icon-catalog-9:before {
	content: "\e90c";
}

.icon-catalog-10:before {
	content: "\e90d";
}

.icon-catalog-11:before {
	content: "\e90e";
}

.icon-catalog-12:before {
	content: "\e90f";
}

.icon-check:before {
	content: "\e910";
}

.icon-compare:before {
	content: "\e911";
}

.icon-compare-1:before {
	content: "\e912";
}

.icon-credit:before {
	content: "\e913";
}

.icon-date:before {
	content: "\e914";
}

.icon-delivery:before {
	content: "\e915";
}

.icon-download:before {
	content: "\e916";
}

.icon-eye:before {
	content: "\e917";
}

.icon-geo:before {
	content: "\e918";
}

.icon-info:before {
	content: "\e919";
}

.icon-list:before {
	content: "\e91a";
}

.icon-message:before {
	content: "\e91b";
}

.icon-more:before {
	content: "\e91c";
}

.icon-payment-1:before {
	content: "\e91d";
}

.icon-payment-2:before {
	content: "\e91e";
}

.icon-payment-3:before {
	content: "\e91f";
}

.icon-payment-4:before {
	content: "\e920";
}

.icon-payment-5:before {
	content: "\e921";
}

.icon-percent:before {
	content: "\e922";
}

.icon-phone:before {
	content: "\e923";
}

.icon-play:before {
	content: "\e924";
}

.icon-properties-1:before {
	content: "\e925";
}

.icon-properties-2:before {
	content: "\e926";
}

.icon-properties-3:before {
	content: "\e927";
}

.icon-properties-4:before {
	content: "\e928";
}

.icon-rouble:before {
	content: "\e929";
}

.icon-sad:before {
	content: "\e92a";
}

.icon-search:before {
	content: "\e92b";
}

.icon-service-1:before {
	content: "\e92c";
}

.icon-service-2:before {
	content: "\e92d";
}

.icon-service-3:before {
	content: "\e92e";
}

.icon-speed:before {
	content: "\e92f";
}

.icon-star:before {
	content: "\e930";
}

.icon-star-fill:before {
	content: "\e931";
}

.icon-talking:before {
	content: "\e932";
}

.icon-tile:before {
	content: "\e933";
}