/*
================================================================================
|                                RANGE SLIDER                              |
================================================================================
*/

.ui-widget.ui-widget-content:not(.ui-autocomplete) {
	height: 4px;
	width: 100%;
	max-width: 220px;
	margin-left: 10px;
	background: #D1DBE0;
	border: none;
}

.ui-slider-horizontal .ui-slider-range {
	background: #648798;
}

.ui-slider-horizontal .ui-slider-handle {
	display: block;
	top: 50%;
	width: 20px;
	height: 20px;
	border: 1px solid #fff;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	transform: translateY(-50%);
	cursor: pointer;
	transition: border $transition;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
	border: 3px solid $color-2;
}