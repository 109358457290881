/*
================================================================================
|                                 BASKET PAGE                                  |
================================================================================
*/

.box-basket {
	position: relative;
	&__wrap {
		position: static !important;
		/*position: relative !important;*/
		display: flex;
		flex-wrap: wrap;
		background-color: $base-bg;
	}
}

.basket-main {
	width: calc(100% - 395px);
	position: relative;
	z-index: 1;
	h1,
	h2 {
		padding: 0 0 20px;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #000;
	}

	.product-list {
		margin: -5px;
	}

	.product-list__item {
		padding: 5px;
	}
}

.basket-main {
	.product-list__item {
		width: 100%;
	}

	.product-list_row .product-list__img-wrap {
		width: 140px;
		position: relative;
		.product-list__stock {
			display: inline-block;
			position: absolute;
			z-index: 10;
		}
	}

	.product-list_row .product-list__img {
		height: 94px;
	}

	.product-list_row .product-list__name {
		max-width: 450px;
		margin: 6px 0 18px;
	}

	.product-list_row .product-list__bottom {
		justify-content: center;
		width: 130px;
	}

	.product-list_row .product-list__price {
		margin: 17px 0 12px;
	}

	.product-list_row .product-list__price-old {
		position: absolute;
		top: 38px;
		left: -68px;
	}

	.product-list__price i {
		margin-left: 8px;
	}
}

.basket-main-section {
	padding: 20px;
	margin-bottom: 20px;
	background-color: #fff;

	&:last-child {
		margin-bottom: 0;
	}
}

.basket-order {

	$this: &;

	&__row {
		padding: 0 20px 30px;
		margin: 0 -20px;
		border-top: 1px solid #E8E8E8;

		&:last-child {
			padding: 0 20px 10px;
		}
	}

	&__cont {
		padding-left: 50px;
	}

	&__title-icon {
		position: absolute;
		top: 50%;
		left: -35px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: #C2C2C2;
		color: #fff;
		font-size: 9px;
		transform: translateY(-50%);
	}

	&__title {
		position: relative;
		display: flex;
		align-items: center;
		padding: 11px 0;
		color: rgba(51, 51, 51, .6);
		font-family: $custom-font-family;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;

		&.active #{$this}__title-icon {
			background-color: #4DB45E;
		}
	}

	&__step {
		width: 49px;
		color: $color-1;
		font-weight: 500;
		font-size: 46px;
		line-height: normal;
		text-align: center;
	}

	&__radio-wrap {
		display: flex;
		flex-wrap: wrap;
		margin: -5px -15px;

		& + p {
			margin-top: 9px;
			padding-bottom: 0;
		}
	}

	&__radio-wrap_col {
		flex-direction: column;
	}

	&__radio {
		padding: 12px 15px;
	}

	&__radio-input {
		display: none;
	}

	&__radio-label {
		position: relative;
		display: inline-block;
		padding-left: 36px;
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 16px;
		line-height: normal;
		cursor: pointer;
	}

	&__radio-label::before {
		content: "";
		position: absolute;
		top: 0;
		left: 2px;
		display: block;
		width: 16px;
		height: 16px;
		border: 3px solid #fff;
		background: #FFFFFF;
		box-shadow: 0px 0px 0px 2px #C4C4C4;
		border-radius: 50%;
		transition: $transition;
	}

	&__radio-input:checked + #{$this}__radio-label::before {
		box-shadow: 0px 0px 0px 2px $color-2;
		background: $color-2;
	}

	&__link-wrap {
		padding-left: 26px;
		font-size: 12px;
		line-height: 15px;
		font-weight: normal;
		color: #787878;
	}

	&__checkbox-link {
		display: inline-block;
		border-bottom: 1px solid;
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		color: $color-3;
	}

	&__link {
		display: inline-block;
		border-bottom: 1px solid;
		color: rgba(51, 51, 51, .8);
		line-height: 12px;
		font-weight: 500;
	}

	p {
		color: rgba(51, 51, 51, .7);
	}
}

.basket-order-form {
	margin: 0px 0;

	$this: &;

	&__row {
		padding: 10px 0;
		display: flex;
		align-items: center;
	}

	&__label {
		width: 130px;
		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}

	&__input-wrap {
		position: relative;
		width: 340px;
		font-family: $custom-font-family;

		&.success {
			#{$this}__input {
				border-color: $color-3;
			}

			#{$this}__input-status {
				background-color: #019835;
			}
		}

		&.error {
			#{$this}__input {
				border: 1px solid #D32011;
			}

			#{$this}__input-status {
				background-color: #D32011;
			}

			label.error {
				z-index: 1;
				opacity: 1;
			}
		}

		label.error {
			position: absolute;
			z-index: -1;
			top: 50%;
			right: 53px;
			color: rgba(51, 51, 51, .4);
			font-weight: 500;
			font-size: 16px;
			line-height: normal;
			transform: translateY(-50%);
			transition: $transition;
		}
	}

	&__input {
		height: 40px;
		border: 1px solid #E8E8E8;

		font-family: $custom-font-family;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		transition: $transition;

		&::placeholder {
			color: rgba(51, 51, 51, .4);
		}
	}

	&__input-status {
		position: absolute;
		z-index: 1;
		top: 50%;
		right: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		color: #fff;
		background-color: transparent;
		font-size: 7px;
		line-height: 1;
		transform: translateY(-50%);
		transition: $transition;

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.basket-aside {
	width: 375px;
}

.basket-form {
	background-color: #fff;
	h2, h3 {
		color: #000;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}
	h3 {
		padding: 2px 0 0 0;
	}
	p {
		padding: 10px 0 0;
		letter-spacing: -.07px;
		font-size: 14px;
		line-height: 24px;
		color: rgba(51, 51, 51, .6);
	}

	&__row {
		border-bottom: 1px solid #E8E8E8;
		padding: 13px 22px;

		&:last-child {
			border-bottom: none;
		}
	}

	.form-filter__checkbox-wrap {
		margin: 5px 0 10px;
	}

	.form-filter__checkbox-label {
		font-size: 12px;
		line-height: 15px;
		font-weight: normal;
		color: #787878;
	}

	.form-filter__checkbox {
		margin-bottom: 15px;
	}
}

.basket-form-list {
	margin: -6px 0;

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 6px 0;
	}

	&__name {
		width: 60%;
		font-size: 14px;
		line-height: 20px;
	}

	&__price {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-left: 10px;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;

		i {
			font-size: 10px;
			margin-left: 10px;
		}
	}
}

.basket-form-price {
	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 4px 0;
	}

	&__title {
		font-size: 14px;
		line-height: normal;
	}

	&__value {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;

		i {
			font-size: 10px;
			margin-left: 10px;
		}
	}
}

.basket-form-order {
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__title {
		font-size: 14px;
		line-height: normal;
	}

	&__price {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 22px;
		line-height: 26px;
		white-space: nowrap;
		i {
			margin-left: 10px;
			font-size: 14px;
		}
	}
}

.basket-form-promocode {
	display: flex;
	justify-content: flex-end;
	margin: 19px 0 21px;

	&__wrap {
		position: relative;
		width: 270px;
		max-width: 100%;
	}

	&__input {
		height: 40px;
		border: 2px solid #BDBDBD;
		border-radius: 50px;
		padding: 0 123px 0 19px;
		font-size: 13px;
		line-height: 15px;
	}

	&__btn {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		width: 110px;
		height: 100%;
		border: none;
		outline: none;
		border-radius: 0px 50px 50px 0px;
		background: #3A6479;
		color: #fff;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		transition: $transition;

		&:hover {
			background-color: $color-2;
		}
	}
}

.basket-form-comment {
	margin-bottom: 5px;

	&__title {
		display: inline-block;
		border-bottom: 1px dashed;
		font-size: 12px;
		line-height: 23px;
		color: #787878;
		cursor: pointer;

		&:hover {
			color: $color-2;
		}

		&.active {
			border-bottom: none;
		}
	}

	&__textarea {
		display: block;
		width: 100%;
		height: 130px;
		border: 1px solid #E8E8E8;
		border-radius: 8px;
	}
}

/*--------------------------------------------------------*/

.box-basket-empty {
	&__wrap {
		padding: 22px;
		background-color: #fff;
	}

	h2 {
		padding-top: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #000;
	}
}

.basket-empty {
	margin: 25px 0 15px;
	text-align: center;

	h3 {
		margin-bottom: 16px;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #000;
	}

	.btn {
		padding: 0px 30px;
	}
}

/*--------------------------------------------------------*/

.box-basket-done {
	&__wrap {
		padding: 90px 22px;
		background-color: #fff;
		text-align: center;
	}

	h1 {
		padding: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		margin-bottom: 30px;
		span {
			color: $color-3;
		}
	}

	p {
		padding: 0;
		font-weight: 500;
		color: rgba(51, 51, 51, .7);
	}

	.btn {
		padding: 0 30px;
	}
}

.box-basket-done__btn {
	margin-top: 30px;
}

/*-------------------------------------------------------------------------------*/

@media screen and (max-width: 1279px) {
	.basket-main .product-list_row .product-list__row-wrap {
		padding-left: 0;
	}
	.basket-main .product-list__quantity {
		position: absolute;
		top: 14px;
		right: 14px;
	}

	.basket-main .product-list__price-wrap {
		margin-top: 35px;
	}
}

@media screen and (max-width: 1023px) {
	#order_params_result {
		display: none;
	}
	.box-basket-empty__wrap {
		margin: 0 -20px;
	}

	.box-basket__wrap {
		flex-wrap: wrap;
	}

	.basket-main {
		width: 100%;
		margin-right: 0;
	}

	.inner-wrapper-sticky {
		position: static !important;
		transform: none !important;
		width: 100% !important;
	}

	.basket-aside {
		width: 100%;
		margin: 20px 0 0 0;
	}

	#buy_form_container {
		flex-wrap: wrap;
	}

	.basket-main .product-list__quantity {
		position: static;
	}

	.basket-main-section {
		margin: 0 -20px 20px;
	}

	.basket-order__title-icon {
		left: 0;
	}

	.basket-order__title {
		padding-left: 35px;
	}

	.basket-form__row {
		padding: 13px 20px;
	}

	.basket-main .product-list__price-wrap {
		margin-top: 0px;
	}
}

@media screen and (max-width: 767px) {
	.box-basket-empty__wrap {
		padding: 20px;
	}

	.product-list__remove {
		margin-bottom: 20px;
	}

	.basket-order__cont {
		padding-left: 0;
	}

	.basket-order-form__input {
		padding: 0 45px 0 15px;
	}

	.basket-order-form__input-wrap label.error {
		top: 100%;
		right: 15px;
		transform: none;
	}

	.basket-main {
		.product-list__wrap {
			position: relative;
			flex-direction: row;
			flex-wrap: wrap;
			padding: 10px;
		}

		.product-list__img-wrap {
			margin: 0;
		}

		.product-list__quantity {
			position: absolute;
			top: auto;
			right: auto;
			bottom: 10px;
			left: 10px;
		}

		.product-list_row .product-list__bottom {
			justify-content: flex-end;
			width: 100%;
			margin-top: 0;
			text-align: right;
		}

		.product-list__price-wrap {
			display: flex;
			flex-direction: column;

			& > div {
				width: 100%;
				margin: 5px 0;
			}
		}

		.product-list_row .product-list__price-old {
			position: static;
		}

		.product-list_row .product-list__price {
			order: 1;
			margin: 5px 0 0;
		}

		.product-list_row .product-list__row-wrap {
			width: calc(100% - 140px);
		}
	}
}

@media screen and (max-width: 500px) {
	.basket-empty h3 {
		font-size: 18px;
		line-height: 22px;
	}

	.basket-form-promocode__wrap {
		width: 100%;
	}

	.box-basket-done__wrap {
		padding: 50px 15px;
	}

	.basket-order__row {
		padding: 0 15px 30px;
	}

	.basket-order__title {
		font-size: 14px;
		line-height: normal;
	}

	.box-basket-done h1 {
		font-size: 18px;
		line-height: 22px;
	}

	.basket-form h2,
	.basket-form h3 {
		font-size: 15px;
		line-height: normal;
	}

	.basket-form__row {
		padding: 12px 20px;
	}

	.basket-form-order {
		flex-wrap: wrap;
	}

	.basket-form-order__btn {
		width: 100%;
		margin-top: 15px;

		.btn {
			width: 100%;
		}
	}

	.basket-order__radio-label {
		font-size: 13px;
		padding-left: 30px;
	}

	.basket-order-form__row {
		flex-wrap: wrap;
	}

	.basket-order-form__label {
		width: 100%;
	}

	.basket-order-form__input-wrap {
		margin-top: 10px;
		width: 100%;
	}

	.basket-order__step {
		font-size: 40px;
	}

	.basket-main .product-list_row .product-list__img-wrap {
		width: 85px;
	}

	.basket-main .product-list_row .product-list__row-wrap {
		width: calc(100% - 85px);
		padding-left: 5px;
	}

	.basket-main .product-list_row .product-list__name {
		font-size: 15px;
		line-height: 19px;
	}

	.basket-main .product-list_row .product-list__remove {
		margin-bottom: 5px;
	}
}

.basket-order__radio-label .input-control__indicator:after {
	content: "";
	position: absolute;
	top: 0;
	left: 2px;
	display: block;
	width: 16px;
	height: 16px;
	border: 3px solid #fff;
	background: #FFFFFF;
	box-shadow: 0px 0px 0px 2px #C4C4C4;
	border-radius: 50%;
	transition: 0.4s ease-out;
}

.basket-order__radio-label input:checked ~ .input-control__indicator:after {
	box-shadow: 0px 0px 0px 2px $color-2;
	background: $color-2;
}
