//Variables
$min-width: 320px; 
$max-width: 1460px; 
$base-bg: #EFEFEF; 

$color-1: #333333;
$color-2: #FF9900;
$color-3: #3A6479;
$color-4: #648798;
$grey: #8c8c8c;
$dark: #444444;

$base-font-family: 'Roboto', sans-serif; 
$custom-font-family: 'Gilroy', sans-serif; 
$base-font-size: 13px; 
$base-line-height: 22px; 

$font-size-p: 13px;
$line-height-p: 22px;
$font-size-h1: 38px;
$line-height-h1: 45px;
$font-size-h2: 32px;
$line-height-h2: 37px;
$font-size-h3: 22px;
$line-height-h3: 36px;
$font-size-h4: 18px;
$line-height-h4: 20px;



$transition: 0.4s ease-out;
